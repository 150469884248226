import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CodeList, ICodeListTextDeps, getCodeListTextDeps } from '../entities/code-list';
import { AnchorChannelEmbedmentDepth } from '../entities/code-lists/anchor-channel-embedment-depth';
import { AnchorChannelLength } from '../entities/code-lists/anchor-channel-length';
import { BoltLength } from '../entities/code-lists/bolt-length';
import { Design } from '../entities/design';
import { DesignCodeList } from '../entities/enums/design-code-list';
import { LocalizationService } from '../services/localization.service';
import { NumberService } from '../services/number.service';
import { UnitService } from '../services/unit.service';
import { UserService } from '../services/user.service';
import { RebarPlate } from '../entities/code-lists/rebar-plate';
import { LipStrengthClips } from '../entities/code-lists/lip-strength-clips';
import { isAnchorRebar } from '../services/helpers/static-menu-helper';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { LoadTolerances } from '../entities/code-lists/load-tolerances';
import { Constants } from '../entities/constants';
import { AnchorEmbedmentDepth } from '../entities/code-lists/anchor-embedment-depth';
import { GenericRebars } from '../entities/code-lists/generic-rebars';

export class CodelistHelper {

    public static translateDropdownItems(design: Design, userService: UserService, unitService: UnitService, localizationService: LocalizationService, numberService: NumberService, codeListItems: CodeList[] | undefined, codeList?: number) {
        const codeListDeps = getCodeListTextDeps(localizationService, numberService);
        const list = codeListItems?.map((codeListItem) => ({
            value: codeListItem.id,

            text: (() => {
                return this.getCodeListItemText(userService, unitService, localizationService, design, codeList as number, codeListItem, codeListDeps)
                    ?? codeListItem.getTranslatedNameText(codeListDeps);
            })(),
            textTranslationKey: codeListItem.nameResourceKey,
            addOnActive: (() => {
                return false;
            })(),
            addOnText: (() => {
                return undefined;
            })(),
            tag: codeListItem.tag != null && codeListItem.tag.length > 0 ? localizationService.getString(codeListItem.tag) : null,
            isNew: (() => {
                return false;
            })(),
            internalPortfolioOnly: (() => {
                return false;
            })(),
        }) as DropdownItem<number>);

        return list;
    }

    public static getCodeListItemText(
        userService: UserService,
        unitService: UnitService,
        localizationService: LocalizationService,
        design: Design,
        codeList: number,
        codeListItem: CodeList,
        codeListDeps: ICodeListTextDeps
    ): string | undefined {
        if (!this.hasCodeListCustomText(codeList))
            return undefined;

        switch (codeList) {
            case DesignCodeList.AnchorChannelLength:
                return this.getAnchorChannelLength(userService, unitService, codeListItem, codeListDeps, design.unitLength);
            case DesignCodeList.AnchorChannelEmbedmentDepth:
                return this.getAnchorChannelEmbedmentDepth(userService, unitService, codeListItem, codeListDeps, design.unitLength);
            case DesignCodeList.AnchorEmbedmentDepths:
                return this.getAnchorEmbedmentDepth(userService, unitService, codeListItem, codeListDeps, design.unitLength);
            case DesignCodeList.RebarPlate:
                return this.getRebarPlate(userService, unitService, codeListItem, design.unitLength);
            case DesignCodeList.LipStrengthClips:
                return this.getLipStrengthClip(userService, unitService, codeListItem, design.unitLength);
            case DesignCodeList.BoltLengths:
                return CodelistHelper.getLengthValue(userService, unitService, codeListItem, design.unitLength);
            case DesignCodeList.LoadTolerances:
            case DesignCodeList.LoadTolerancesY:
            case DesignCodeList.LoadTolerancesZ:
                return LoadTolerances.formatItemText(userService, unitService, localizationService, codeListItem, design.unitLength);
            case DesignCodeList.GenericRebars:
                return CodelistHelper.getLengthValue(userService, unitService, codeListItem, design.unitLength);
            default:
                throw new Error(`Code list '${codeList}' does not have custom item text method.`);
        }
    }

    public static hasCodeListCustomText(codeList: number) {
        return [
            DesignCodeList.AnchorChannelLength,
            DesignCodeList.AnchorChannelEmbedmentDepth,
            DesignCodeList.AnchorEmbedmentDepths,
            DesignCodeList.RebarPlate,
            DesignCodeList.LipStrengthClips,
            DesignCodeList.BoltLengths,
            DesignCodeList.LoadTolerances,
            DesignCodeList.LoadTolerancesY,
            DesignCodeList.LoadTolerancesZ,
            DesignCodeList.GenericRebars
        ].includes(codeList);
    }

    public static getAnchorChannelLength(userService: UserService, unitService: UnitService, codeListItem: AnchorChannelLength, codeListDeps: ICodeListTextDeps, unit?: Unit) {
        unit = unit || userService.design.unitLength;

        const defaultUnit: Unit = unit;

        if (codeListDeps.localizationService != undefined && codeListItem.displayKey) {
            return codeListDeps.localizationService.getString(codeListItem.displayKey);
        }

        return CodelistHelper.getSetAndInternalLengthValue(codeListItem.channelLength as number, defaultUnit, unitService);
    }

    public static getAnchorChannelEmbedmentDepth(userService: UserService, unitService: UnitService, codeListItem: AnchorChannelEmbedmentDepth, codeListDeps: ICodeListTextDeps, unit?: Unit) {

        if (codeListItem.id == Constants.UserDefinedId){
            if (codeListDeps.localizationService != undefined && codeListItem.displayKey) {
                return codeListDeps.localizationService.getString(codeListItem.displayKey);
            }
        }

        unit = unit || userService.design.unitLength;

        const defaultUnit: Unit = unit;
        const internalUnit = unitService.getInternalUnit(UnitGroup.Length);

        // The values shown in "Embedment depth h_ef" in UI should be formatted as "%h_ef% %lengthUnit% (%b_ch% x %h_ch%)"
        const hEfWithUnit = unitService.formatUnitValueArgs(unitService.convertUnitValueArgsToUnit((isAnchorRebar(userService.design) ? codeListItem.rebarChannel?.l ?? 0 : codeListItem.h_ef) as number, internalUnit, defaultUnit), defaultUnit);
        return `${hEfWithUnit}`;
    }

    public static getAnchorEmbedmentDepth(userService: UserService, unitService: UnitService, codeListItem: AnchorEmbedmentDepth, codeListDeps: ICodeListTextDeps, unit?: Unit) {
        unit = unit ?? userService.design.unitLength;

        const defaultUnit: Unit = unit;
        const internalUnit = unitService.getInternalUnit(UnitGroup.Length);

        // The values shown in "Embedment depth h_ef" in UI should be formatted as "%h_ef% %lengthUnit%"
        const hEfWithUnit = unitService.formatUnitValueArgs(unitService.convertUnitValueArgsToUnit(codeListItem.hef as number, internalUnit, defaultUnit), defaultUnit);
        return `${hEfWithUnit}`;
    }

    public static getLengthValue(userService: UserService, unitService: UnitService, codeListItem: BoltLength | GenericRebars, unit?: Unit): string {
        unit = unit || userService.design.unitLength;

        const defaultUnit: Unit = unit;
        const value = codeListItem instanceof BoltLength ? codeListItem.ls as number : codeListItem.value as number;

        return CodelistHelper.getSetAndInternalLengthValue(value, defaultUnit, unitService);
    }

    public static getRebarPlate(userService: UserService, unitService: UnitService, codeListItem: RebarPlate, unit?: Unit) {
        unit = unit || userService.design.unitLength;

        const defaultUnit: Unit = unit;
        const internalUnit = unitService.getInternalUnit(UnitGroup.Length);

        // The values shown in "Rebar plate" in UI should be formatted as "%length% %lengthUnit% + %noOfRebars%R"
        const lengthUnitUnit = unitService.formatUnitValueArgs(unitService.convertUnitValueArgsToUnit(codeListItem.length ?? 0, internalUnit, defaultUnit), defaultUnit);
        return `${lengthUnitUnit} + ${codeListItem.noOfRebars}R`;
    }

    public static getLipStrengthClip(userService: UserService, unitService: UnitService, codeListItem: LipStrengthClips, unit?: Unit) {
        unit = unit || userService.design.unitLength;

        const defaultUnit: Unit = unit;
        const internalUnit = unitService.getInternalUnit(UnitGroup.Length);

        return unitService.formatUnitValueArgs(unitService.convertUnitValueArgsToUnit(codeListItem.length ?? 0, internalUnit, defaultUnit), defaultUnit);
    }

    public static getSetAndInternalLengthValue(value: number, setUnit: Unit, unitService: UnitService) : string {
        const internalUnit = unitService.getInternalUnit(UnitGroup.Length);

        if (setUnit != Unit.mm) {
            return `${unitService.formatUnitValueArgs(unitService.convertUnitValueArgsToUnit(value, internalUnit, setUnit), setUnit)} (${unitService.formatUnitValueArgs(unitService.convertUnitValueArgsToUnit(value, internalUnit, internalUnit), Unit.mm)})`;
        }
        return unitService.formatUnitValueArgs(unitService.convertUnitValueArgsToUnit(value, internalUnit, setUnit), setUnit);
    }
}
