import { Design } from '../entities/design';
import {
    InfoDialogTable
} from '../components/info-dialog-table/info-dialog-table.component';
import {
    InfoDialogUnorderedList
} from '../components/info-dialog-unordered-list/info-dialog-unordered-list.component';
import { CodeListService } from '../services/code-list.service';
import { DesignReportDataEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Calculation';
import { DesignEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { DesignMethodGroups, DesignStandards } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { DetailedScopeCheckInfoPopup } from '../entities/generated-modules/Hilti.PE.ScopeChecks.Common.Enums';

export interface IInfoDialogComponentInput {
    command: MainMenuCommand;
    detailedScopeCheckInfoPopup: DetailedScopeCheckInfoPopup;
    isScopeCheckInfoPopup: boolean;
    forceLinkToOpen: boolean;
}

export type CommandAdditionalData = {
    designMethodGroup: DesignMethodGroups;
    designStandard: DesignStandards;
    projectDesign?: DesignEntity;
    reportData?: DesignReportDataEntity;
};

export const getItemsForCommand = (design: Design, projectCodeList: CodeListService, forceLinkToOpen: boolean, command?: MainMenuCommand) => {
    const items = getItemsByCommand(
        {
            designMethodGroup: design.designMethodGroup?.id ?? DesignMethodGroups.None,
            designStandard: design.designStandard?.id ?? DesignStandards.None,
            projectDesign: design.designData?.projectDesign,
            reportData: design.designData?.reportData
        },
        command);

    return items;
};

export const getItemsForScopeCheckInfoPopup = (command: DetailedScopeCheckInfoPopup) => {
    return getScInfoPopupItems(command);
};

export const enum InfoDialogItemType {
    Text = 1,
    Image = 2,
    Title = 3,
    InfoLinkValue = 4,
    InfoLinkTitle = 5,
    Table = 6,
    SubHeading = 7,
    ListItem = 8,
    LineBreak = 9,
    InfoDirectLink = 10,
    UnorderedList = 11
}

export type MainMenuCommand =
    'OpenUseFullCrossSectionPopup';

export interface IInfoDialogItem {
    type: InfoDialogItemType;
    value?: string | InfoDialogTable | IcidLinks[] | InfoDialogUnorderedList;
    appendText?: string;
}

export interface IcidLinks {
    link: string;
    icid: number;
}

export interface IInfoLinkValue {
    icidLinks: IcidLinks[];
    displayKey: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getScInfoPopupItems = (command: DetailedScopeCheckInfoPopup): IInfoDialogItem[] => {
    return [];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getItemsByCommand = (commandAdditionalData: CommandAdditionalData, command?: MainMenuCommand): IInfoDialogItem[] => {
    return [];
};
