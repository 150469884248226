import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { DesignCodeList } from '../../entities/enums/design-code-list';
import { IProduct } from '../../entities/product';
import { IPropertyMetaData, PropertyMetaData } from '../../entities/properties';
import { CodelistHelper } from '../../helpers/codelist-helper';
import { CalculationService } from '../../services/calculation.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { ProductService } from '../../services/product-service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './select-v-para-solution.component.html',
    styleUrls: ['./select-v-para-solution.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectVParaSolutionComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;
    public dataLoaded = false;

    public propertyMetaData = PropertyMetaData;
    public channelFamily: DropdownProps<number> = {};
    public boltFamily: DropdownProps<number> = {};
    public boltSize: DropdownProps<number> = {};

    public selectedChannelFamilyId: number;
    public selectedBoltFamilyId: number;
    public selectedBoltSizeId: number;

    public products!: IProduct[];
    public scrollElement!: Element;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private calculationService: CalculationService,
        private productService: ProductService,
        private numberService: NumberService,
        private unitService: UnitService,
        private elementRef: ElementRef<HTMLElement>) {
            this.selectedChannelFamilyId = -1;
            this.selectedBoltFamilyId = -1;
            this.selectedBoltSizeId = -1;
        }

    ngOnInit(): void {
        this.scrollElement = document.querySelector('.modal') as Element;
        this.dataLoaded = true;

        this.reloadInputs();
    }

    public get design() {
        return this.userService.design;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public async setPropertyValue(value: number, propertyId: IPropertyMetaData) {
        if (this.design.isReadOnlyDesignMode) {
            return;
        }

        await this.calculationService.calculateAsync(this.design, d => d.model[propertyId.id] = value);
    }

    public async setPropertiesValues(properties: Array<[value: number, propertyId: IPropertyMetaData]>) {
        if (this.design.isReadOnlyDesignMode) {
            return;
        }

        await this.calculationService.calculateAsync(this.design, d => {
            properties.forEach(property => {
                d.model[property[1].id] = property[0];
            });
        });
    }

    public reloadInputs() {
        this.products = this.productService.loadProducts();

        this.loadChannelFamilies();
        this.loadBoltFamilies();
        this.loadBoltSizes();
    }

    public loadChannelFamilies() {
        this.channelFamily = this.createDropdownComponent(
            'select-v-para-solution-dropdown-channel-family-dropdown',
            'Agito.Hilti.CW.SelectVParaSolution.ChannelFamily',
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                this.getCodeListItems(DesignCodeList.AnchorChannelFamily, PropertyMetaData.AnchorChannel_CW_Type_VPara.id),
                DesignCodeList.AnchorChannelFamily)
        );

        const selectedChannelFamilyId = this.design.model[PropertyMetaData.AnchorChannel_CW_Type_VPara.id] as number;
        const firstItemId = (this.channelFamily.items && this.channelFamily.items.length > 0) ? this.channelFamily.items[0].value : -1;
        this.selectedChannelFamilyId = this.channelFamily.items?.find(x => x.value == selectedChannelFamilyId)?.value ?? firstItemId;
    }

    public loadBoltFamilies() {
        this.boltFamily = this.createDropdownComponent(
            'select-v-para-solution-dropdown-bolt-family-dropdown',
            'Agito.Hilti.CW.SelectVParaSolution.BoltFamily',
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                this.getCodeListItems(DesignCodeList.BoltFamilies, PropertyMetaData.Bolt_CW_Type_VPara.id),
                DesignCodeList.BoltFamilies)
        );

        const selectedBoltFamilyId = this.design.model[PropertyMetaData.Bolt_CW_Type_VPara.id] as number;
        const firstItemId = (this.boltFamily.items && this.boltFamily.items.length > 0) ? this.boltFamily.items[0].value : -1;
        this.selectedBoltFamilyId = this.boltFamily.items?.find(x => x.value == selectedBoltFamilyId)?.value ?? firstItemId;
    }

    public loadBoltSizes() {
        this.boltSize = this.createDropdownComponent(
            'select-v-para-solution-dropdown-bolt-size-dropdown',
            'Agito.Hilti.CW.SelectVParaSolution.BoltSize',
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                this.getCodeListItems(DesignCodeList.BoltSizes, PropertyMetaData.Bolt_CW_Size_VPara.id),
                DesignCodeList.BoltSizes)
        );

        const selectedBoltSizeId = this.design.model[PropertyMetaData.Bolt_CW_Size_VPara.id] as number;
        const firstItemId = (this.boltSize.items && this.boltSize.items.length > 0) ? this.boltSize.items[0].value : -1;
        this.selectedBoltSizeId = this.boltSize.items?.find(x => x.value == selectedBoltSizeId)?.value ?? firstItemId;
    }

    public async onChannelFamilyChange(value: number) {
        await this.setPropertyValue(value, this.propertyMetaData.AnchorChannel_CW_Type_VPara);
        this.loadBoltFamilies();
        this.loadBoltSizes();
    }

    public async onBoltFamilyChange(value: number) {
        await this.setPropertyValue(value, this.propertyMetaData.Bolt_CW_Type_VPara);
        this.loadBoltSizes();
    }

    public async save() {
        if (this.submitted) {
            return;
        }

        this.submitted = true;

        //submit
        const values: Array<[value: number, propertyId: IPropertyMetaData]> = [];
        values.push([this.selectedChannelFamilyId, this.propertyMetaData.AnchorChannel_CW_Type]);
        values.push([this.selectedBoltFamilyId, this.propertyMetaData.Bolt_CW_Type]);
        values.push([this.selectedBoltSizeId, this.propertyMetaData.Bolt_CW_Size]);

        await this.setPropertiesValues(values);

        this.submitted = false;

        this.modalInstance.close();
    }

    private createDropdownComponent<TValue>(id: string, translationKey: string, items?: DropdownItem<TValue>[], selectedValue?: TValue) {
        const dropdown: DropdownProps<TValue> = {
            id: id,
            title: translationKey ? this.translate(translationKey) : '',
            items,
            selectedValue
        };
        return dropdown;
    }

    private getCodeListItems(codeListProperty: DesignCodeList, targetProperty: number) {
        let items = this.design.designData.designCodeLists[codeListProperty];
        const allowedValues = this.design.properties.get(targetProperty).allowedValues;

        if (items != null && allowedValues != null) {
            items = items.filter(item => allowedValues.includes(item?.id ?? 0));
        }

        return items;
    }
}
