import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import { Subscription } from 'rxjs/internal/Subscription';

import { Injectable, NgZone } from '@angular/core';
import { TimeoutError } from '@microsoft/signalr';
import {
    CommonRegion as Region
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    DesignEvent, IBaseDesign, IProperty, IValidationError, StateChange
} from '@profis-engineering/pe-ui-common/entities/design';
import { IDesignInfo } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { formatKeyValue, trim } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    CalculationServiceBase, ICalculateInternalOptionsBase, IPropertyChanges
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import {
    CommonCodeList as ProjectCodeList
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CantOpenDesignBecauseLockedByOtherUser, DocumentAccessMode } from '@profis-engineering/pe-ui-common/services/document.common';
import { LogMessage } from '@profis-engineering/pe-ui-common/services/logger.common';
import { SignalRConnectionOptions } from '@profis-engineering/pe-ui-common/services/signalr.common';

import { environment } from '../../environments/environmentCW';
import { DesignStandard } from '../entities/code-lists/design-standard';
import { UIPropertyConfigExtended } from '../entities/code-lists/UI-property';
import { Constants } from '../entities/constants';
import { Design, ICalculationResult, IDesignDeps } from '../entities/design';
import {
    ReportOptionsEntity
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Calculation';
import {
    CodeListResponse
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.js';
import {
    DesignEntity
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import {
    DialogsEntity, WarningMessageEntity
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Dialogs';
import {
    CalculateDesignRequest, ConvertDesignRequest, DefaultUnitsEntity, NewDesignFromProjectRequest,
    NewDesignRequest
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Requests';
import {
    CalculationResultResponse, GenerateReportResponse
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Responses';
import {
    UIProperty
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { Unit } from '../entities/generated-modules/Hilti.PE.Units';
import {
    UIPropertyConfig, UIPropertyTexts, UIPropertyValue
} from '../entities/generated-modules/Hilti.PE.UserInterfaceProperties';
import { PropertyMetaData, UIProperties } from '../entities/properties';
import { sanitizePropertyValueForJson } from '../helpers/serialization-helper';
import { toCwUnit } from '../helpers/unit-helper';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { BrowserService } from './browser.service';
import { ChangesService } from './changes.service';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { DocumentService } from './document.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { RoutingService } from './routing.service';
import { ISignalRServiceConstructor, SignalRService } from './signalr.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';
import { IApplicationError } from '@profis-engineering/pe-ui-common/entities/application-error';
import { TrackingService } from './tracking.service';
import { DesignStandards } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { DesignService } from './design.service';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { ISaveDesignResult } from '@profis-engineering/pe-ui-common/entities/save-design';
import { DesignTemplateService } from './design-template.service';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { ProjectAndDesignView } from '@profis-engineering/pe-ui-common/services/user.common';
import { MessageHelper } from '../helpers/message-helper';
import { UtilizationsHelper } from '../helpers/utilizations-helper';
import { GetImageRequest, GetImageResponse, UploadImageRequest } from '../entities/generated-modules/Hilti.CW.CalculationController.Web.Cqrs.CustomImages';
import { DownloadFileOptions } from '../entities/download-file-options';
import { ProjectOpenType } from '../entities/tracking-entities';

export enum Status { Canceled = 'canceled' }

@Injectable({
    providedIn: 'root'
})
export class CalculationService extends CalculationServiceBase {
    private calculationSubscriptions: { [key: string]: Subscription } = {};
    private calculationCanceled: { [key: string]: boolean } = {};

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService,
        private codeListService: CodeListService,
        private commonCodeListService: CommonCodeListService,
        private apiService: ApiService,
        private logger: LoggerService,
        private guid: GuidService,
        private changesService: ChangesService,
        private user: UserService,
        private modalService: ModalService,
        private authenticationService: AuthenticationService,
        private documentService: DocumentService,
        private routingService: RoutingService,
        private browserService: BrowserService,
        private trackingService: TrackingService,
        private designService: DesignService,
        private designTemplateService: DesignTemplateService,
        private ngZone: NgZone
    ) {
        super();
    }

    private timeoutPromise<T>(promise: Promise<T>, timeoutMs?: number): Promise<T> {
        const timeoutPromise = new Promise<T>((resolve, reject) => {
            const id = setTimeout(() => {
                clearTimeout(id);
                reject(new TimeoutError());
            }, timeoutMs);
        });

        return Promise.race([
            promise,
            timeoutPromise
        ]);
    }

    private calculationDone(calculateId: string, cancellationToken?: Promise<void>): Promise<CalculationResultResponse> {
        let rejection: (reason?: any) => void;
        this.calculationCanceled[calculateId] = false;

        if (cancellationToken) {
            cancellationToken.finally(() => {
                this.calculationCanceled[calculateId] = true;
                this.calculationSubscriptions[calculateId]?.unsubscribe();
                rejection();
            });
        }

        const calculationPromise = new Promise<CalculationResultResponse>((resolve, reject) => {
            rejection = reject;

            this.calculationSubscriptions[calculateId] = this.signalRservice.common.calculationDone.subscribe({
                next: (result: CalculationResultResponse) => {

                    if (result.validationError != null && result.validationError.id == -1) {
                        resolve(result);
                        this.calculationSubscriptions[calculateId]?.unsubscribe();
                    }

                    if (this.calculationCanceled[result.id] || result.id != calculateId) {
                        return;
                    }

                    resolve(result);
                    this.calculationSubscriptions[result.id]?.unsubscribe();
                },
                error: (err: any) => {
                    reject(err);
                    this.calculationSubscriptions[calculateId]?.unsubscribe();
                }
            });

        });

        return this.timeoutPromise<CalculationResultResponse>(calculationPromise, environment.signalRTimeoutInMilliseconds)
            .catch((err) => {
                if (err instanceof TimeoutError) {
                    this.modalService.openAlertError(
                        this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.Title'),
                        this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.ServerOverloadDescription')
                    );
                }

                throw err;
            });
    }

    private projectCodeListDone(): Promise<CodeListResponse> {
        const projectCodeListPromise = new Promise<CodeListResponse>((resolve, reject) => {
            this.signalRservice.common.projectCodeListDone.subscribe({
                next: (response: CodeListResponse) => {
                    resolve(response);
                },
                error: (err: any) => {
                    reject(err);
                },
            });
        });

        return this.timeoutPromise<CodeListResponse>(projectCodeListPromise, environment.signalRTimeoutInMilliseconds)
            .catch(() => {
                return {} as CodeListResponse;
            });
    }

    private generateReportDone(): Promise<GenerateReportResponse> {
        const calculationPromise = new Promise<GenerateReportResponse>((resolve, reject) => {
            this.signalRservice.common.generateReportDone.subscribe({
                next: (response: GenerateReportResponse) => {
                    resolve(response);
                },
                error: (err: any) => {
                    reject(err);
                },
            });
        });

        return this.timeoutPromise<GenerateReportResponse>(calculationPromise, environment.signalRTimeoutInMilliseconds)
            .catch((err) => {
                if (err instanceof TimeoutError) {
                    this.modalService.openAlertError(
                        this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.Title'),
                        this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.ServerOverloadDescription')
                    );
                }

                throw err;
            });
    }

    private settlePromises(requestPromise: Promise<any>, responsePromise: Promise<any>) {
        return Promise.allSettled([requestPromise, responsePromise])
            .then(results => {
                const promiseResponse = results[1];

                if (promiseResponse.status !== 'fulfilled' || promiseResponse.value === undefined)
                    throw new Error('No results');

                return promiseResponse.value;
            });
    }

    private _signalRservice?: SignalRService;
    private get signalRservice(): SignalRService {
        if (this._signalRservice == null) {
            this._signalRservice = this.createSignalRService();
        }

        return this._signalRservice;
    }

    public async loadInitialData() {
        this.codeListService.initialize(await this.getProjectCodeListInternal());
    }

    public async createNewDesign(request: NewDesignRequest): Promise<ICalculationResult> {
        const design = new Design(this.getDesignDeps());
        return this.createNewDesignInternal(design, request);
    }

    private async createNewDesignInternal(design: Design, request: NewDesignRequest): Promise<ICalculationResult> {
        const { numberDecimalSeparator, numberThousandsSeparator } = this.userSettings.getNumberSeparators();
        request.numberDecimalSeparator = numberDecimalSeparator;
        request.numberThousandsSeparator = numberThousandsSeparator;
        request.language = this.localization.selectedLanguage;
        request.id = design.guid.new();

        design.projectOpenType = ProjectOpenType.Blank;

        try {
            const response = await this.settlePromises(this.signalRservice.common.request<NewDesignRequest, CalculationResultResponse>('CreateNewDesign', request), this.calculationDone(request.id));
            this.updateCalculationData(design, response, this.localization.selectedLanguage);

            await this.documentService.addDesignCommon(request.projectId, design, true, false);

            this.trackOnDesignOpen(design);

            return this.saveDesignStateInternal(design, response.dialogs, new Deferred());
        }
        catch (err: any) {
            this.logger.logServiceError(err, 'Design', 'CreateNewDesign');
            throw err;
        }
    }

    private async getProjectCodeListInternal(): Promise<CodeListResponse> {
        return this.settlePromises(this.signalRservice.common.request<object, CodeListResponse>('GetProjectCodeList', {}, {}, true), this.projectCodeListDone())
            .then(async (response) => {
                return response;
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'CalculationService', 'GetProjectCodeList');
                throw response;
            });
    }

    public openFromDocumentDesign(documentDesign: IBaseDesign, setDesignAndRedirect = true) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps);
        design.updateFromDocumentDesign(documentDesign);

        return this.documentService.openDesignExclusive<DesignEntity>(documentDesign, (content, designName, projectName) => {
                content.designName = designName;
                content.projectName = projectName;
                return content;
            })
            .then((projectDesign) => this.createFromProjectDesignInternal(design, projectDesign))
            .then((result) => {
                if (setDesignAndRedirect) {
                    this.user.changeDesign(this.documentService.findProjectById(design.projectId), design);
                    this.routingService.navigateToUrl(`${Constants.DesignPath}/${design.id}`);
                }

                return result;
            });
    }

    private async getTemplateDesign(template: DesignTemplateEntity): Promise<Design> {
        const projectDesign = JSON.parse(template.ProjectDesign) as DesignEntity;

        const calculationResults = await this.createAndOpenFromProjectDesign(projectDesign, this.documentService.draftsProject.id as string, template.DesignTemplateDocumentId);

        const design = calculationResults.design;
        design.id = template.DesignTemplateDocumentId;
        design.designName = template.DesignTemplateName;
        design.projectOpenType = ProjectOpenType.TemplateEdit;

        return design;
    }

    public async newDesignFromTemplate(designId: string, projectId?: string, designName?: string) {
        try {
            const template = await this.designTemplateService.getById(designId);

            const projectDesign = JSON.parse(template.ProjectDesign) as DesignEntity;

            const project = projectId ? this.documentService.findProjectById(projectId) : this.documentService.draftsProject;

            projectDesign.designName =  designName ?? this.documentService.createUniqueName(
                this.designService.getNewDesignName(),
                Object.values(this.documentService.draftsProject.designs ?? []).map((item) => item.designName));

            projectDesign.projectName = project?.name as string;

            const calculationResults = await this.createAndOpenFromProjectDesign(projectDesign,projectId ?? this.documentService.draftsProject.id as string);
            calculationResults.design.projectOpenType = ProjectOpenType.BlankFromTemplate;

            this.user.changeDesign(project, calculationResults.design);
            this.routingService.navigateToUrl(UrlPath.main + calculationResults.design.id);

            return {
                designId: calculationResults.design.id,
                path: UrlPath.main + calculationResults.design.id,
                design: calculationResults.design,
                success: true
            } as ISaveDesignResult;

        } catch (err) {
            if (err instanceof Error) {
                console.error(err);
            }
            return {
                path: UrlPath.main,
                success: false
            } as ISaveDesignResult;
        }
    }

    public async openTemplate(templateId: string) {
        try {
            const template = await this.designTemplateService.getById(templateId);
            const design = await this.getTemplateDesign(template);

            this.user.changeDesign(undefined, design);
            this.routingService.navigateToUrl(UrlPath.main + design.id);

            this.trackOnTemplateOpen(design);

            return {
                designId: design.id,
                path: UrlPath.main + design.id,
                design,
                success: true
            } as ISaveDesignResult;

        } catch (err) {
            if (err instanceof Error) {
                console.error(err);
            }

            return {
                path: UrlPath.main,
                success: false
            } as ISaveDesignResult;
        }
    }

    public async openTemplateSettings(templateId: string, designInfo: IDesignInfo, onClosed?: () => void) {
        const template = await this.designTemplateService.getById(templateId);
        const design = await this.getTemplateDesign(template);

        if (!design.confirmChangeInProgress) {
            this.onTemplateOpenSettings(template, design, designInfo, onClosed);
        }
        else {
            design.on(DesignEvent.designChangesConfirmed, () => this.onTemplateOpenSettings(template, design, designInfo, onClosed));
        }
    }

    private onTemplateOpenSettings(template: DesignTemplateEntity, design: Design, designInfo: IDesignInfo, onClosed?: () => void) {
        const region = this.commonCodeListService.commonCodeLists[ProjectCodeList.Region].find((codeList) => codeList.id == design.regionId) as Region;

        const project = this.documentService.findProjectById(design.projectId);
        this.user.changeDesign(project, design);

        this.modalService.openAddEditDesignFromModule({
            design: {
                id: design.id,
                name: template.DesignTemplateName,
                projectId: design.projectId,
                projectName: design.projectName,
                region,
                designType: designInfo.designTypeId,
                displayDesignType: DisplayDesignType.template
            },
            selectedModuleDesignInfo: designInfo,
            addEditType: AddEditType.edit,
            onDesignEdited: () => {
                return this.designTemplateService.update({
                    designTemplateDocumentId: template.DesignTemplateDocumentId,
                    designTypeId: designInfo.designTypeId,
                    designStandardId: design.designStandardId,
                    regionId: design.region.id,
                    templateName: design.designName,
                    anchorName: template.AnchorName,
                    approvalNumber: template.ApprovalNumber,
                    projectDesign: JSON.stringify(design.projectDesign)
                });
            }
        }).closed
            .finally(() => {
                // template has no publish so we don't need to call result.design.publish();
                if (onClosed) {
                    onClosed();
                }
            });
    }

    public async copyFromDocumentDesign(designId: string, designName: string, projectId: string) {
        const documentDesign = this.documentService.findDesignById(designId);
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps);
        design.updateFromDocumentDesign(documentDesign);

        design.designData.projectDesign = await this.documentService.openDesignExclusive<DesignEntity>(documentDesign);
        design.designName = designName;
        design.region = { id: documentDesign.metaData.region } as Region;
        design.designStandard = { id: documentDesign.metaData.standard } as DesignStandard;

        return this.documentService.addDesignCommon(projectId, design, true, false);
    }

    public async openDesignSettings(designId: string, regionId: number, designInfo: IDesignInfo, onClose?: () => void) {
        const documentDesign = this.documentService.findDesignById(designId);

        let projectDesign: DesignEntity;

        try {
            projectDesign = await this.documentService.openDesignExclusive<DesignEntity>(documentDesign);
        } catch (err: any) {
            if ('username' in err || err instanceof CantOpenDesignBecauseLockedByOtherUser) {
                const lockedEx: CantOpenDesignBecauseLockedByOtherUser = err;
                this.modalService.openAlertWarning(
                    this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title'),
                    formatKeyValue(this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description'), {
                        user: lockedEx.username ?? ''
                    })
                );
            }

            throw err;
        }

        const region = this.commonCodeListService.commonCodeLists[ProjectCodeList.Region].find((codeList) => codeList.id == regionId) as Region;
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps);

        design.updateFromDocumentDesign(documentDesign);

        // Skip confirm dialog display - we will display it manually
        design.confirmChangeInProgress = true;
        const result = await this.createFromProjectDesignInternal(design, projectDesign);
        design.confirmChangeInProgress = false;

        const project = this.documentService.findProjectById(design.projectId);

        this.user.changeDesign(project, result.design);

        this.modalService.openAddEditDesignFromModule({
            design: {
                id: design.id,
                name: design.designName,
                projectId: design.projectId,
                projectName: design.projectName,
                region,
                designType: designInfo.designTypeId,
                displayDesignType: 0
            },
            selectedModuleDesignInfo: designInfo,
            addEditType: AddEditType.edit,
            onDesignEdited: (_, project) => {
                design.projectId = project.id ?? design.projectId;
                design.projectName = project.getDisplayName(this.localization) ?? design.projectName;
                this.user.changeDesign(project as Project, design);
                this.user.projectAndDesignView = project == this.documentService.draftsProject
                    ? ProjectAndDesignView.drafts
                    : ProjectAndDesignView.projects;

                return this.calculateAsync(design, undefined, { suppressLoadingFlag: true })
                    .then(() => undefined);
            }
        }).closed.finally(() => {
            // Unlock design after dialog is closed
            this.documentService.updateDesignWithNewContentCommon(design, null, true, false, DocumentAccessMode.Update);
            onClose?.();
        });


        this.checkDialogs(design, result.dialogs ?? {} as DialogsEntity);
    }

    private async createFromProjectDesignInternal(design: Design, projectDesign: DesignEntity, updateDocumentServiceFn?: () => Promise<void>) {
        // OpenExisting is the default open type when creating from design but calling method is responsible for setting the correct type (e.g. when creating from template, importing file, editing template)
        design.projectOpenType = ProjectOpenType.OpenExisting;

        const { numberDecimalSeparator, numberThousandsSeparator } = this.userSettings.getNumberSeparators();

        const request: NewDesignFromProjectRequest = {
            id: design.guid.new(),
            design: projectDesign,
            language: this.localization.selectedLanguage,
            numberDecimalSeparator: numberDecimalSeparator,
            numberThousandsSeparator: numberThousandsSeparator,
            forceFreeLicense: this.userSettings.settings.application.general.forceFreeLicense.value ?? false
        };

        if (!environment.cwEnabled) {
            this.modalService
                .openAlertError(
                    this.localization.getString('Agito.Hilti.Profis3.FileUpload.UnsupportedFileAlert.Title'),
                    this.localization.getString('Agito.Hilti.CW.DesignVerification.Obsolete.StandardOrRegion')
                );
            throw new Error('notAllowedCW');
        }

        try {
            const response: CalculationResultResponse = await this.settlePromises(this.signalRservice.common.request('CreateNewDesignFromProjectDesign', request), this.calculationDone(request.id));
            if (this.checkForErrors(response)) {
                this.modalService.openAlertError(
                    this.localization.getString('Agito.Hilti.Profis3.FileUpload.UnsupportedFileAlert.Title'),
                    MessageHelper.getValidationErrorMessage(response.validationError, this.localization)
                );

                // error already handled and modal popup closed by user
                throw 'closed';
            }

            const messagesClosedDeferred = new Deferred();
            this.updateCalculationData(design, response, request.language, messagesClosedDeferred);

            if (updateDocumentServiceFn != null)
                await updateDocumentServiceFn();

            if (design.isTemplate)
                this.trackOnTemplateOpen(design);
            else
                this.trackOnDesignOpen(design);

            return this.saveDesignStateInternal(design, response.dialogs, messagesClosedDeferred);
        }
        catch (err: any) {
            this.logger.logServiceError(err, 'Design', 'CreateNewDesignFromProjectDesign');
            throw err;
        }
    }

    private checkForErrors(calculationResult: CalculationResultResponse) {
        return calculationResult.validationError != null && calculationResult.validationError.id == -1;
    }

    public calculateAsync(design: Design, changeFn?: (design: Design) => void, options?: ICalculateInternalOptionsBase) {
        return this.calculateAsyncHelper(this.calculate.bind(this), design, changeFn, options);
    }

    public calculate(design: Design, calculateId: string) {
        design.cancellationToken = new Deferred<void>();
        const cancellationToken = design.cancellationToken.promise;

        const request = this.getCalculateDesignRequestData(design, calculateId, false, false);
        design.confirmedProperties = [];

        this.settlePromises(this.signalRservice.common.request<CalculateDesignRequest, CalculationResultResponse>('CalculateDesign', request, { cancel: cancellationToken }), this.calculationDone(calculateId, cancellationToken))
            .then((response) => {
                if (this.calculationCanceled[calculateId])
                    return Status.Canceled;

                const messagesClosedDeferred = new Deferred();
                design.trigger(DesignEvent.beforeUpdate);


                const modelChanges = this.updateCalculationData(design, response, request.localization.language, messagesClosedDeferred);
                this.checkCalculationChanges(design, calculateId, modelChanges, messagesClosedDeferred);

                if (design.isTemplate) {
                    const templateDocument = this.designService.designToTemplateDocument(design, design.id, design.designName);

                    this.designTemplateService.update(templateDocument);

                    return response;
                }

                this.documentService.updateDesignWithNewContentCommon(design, null, false, false, DocumentAccessMode.Update);

                this.trackOnDesignChange(design);

                return response;
            })
            .catch((err) => this.catchCalculationException(err, design, this.calculationCanceled[calculateId], calculateId, this.logger))
            .then((response) => this.removeLoadingFlag(response, design, calculateId))
            .then(() => this.clearRequestCache());

        this.afterCalculation(design);
    }

    public async convertDesign(xmlFileContent: string): Promise<DesignEntity> {
        const { numberDecimalSeparator, numberThousandsSeparator } = this.userSettings.getNumberSeparators();

        const availableRegions = this.codeListService.designType?.regions ?? [];

        const defaultRegionUnits: { [key: number]: DefaultUnitsEntity } = {};
        const regions = this.commonCodeListService.commonCodeLists[ProjectCodeList.Region].filter(p => availableRegions.includes(p.id)) as Region[];
        regions.forEach(r => {
            defaultRegionUnits[r.id] = {
                unitForcePerLength: r.defaultUnitForcePerLength != null ? toCwUnit(r.defaultUnitForcePerLength) : Unit.kN_m,
                unitMomentPerLength: r.defaultUnitMomentPerLength != null ? toCwUnit(r.defaultUnitMomentPerLength) : Unit.kNm_m,
            };
        });

        const request: ConvertDesignRequest = {
            xmlFileContent: xmlFileContent,
            language: this.localization.selectedLanguage,
            numberDecimalSeparator: numberDecimalSeparator,
            numberThousandsSeparator: numberThousandsSeparator,
            defaultRegionUnits: defaultRegionUnits,
            id: this.guid.new()
        };

        try {
            const calculationResult: CalculationResultResponse = await this.settlePromises(
                this.signalRservice.common.request<ConvertDesignRequest, CalculationResultResponse>('ConvertDesign', request),
                this.calculationDone(request.id));

            const deferred = new Deferred();

            if (calculationResult.validationError == null) {
                this.checkDialogs(undefined, calculationResult.dialogs, deferred);
                await deferred.promise;

                return calculationResult.design;
            }
            else {
                const validationErrorMsg = MessageHelper.getValidationErrorMessage(calculationResult.validationError, this.localization);
                this.modalService.openAlertError('Import error', validationErrorMsg);

                throw new Error(`Import error: ${validationErrorMsg}`, {
                    cause: 'import_error'
                });
            }
        }
        catch (err: any) {
            this.logger.logServiceError(err, 'CalculationService', 'ConvertDesign');
            throw err;
        }
    }

    public getLatestAppErrorInfo(): IApplicationError {
        return {
            correlationId: this.signalRservice.common?.requestId,
            requestPayload: this.signalRservice.common?.requestData,
            responsePayload: this.signalRservice.common?.responseData,
            endPointUrl: this.signalRservice.common?.connectionUrl
        };
    }

    private clearRequestCache() {
        for (const requestId in this.calculationSubscriptions) {
            if (this.calculationSubscriptions[requestId].closed) {
                delete this.calculationSubscriptions[requestId];
                delete this.calculationCanceled[requestId];
            }
        }
    }

    public downloadCalculationLog(design: Design) {
        const downloadOptions: DownloadFileOptions = {
            downloadCalculationLog: true,
            downloadExternalDesign: false
        };

        this.downloadDebugFile(design, downloadOptions, (response: any) => {
            const input = this.browserService.fromBase64(response.designReportData.kernelInputsSerialized);
            const output = response.designReportData.kernelOutputLog;
            const logContent = this.browserService.encodeB64(`${input}\n${output}`);

            const fileName = `${design.designData.projectDesign?.designName} (${design.designData.projectDesign?.projectName})`;
            const inputBlob = this.browserService.base64toBlob(logContent, 'application/json');
            this.browserService.downloadBlob(inputBlob, `${fileName}-log.json`, true, true);

            return response;
        });
    }

    public downloadExternalDesign(design: Design) {
        const downloadOptions: DownloadFileOptions = {
            downloadCalculationLog: false,
            downloadExternalDesign: true
        };

        this.downloadDebugFile(design, downloadOptions, (response: CalculationResultResponse) => {
            const content = this.browserService.encodeB64(response.designReportData.externalDesign ?? '');

            const fileName = `${design.designData.projectDesign?.designName} (${design.designData.projectDesign?.projectName})`;
            const inputBlob = this.browserService.base64toBlob(content, 'application/xml');
            this.browserService.downloadBlob(inputBlob, `${fileName}-S2C.pe`, true, true);

            return response;
        });
    }

    private downloadDebugFile(design: Design, downloadFileOptions: DownloadFileOptions, downloadFileFn: (response: any) => any) {
        const calculateId = design.guid.new();
        design.cancellationToken = new Deferred<void>();
        const cancellationToken = design.cancellationToken.promise;

        const request = this.getCalculateDesignRequestData(design, calculateId, false, downloadFileOptions.downloadCalculationLog, downloadFileOptions.downloadExternalDesign);

        this.settlePromises(this.signalRservice.common.request<CalculateDesignRequest, CalculationResultResponse>('CalculateDesign', request, { cancel: cancellationToken }), this.calculationDone(calculateId, cancellationToken))
            .then((response: CalculationResultResponse) => {
                if (this.calculationCanceled[calculateId])
                    return Status.Canceled;

                return downloadFileFn(response);
            })
            .catch((err) => this.catchCalculationException(err, design, this.calculationCanceled[calculateId], calculateId, this.logger))
            .then((response) => this.removeLoadingFlag(response, design, calculateId))
            .then(() => this.clearRequestCache());

        this.afterCalculation(design);
    }

    public async createAndOpenFromProjectDesign(projectDesign: DesignEntity, projectId: string, templateId?: string): Promise<ICalculationResult> {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps);

        design.templateId = templateId;
        design.isTemplate = templateId != null;

        let addDesign: (() => Promise<void>) | undefined;

        // Add document only if it is not a template
        if (!design.isTemplate) {
            addDesign = async () => {
                await this.documentService.addDesignCommon(projectId, design, true, false);
            };
        }

        const result = await this.createFromProjectDesignInternal(design, projectDesign, addDesign);

        return result;
    }

    public async updateDesignFromExternalFile(oldDesign: IBaseDesign, projectDesign: DesignEntity) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps);
        design.updateFromDocumentDesign(oldDesign);

        await this.documentService.openDesignExclusive(oldDesign);
        await this.createFromProjectDesignInternal(design, projectDesign, async () => {
            await this.documentService.updateDesignWithNewContentCommon(design, null, false, false, DocumentAccessMode.Update);
        });

        return design;
    }

    public async trackOnDesignOpen(design: Design): Promise<void> {
        await this.trackingService.trackOnDesignOpen(design.id, design.designStandard?.id ?? DesignStandards.None);
        await this.trackOnDesignChange(design);
    }

    public trackOnDesignChange(design: Design): Promise<void> {
        design.usageCounter.DateClosed = new Date();

        return this.trackingService.trackOnDesignChange(
            design.id,
            design.projectDesign,
            design.usageCounter.toTrackingEntity(),
            design.projectOpenType,
            design.createdFromTemplate,
            UtilizationsHelper.getAllUtilizations(design, design.selectedLoadCombinationId),
            design.trackingDisplayNames,
            design.templateId);
    }

    public trackOnDesignClose(design: Design, isBrowserUnloadEvent = false): Promise<void> {
        design.usageCounter.DateClosed = new Date();

        return this.trackingService.trackOnDesignClose(
            design.id,
            design.projectDesign,
            design.usageCounter.toTrackingEntity(),
            design.projectOpenType,
            design.createdFromTemplate,
            UtilizationsHelper.getAllUtilizations(design, design.selectedLoadCombinationId),
            design.trackingDisplayNames,
            design.templateId,
            isBrowserUnloadEvent);
    }

    public trackOnTemplateOpen(design: Design): Promise<void> {
        return this.trackingService.trackOnTemplateOpen(design.templateId as string, design.designStandard?.id ?? DesignStandards.None);
    }

    public trackOnTemplateClose(design: Design): Promise<void> {
        return this.trackingService.trackOnTemplateClose(
            design.templateId as string,
            design.projectDesign,
            design.usageCounter.toTrackingEntity(),
            design.projectOpenType,
            design.createdFromTemplate);
    }

    private checkDialogs(design: Design | undefined, dialogs: DialogsEntity, messagesClosedDeferred?: Deferred<void>) {
        let messagesToProcess: WarningMessageEntity[] = [];

        messagesToProcess = this.getMessagesToProcess(dialogs);
        this.showPopUp(messagesToProcess, design, dialogs, messagesClosedDeferred);
    }

    private getMessagesToProcess(dialogs: DialogsEntity) {
        let messagesToProcess: WarningMessageEntity[] = [];
        const messages = dialogs.warningMessages;

        const getTranslationIfAnyAndEscape = (stringValue: string) => {
            let message = '';
            if (this.localization.getKeyExists(stringValue)) {
                message = this.localization.getString(stringValue);
            }
            else if (stringValue?.length > 0) {
                message = stringValue;
            }

            const sanitizeTags = { ...LocalizationService.PBrB, ...LocalizationService.H1OlLi, ...LocalizationService.SubSup, ...LocalizationService.I };

            return this.localization.sanitizeText(message, sanitizeTags);
        };

        if (messages?.length > 0) {
            // Get changes with confirm button only and merge them into one change message
            let msgMash: WarningMessageEntity | null = null;
            if (messages.length > 0) {
                let messageStrings = '';

                if (messages.length == 1) {
                    messageStrings = getTranslationIfAnyAndEscape(messages[0].message);
                }
                else if (messages.length > 1) {
                    messageStrings = `<html lang="en"><ul>` + messages.reduce((acc, msg) => acc + `<br>${getTranslationIfAnyAndEscape(msg.message)}</li>`, '') + '</ul></html>';
                }

                msgMash = ({
                    title: getTranslationIfAnyAndEscape(messages[0].title),
                    message: `${messageStrings}`
                });
            }

            if (msgMash != null) {
                messagesToProcess.push(msgMash);
            }
            messagesToProcess = [...messagesToProcess];
        }

        return messagesToProcess;
    }

    private showPopUp(messagesToProcess: WarningMessageEntity[], design: Design | undefined, dialogs: DialogsEntity, messagesClosedDeferred?: Deferred<void>) {
        const messageEntity = messagesToProcess.length > 0 ? messagesToProcess[0] : null;
        if (messageEntity != null) {
            const onClose = () => {
                dialogs.warningMessages = messagesToProcess.slice(1);
                this.checkDialogs(design, dialogs, messagesClosedDeferred);
            };

            if (messageEntity.message != '' && messageEntity.title != '') {
                this.modalService.openConfirmChange({
                    id: 'warning-cw',
                    title: messageEntity.title,
                    message: messageEntity.message,
                    confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                    onConfirm: (modal) => {
                        design?.trigger(DesignEvent.designChangesConfirmed);
                        modal.close();
                    }
                }).closed.then(onClose);
            }
        }
        else if (dialogs.showDesignChangedPopup) {
            this.handleDesignChangedPopup(design, dialogs, messagesClosedDeferred);
        }
        else if (dialogs.showPostInstallAnchorsNotAllowedPopup) {
            this.handlePostInstallAnchorsNotAllowedPopup(design, dialogs, messagesClosedDeferred);
        }
        else if (dialogs.showSeismicUncrackedConcretePopup) {
            this.handleShowSeismicUncrackedConcretePopup(design, dialogs, messagesClosedDeferred);
        }
        else {
            if (design != null) {
                design.confirmChangeInProgress = false;
            }

            if (messagesClosedDeferred != null) {
                messagesClosedDeferred.resolve();
            }
        }
    }

    private handleDesignChangedPopup(design: Design | undefined, dialogs: DialogsEntity, messagesClosedDeferred?: Deferred<void>) {
        this.modalService.openConfirmChange({
            id: 'designChangedPopup',
            title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
            message: this.localization.getString('Agito.Hilti.CW.DesignChangedPopup.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Cancel'),
            onConfirm: (modal) => modal.close(),
            onCancel: (modal) => {
                this.undo(design);
                // removing last (cancelled) state

                if (design != null) {
                    design.states = design.states.slice(0, design.states.length - 1);
                }

                modal.close();
            }
        }).closed.then(() => {
            dialogs.showDesignChangedPopup = false;
            this.checkDialogs(design, dialogs, messagesClosedDeferred);
        });
    }

    private async handlePostInstallAnchorsNotAllowedPopup(design: Design | undefined, dialogs: DialogsEntity, messagesClosedDeferred?: Deferred<void>) {
        this.modalService.openConfirmChange({
            id: 'postInstallAnchorsNotAllowedPopup',
            title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
            message: this.localization.getString('Agito.Hilti.CW.PostInstallAnchorsNotAllowedPopup.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
            onConfirm: (modal) => modal.close()
        }).closed.then(() => {
            dialogs.showPostInstallAnchorsNotAllowedPopup = false;
            this.checkDialogs(design, dialogs, messagesClosedDeferred);
        });
    }

    private handleShowSeismicUncrackedConcretePopup(design: Design | undefined, dialogs: DialogsEntity, messagesClosedDeferred?: Deferred<void>) {
        this.modalService.openConfirmChange({
            id: 'seismicUncrackedConcretePopup',
            title: this.localization.getString('Agito.Hilti.Profis3.Confirmation'),
            message: this.localization.getString('Agito.Hilti.CW.SeismicUncrackedConcretePopup.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.CW.SeismicUncrackedConcretePopup.Confirm.Yes'),
            cancelButtonText: this.localization.getString('Agito.Hilti.CW.SeismicUncrackedConcretePopup.Confirm.No'),
            onCancel: (modal) => modal.close(),
            onConfirm: (modal) => {
                if (design != null) {
                    this.ngZone.run(() => {
                        this.updateUiProperty(this.user.design, PropertyMetaData.BaseMaterial_CW_CrackedConcrete.id, true);
                    });
                }
                modal.close();
            }
        }).closed.then(() => {
            dialogs.showSeismicUncrackedConcretePopup = false;
            this.checkDialogs(design, dialogs, messagesClosedDeferred);
        });
    }

    public checkCalculationChanges(design: Design, calculateId: string, modelChanges: { [property: string]: Change } | null, messagesClosedDeferred: Deferred) {
        const combinedChanges = design.changes.chainChanges(design.lastModelChanges ?? [], modelChanges != null ? Object.values(modelChanges) : []);

        if (combinedChanges != null && combinedChanges.length > 0) {
            // save state if validation was ok
            design.saveState(StateChange.server);
        }

        if (modelChanges != null) {
            // trigger calculate event
            design.trigger(DesignEvent.calculate, design, Object.values(modelChanges));
        }

        // resolve calculate promise
        if (design.calculateId == calculateId) {
            design.calculateDefer.resolve({ reportData: null, design, calculationCanceled: false, messagesClosed: messagesClosedDeferred.promise });
            design.calculateDefer = new Deferred<ICalculationResult>();
        }
    }

    public async updateUiProperty(design: Design, uiPropertyId: number, value: any) {
        if (uiPropertyId != null) {
            // Update model and run calculation
            this.calculateAsync(design,
                (design) => {
                    design.model[uiPropertyId] = value;
                }
            );
        }
    }

    private async calculateAsyncHelper(
        calculateFn: (design: Design, calculateId: string) => void,
        design: Design,
        changeFn?: (design: Design) => void,
        options?: ICalculateInternalOptionsBase): Promise<ICalculationResult> {

        changeFn?.(design);

        design.loading = true;
        design.setPendingCalculation(true);
        const calculateId = design.calculateId = design.guid.new();
        const calculateDefer = design.calculateDefer;

        design.modelChanges.observe();

        // trigger event that might change some data before we do the calculation
        design.trigger(DesignEvent.beforeCalculate, design, design.modelChanges.changes);

        design.modelChanges.observe();
        if (options?.changes != null) {
            design.modelChanges.changes.push(...options.changes);
        }

        design.cancelCalculationRequest();

        const anyModelChanges = design.modelChanges.changes != null && design.modelChanges.changes.length > 0;

        if (anyModelChanges || options?.forceCalculation) {
            calculateFn(design, calculateId);
        }
        else {
            // remove loading flag
            if (design.calculateId == calculateId) {
                design.loading = false;
                design.setPendingCalculation(false);

                // resolve
                design.calculateDefer.resolve({ reportData: null, design, calculationCanceled: true, messagesClosed: Promise.resolve() });
                design.calculateDefer = new Deferred<ICalculationResult>();
            }
        }

        return await calculateDefer.promise;
    }

    private afterCalculation(design: Design) {
        // print changes
        if (design.modelChanges.changes != null && design.modelChanges.changes.length > 0) {
            this.logger.logGroup(new LogMessage({
                message: 'Calculate'
            }), design.modelChanges.changes.map((change) => {
                return new LogMessage({
                    message: change.name + ': %o => %o',
                    args: [trim(change.oldValue), trim(change.newValue)]
                });
            }));
        }

        // clear model changes
        design.lastModelChanges = design.modelChanges.changes.slice();  // we might need them if we cancel the request
        design.modelChanges.clear();
    }

    private getCalculateDesignRequestData(design: Design, calculateId: string, isReportCalculation: boolean, withCalculationLog: boolean, withExternalDesign = false, reportOptions: ReportOptionsEntity = {} as ReportOptionsEntity): CalculateDesignRequest {
        const { numberDecimalSeparator, numberThousandsSeparator } = this.userSettings.getNumberSeparators();

        return {
            id: calculateId,
            design: design.designData.projectDesign,
            localization: {
                language: this.localization.selectedLanguage,
                numberDecimalSeparator: numberDecimalSeparator,
                numberThousandsSeparator: numberThousandsSeparator
            },
            calculationOptions: {
                isReportCalculation: isReportCalculation,
                isCalculationLog: withCalculationLog,
                sendExternalDesign: withExternalDesign
            },
            reportOptions: reportOptions,
            uiPropertyUpdates: design.modelChanges.changes.map((change: Change): UIPropertyValue<UIProperty> => {
                return {
                    property: parseInt(change.name, 10),
                    valueJsonElement: sanitizePropertyValueForJson(change.newValue),
                    confirmed: design.confirmedProperties?.some((confirmation) => confirmation == parseInt(change.name, 10)) ?? false,
                    generateReport: false,
                    runAdvancedCalculation: false,
                };
            }),
            forceFreeLicense: this.userSettings.settings.application.general.forceFreeLicense.value ?? false
        };
    }

    private catchCalculationException(err: any, design: Design, isRequestCanceled: boolean, calculateId: string, logger: LoggerService) {
        // is request canceled
        if (isRequestCanceled) {
            return Status.Canceled;
        }

        // if request was canceled don't reject the promise since a new request was started
        logger.logServiceError(err, 'CalculationService', 'CalculateDesign');

        // reload state if we are not changing the page (babylon breaks if you load a texture and dispose the engine before the texture is loaded)
        if (SignalRService.getStatusCode(err) != 401) {
            design.reloadState();
        }

        // reject calculate promise
        if (design.calculateId == calculateId) {
            design.calculateDefer.reject(err);
            design.calculateDefer = new Deferred<ICalculationResult>();
        }

        // must be simple return so we get to the last .then which acts as finally with argument
        return err;
    }

    private removeLoadingFlag(response: any, design: Design, calculateId: string) {
        // remove loading flag if request is not canceled
        if (response != Status.Canceled) {
            design.cancellationToken = undefined;
            design.lastModelChanges = undefined;

            if (design.calculateId == calculateId) {
                design.setPendingCalculation(false);
                design.loading = false;
            }
        }
    }

    private getDesignDeps() {
        return {
            codeListService: this.codeListService,
            calculationService: this,
            logger: this.logger,
            guid: this.guid,
            changes: this.changesService,
            user: this.user,
            localization: this.localization,
            userSettings: this.userSettings,
            commonCodeList: this.commonCodeListService
        } as IDesignDeps;
    }

    private saveDesignStateInternal(design: Design, dialogs: DialogsEntity, messagesClosedDeferred: Deferred<void>): ICalculationResult {
        // save state
        design.saveState();

        // trigger create design event
        design.trigger(DesignEvent.createDesign);

        return {
            design,
            dialogs,
            calculationCanceled: false,
            messagesClosed: messagesClosedDeferred.promise
        } as ICalculationResult;
    }

    private updateModel(design: Design, properties: UIProperties) {
        return design.changeModel((model: { [property: number]: unknown }) => {
            const propertyDict: { [property: number]: unknown } = {};

            for (const name in properties) {
                const propertyConfig: UIPropertyConfig<UIProperty> = (properties as any)[name];

                // don't update the model if there's already a change pending
                if (propertyConfig != null && !design.modelChanges.changes.some((change) => change.name == propertyConfig.property.toString())) {
                    model[propertyConfig.property] = propertyConfig.value;
                    propertyDict[propertyConfig.property] = propertyConfig.value;
                }
            }

            // Two way checking for correct property unassignment (eg. model has an old value stored and is was not returned by the service - should be removed)
            for (const name in model) {
                model[name] = propertyDict[name];
            }

            return model;
        });
    }

    private updatePropertyValuesInternal(design: Design, propertyConfigs: UIPropertyConfigExtended[], keepMissingProperties?: boolean): IPropertyChanges {
        const updatedProperties: { [property: number]: IProperty } = {};
        const uiPropertyIds: { [property: number]: boolean } = {};

        const iPropertyChanges = this.setPropertyConfigs(propertyConfigs, uiPropertyIds, updatedProperties, design);

        // hide properties that were not returned
        if (!keepMissingProperties) {
            for (const key in design.properties.properties) {
                const propertyId = key as any as number;
                const property = design.properties.get(propertyId);

                let newProperty = updatedProperties[propertyId];

                if (!property.hidden && uiPropertyIds[propertyId] === undefined) {
                    newProperty = newProperty || { ...property };

                    newProperty.hidden = true;
                }

                // save change
                if (newProperty != null) {
                    updatedProperties[propertyId] = newProperty;
                }
            }
        }

        // update properties
        design.properties = design.properties.update(updatedProperties);

        return iPropertyChanges;
    }

    private setPropertyConfigs(propertyConfigs: UIPropertyConfigExtended[], uiPropertyIds: { [property: number]: boolean }, updatedProperties: { [property: number]: IProperty }, design: Design): IPropertyChanges {
        const updatedAllowedValues: number[] = [];
        const updatedDisabled: number[] = [];
        const updatedHidden: number[] = [];
        const updatedMin: number[] = [];
        const updatedMax: number[] = [];
        const updatedDisplayKey: number[] = [];
        const updatedTooltipTitle: number[] = [];
        const updatedTooltip: number[] = [];
        const updatedTitleDisplayKey: number[] = [];
        const updatedItemsTooltip: number[] = [];
        const updatedSize: number[] = [];

        propertyConfigs.filter(c => c != null).forEach((propertyConfig: UIPropertyConfigExtended) => {
            const propertyId = propertyConfig.Property as unknown as number;
            uiPropertyIds[propertyId] = true;

            let newProperty = updatedProperties[propertyId];
            const property = design.properties.get(propertyId);

            const allowedValues: number[] = propertyConfig['AllowedValues'];
            const disabledValues: number[] = propertyConfig['DisabledValues'];
            const allowedValuesUniqueId: string = propertyConfig['AllowedValuesUniqueId'];
            const disabled = propertyConfig.Editable !== true;
            const hidden = propertyConfig.Visible !== true;
            const size = propertyConfig.Size;

            // allowed values
            if (property.allowedValuesUniqueId != allowedValuesUniqueId) {
                newProperty = newProperty || { ...property };
                newProperty.allowedValues = allowedValues;
                newProperty.allowedValuesUniqueId = allowedValuesUniqueId;

                updatedAllowedValues.push(propertyId);
            }

            // disabled values
            if (!isEqual(property.disabledValues, disabledValues)) {
                newProperty = newProperty || { ...property };
                newProperty.disabledValues = disabledValues;
            }

            // disabled
            if (property.disabled !== disabled) {
                newProperty = newProperty || { ...property };
                newProperty.disabled = disabled;

                updatedDisabled.push(propertyId);
            }

            // hidden
            if (property.hidden !== hidden) {
                newProperty = newProperty || { ...property };
                newProperty.hidden = hidden;

                updatedHidden.push(propertyId);
            }

            // size
            if (property.size !== size) {
                newProperty = newProperty || { ...property };
                newProperty.size = size;

                updatedSize.push(propertyId);
            }

            // min
            if (property.min !== propertyConfig.MinValue) {
                newProperty = newProperty || { ...property };
                newProperty.min = propertyConfig.MinValue;

                updatedMin.push(propertyId);
            }

            // max
            if (property.max !== propertyConfig.MaxValue) {
                newProperty = newProperty || { ...property };
                newProperty.max = propertyConfig.MaxValue;

                updatedMax.push(propertyId);
            }

            // display key
            if (property.displayKey !== propertyConfig.Texts.displayKey) {
                newProperty = newProperty || { ...property };
                newProperty.displayKey = propertyConfig.Texts.displayKey;

                updatedDisplayKey.push(propertyId);
            }

            // title
            if (property.titleDisplayKey !== propertyConfig.Texts.titleDisplayKey) {
                newProperty = newProperty || { ...property };
                newProperty.titleDisplayKey = propertyConfig.Texts.titleDisplayKey;

                updatedTitleDisplayKey.push(propertyId);
            }

            // tooltip title
            if (property.tooltipTitle !== propertyConfig.Texts.tooltipTitle) {
                newProperty = newProperty || { ...property };
                newProperty.tooltipTitle = propertyConfig.Texts.tooltipTitle;

                updatedTooltipTitle.push(propertyId);
            }

            // tooltip
            if (property.tooltip !== propertyConfig.Texts.tooltip) {
                newProperty = newProperty || { ...property };
                newProperty.tooltip = propertyConfig.Texts.tooltip;

                updatedTooltip.push(propertyId);
            }

            // items tooltip
            if (!isEqual(property.itemsTexts, propertyConfig.ItemsTexts)) {
                newProperty = newProperty || { ...property };
                newProperty.itemsTexts = cloneDeep(propertyConfig.ItemsTexts);

                updatedItemsTooltip.push(propertyId);
            }

            // save change
            if (newProperty != null) {
                updatedProperties[propertyId] = newProperty;
            }
        });

        return {
            updatedAllowedValues,
            updatedDisabledValues: [],
            updatedDisabled,
            updatedHidden,
            updatedMin,
            updatedMax,
            updatedTitleDisplayKey,
            updatedTooltipTitle,
            updatedTooltip,
            updatedItemsTooltip,
            updatedItemsTooltipTitle: [],
            updatedDisplayKey, updatedSize
        };
    }

    private updatePropertyValues(design: Design, properties: UIProperties, keepMissingProperties?: boolean) {
        const propertyConfigs: UIPropertyConfigExtended[] = [];

        for (const name in properties) {
            const property = (properties as any)[name];
            const propertyConfig: UIPropertyConfigExtended = {
                Property: property.property,
                Editable: property.editable,
                Value: property.value,
                DefaultValue: 0,
                MinValue: 0,
                MaxValue: 0,
                Visible: property.visible,
                Texts: {
                    displayKey: property.texts.displayKey,
                    displayName: property.texts.displayKey,
                    titleDisplayKey: property.texts.titleDisplayKey,
                    tooltip: property.texts.tooltip,
                    tooltipTitle: property.texts.tooltipTitle
                } as UIPropertyTexts,
                Size: property.size,
                ItemsTexts: {},
            } as UIPropertyConfigExtended;

            if (property['allowedValues']) {
                propertyConfig['AllowedValues'] = property['allowedValues'];
                propertyConfig['DisabledValues'] = property['disabledValues'];
                propertyConfig['AllowedValuesUniqueId'] = property['allowedValuesUniqueId'];
            }

            propertyConfigs.push(propertyConfig);
        }

        return this.updatePropertyValuesInternal(design, propertyConfigs, keepMissingProperties);
    }

    public updateFromProperties(design: Design, properties: UIProperties, keepMissingProperties?: boolean) {
        const modelChanges = this.updateModel(design, properties);
        const propertyChanges = this.updatePropertyValues(design, properties, keepMissingProperties);

        this.changesService.logUpdatesFromProperties(design.properties, modelChanges, propertyChanges);

        return {
            modelChanges,
            propertyChanges
        };
    }

    private triggerDesignEvent(design: Design, propertyChanges: IPropertyChanges) {
        if (propertyChanges.updatedAllowedValues != null && propertyChanges.updatedAllowedValues.length > 0) {
            design.trigger(DesignEvent.allowedValuesChanged, design, propertyChanges.updatedAllowedValues);
        }

        if (propertyChanges.updatedDisabledValues != null && propertyChanges.updatedDisabledValues.length > 0) {
            design.trigger(DesignEvent.disabledValuesChanged, design, propertyChanges.updatedDisabledValues);
        }

        if (Object.keys(propertyChanges.updatedHidden).length > 0) {
            design.trigger(DesignEvent.hiddenChanged, design);
        }

        if (Object.keys(propertyChanges.updatedDisabled).length > 0) {
            design.trigger(DesignEvent.disabledChanged, design);
        }

        if (Object.keys(propertyChanges.updatedTitleDisplayKey).length > 0) {
            design.trigger(DesignEvent.disabledChanged, design);
        }
    }

    public updateCalculationData(
        design: Design,
        calculationResult: CalculationResultResponse,
        calculationLanguage: string,
        messagesClosedDeferred?: Deferred<void>,
        keepMissingProperties?: boolean) {

        design.updateProjectDesign(calculationResult.design);
        design.updateReportData(calculationResult.designReportData);

        const codeLists = this.codeListService.createDesignCodeLists(calculationResult.designCodeList);
        design.updateDesignCodeList(codeLists);

        if (calculationResult.validationError == null) {
            design.validationError = undefined;

            const { modelChanges, propertyChanges } = this.updateFromProperties(design, calculationResult.properties as any, keepMissingProperties);

            design.calculationLanguage = calculationLanguage;

            if (design.confirmChangeInProgress === false) {
                design.confirmChangeInProgress = true;
                this.checkDialogs(design, calculationResult.dialogs, messagesClosedDeferred);
            }
            else if (messagesClosedDeferred != null) {
                messagesClosedDeferred.resolve();
            }

            this.triggerDesignEvent(design, propertyChanges);

            design.isReadOnlyDesign = false;

            return modelChanges;
        }
        else {
            design.validationError = ({
                Id: calculationResult.validationError.id,
                Message: MessageHelper.getValidationErrorMessage(calculationResult.validationError, this.localization)
            } as IValidationError);

            if (messagesClosedDeferred != null) {
                messagesClosedDeferred.resolve();
            }

            // load previous values
            design.reloadState();

            return null;
        }
    }

    private loadState(
        design: Design,
        index: number,
    ) {
        design.loadState(index);
    }

    public undo(design?: Design) {
        if (design == null || !design.canUndo) {
            return Promise.resolve();
        }

        design.resolveCalculation();
        this.loadState(design, design.states.findIndex((state) => state === design.currentState) - 1);

        return this.updateDesignAfterUndoRedo(design, false);
    }

    public redo(design?: Design) {
        if (design == null || !design.canRedo) {
            return Promise.resolve();
        }

        design.resolveCalculation();
        this.loadState(design, design.states.findIndex((state) => state === design.currentState) + 1);

        return this.updateDesignAfterUndoRedo(design, true);
    }

    private updateDesignAfterUndoRedo(design: Design, redoStep: boolean) {
        let promise: Promise<void>;

        if (!design.isTemplate) {
            promise = this.documentService.updateDesignWithNewContentCommon(design, null, false, false, DocumentAccessMode.Update);
        }
        else {
            // TODO: Handle undo/redo for templates later
            promise = Promise.resolve();
            return promise;
        }

        return promise.catch((err) => {
            if (err instanceof Error) {
                console.error(err);
            }

            // undo redo
            if (redoStep) {
                this.loadState(design, design.states.findIndex((state) => state === design.currentState) - 1);
            }
            else {
                this.loadState(design, design.states.findIndex((state) => state === design.currentState) + 1);
            }
        });
    }
    // calculation for report to api

    public async generateAndDownloadReport(design: Design, reportOptions: ReportOptionsEntity) {
        if (!design)
            return;

        design.updateProjectDesignOptions(reportOptions);
        this.trackOnDesignChange(design);

        const request = this.getCalculateDesignRequestData(design, design.guid.new(), true, false, false, reportOptions);

        return this.settlePromises(this.signalRservice.common.request('GenerateReport', request), this.generateReportDone())
            .then(async response => {
                if (response) {
                    const reportBlob = this.browserService.base64toBlob(response.base64Pdf, 'application/pdf');
                    const fileName = `${design.designData.projectDesign?.projectName}_${design.designData.projectDesign?.designName}`;
                    this.browserService.downloadBlob(reportBlob, `${fileName}.pdf`, true, true);
                }
            });
    }

        /**
     * Get custom images.
     * @param imageNames - name of images that need to be retrieved.
     * @returns
     */
        public async getCustomImages(imageNames: string[]): Promise<string[]> {
            const request: GetImageRequest = {
                imageNames
            };

            return (await this.signalRservice.common.request<GetImageRequest, GetImageResponse>('GetImages', request)
                .then((response: GetImageResponse) => {
                    return response.images;
                }));
        }

        /**
         * Uploade image for design when the report is ready for export.
         * @param designId - id of the design.
         * @param images - images that need to be uploaded.
         */
        public async uploadImages(designId: string, images: string[]): Promise<string[]> {
            const request: UploadImageRequest = {
                designId,
                images
            };

            return (await this.signalRservice.common.request<UploadImageRequest, GetImageResponse>('UploadImages', request)
                .then((response: GetImageResponse) => {
                    return response.images;
                }));
        }

    private createSignalRService() {
        const signalROptions = {
            signalRCoreInitSessionUrl: environment.signalRCoreInitSessionUrl,
            signalRCoreServerUrl: environment.signalRCoreServerUrl,
            signalRCoreServerHub: environment.signalRCoreServerHub,
            useHttpLongPolling: false,
            accessToken: environment.accessToken,
            signalRTimeoutInMilliseconds: environment.signalRTimeoutInMilliseconds
        } as SignalRConnectionOptions;

        const signalRServiceCtor = {
            userService: this.user,
            apiService: this.apiService,
            authenticationService: this.authenticationService,
            guid: this.guid,
            localization: this.localization,
            logger: this.logger,
            modal: this.modalService,
            ngZone: this.ngZone,
            options: signalROptions
        } as ISignalRServiceConstructor;
        return new SignalRService(
            signalRServiceCtor
        );
    }
}
