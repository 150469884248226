import { Injectable } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { Button, ButtonGroup, ButtonGroupDisplay, ButtonGroupType, ButtonSize, ButtonType, CheckBox, CheckboxGroup, CustomControl, DropDown, Group, ImageNameSelectionGroup, Label, Menu as MenuBase, NavigationTabWidth, PointsTable, RadioButtonGroup, Region as RegionBase, Rotate, Tab as TabBase, TabGroup, TextBox, ToggleButton, ToggleButtonGroup, ToggleImageButton, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IPlateSizeProps } from '../components/main-menu/Controls/PlateSize/PlateSize';
import { initializePlateSizeControl } from '../components/main-menu/Controls/PlateSize/plate-size';
import { Command, MainMenuCommand, commandFromService } from '../entities/command';
import { Design } from '../entities/design';
import { PropertyMetaData } from '../entities/properties';
import { getSpriteAsIconStyle } from '../sprites';
import { LocalizationService } from './localization.service';
import { UnitService } from './unit.service';
import { Constants } from '../entities/constants';
import { DesignHelper } from '../helpers/design-helper';
import { getPostInstallApprovalKey} from '../helpers/approval-helper';
import { DesignStandardHelper } from '../helpers/design-standard-helper';

export type Control =
    TextBox<MainMenuCommand> |
    DropDown<MainMenuCommand> |
    CheckBox<MainMenuCommand> |
    ToggleButtonGroup<MainMenuCommand> |
    ToggleButton<MainMenuCommand> |
    Button<MainMenuCommand> |
    ButtonGroup<MainMenuCommand> |
    Group<MainMenuCommand> |
    RadioButtonGroup<MainMenuCommand> |
    ToggleImageButton<MainMenuCommand> |
    Rotate<MainMenuCommand> |
    ImageNameSelectionGroup<MainMenuCommand> |
    PointsTable<MainMenuCommand> |
    TabGroup<MainMenuCommand> |
    Label<MainMenuCommand> |
    CheckboxGroup<MainMenuCommand>;

export type Menu = MenuBase<Control, MainMenuCommand>;
export type Tab = TabBase<Control, MainMenuCommand>;
export type Region = RegionBase<Control, MainMenuCommand>;

@Injectable({
    providedIn: 'root'
})
export class StaticMenuService {

    private design?: Design;

    constructor(
        private localizationService: LocalizationService,
        private unitService: UnitService
    ) { }

    public initializeMenu(design: Design): Menu {
        this.design = design;

        const menu = this.createMenu();
        this.fillControlProperties(menu);

        return menu;
    }

    private fillControlProperties(menu: Menu): void {
        const allControls = menu.Tabs.flatMap(tab => tab.TabRegions).flatMap(region => region.Controls);
        allControls.forEach(control => {
            control.Size = control.Size ?? 100;
            control.TooltipType = control.TooltipType ?? TooltipType.Normal;
        });
    }

    private createMenu(): Menu {
        return {
            Id: 1,
            Favorites: true,
            Tabs: [
                this.createMenu_Application(),
                this.createMenu_Plate(),
                this.createMenu_Concrete(),
                this.createMenu_Reinforcement(),
                this.createMenu_Product(),
                this.createMenu_Loads(),
                this.createMenu_FireStop(),
            ],
            Footer: {
                Controls: []
            }
        };
    }

    private createMenu_Application(): Tab {
        return {
            DisplayKey: 'Agito.Hilti.CW.TabApplication',
            Id: 10,
            Name: 'cw-application-tab',
            Image: 'tab-application',
            IconImage: getSpriteAsIconStyle('sprite-tab-application'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.createMenu_Application_ApplicationTypes(),
                this.createMenu_Application_ExpertMode(),
            ]
        };
    }

    private createMenu_Application_ApplicationTypes(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication',
            Id: 11,
            Name: 'cw-app-type-section',
            Controls: [
                {
                    ControlType: 'ImageNameRadioGroup',
                    Name: 'cw-application-types',
                    CodelistName: 'ApplicationTypes',
                    UIPropertyId: PropertyMetaData.Option_CW_ApplicationType.id,
                    DisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication.ApplicationTypes',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication.ApplicationTypes'
                },
                {
                    ControlType: 'ImageNameCheckboxGroup',
                    Name: 'cw-fastening-technologies',
                    CodelistName: 'FasteningTechnologies',
                    UIPropertyId: PropertyMetaData.Application_CW_FasteningTechnology.id,
                    DisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication.FasteningTechnologies',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication.FasteningTechnologies',
                    TooltipType: TooltipType.Popup,
                    Command: commandFromService(Command.OpenFasteningTechnology),
                }
            ]
        };
    }

    private createMenu_Application_ExpertMode(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication.ExpertMode',
            Id: 12,
            Name: 'cw-app-type-expert-mode-section',
            Controls: [
                {
                    ControlType: 'ToggleButton',
                    Image: 'expert-mode',
                    Name: 'cw-application-expert-mode-enabled',
                    UIPropertyId: PropertyMetaData.Application_CW_ExpertMode_Enabled.id,
                    DisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication.ExpertMode.Enabled',
                    TooltipTitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication.ExpertMode.Tooltip',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-cw-application-expert-mode-kink',
                    UIPropertyId: PropertyMetaData.Application_CW_ExpertMode_Kink.id,
                    DisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication.ExpertMode.Kink',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabApplication.ExpertMode.Kink',
                    Size: 50
                }
            ]
        };
    }

    private createMenu_Plate(): Tab {
        return {
            DisplayKey: 'Agito.Hilti.CW.TabPlate',
            Id: 30,
            Name: 'cw-plate-tab',
            Image: 'tab-base-plate',
            IconImage: getSpriteAsIconStyle('sprite-tab-base-plate'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.createMenu_Plate_PlateDesign(),
                this.createMenu_Plate_SlottedHoles(),
                this.createMenu_Plate_LoadEccentricity()
            ]
        };
    }

    private createMenu_Plate_LoadEccentricity(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabPlate.LoadEccentricity',
            KBTooltipDisplayKey: 'Agito.Hilti.Profis3.ControlTooltip.Popup',
            Id: 32,
            Name: 'cw-plate-design-section-load-eccentricity',
            Controls: [
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.Length,
                    Name: 'cw-plate-load-eccentricity-x',
                    UIPropertyId: PropertyMetaData.Loads_CW_EccentricityX.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Plate.LoadEccentricity.X',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.Length,
                    Name: 'cw-plate-load-eccentricity-y',
                    UIPropertyId: PropertyMetaData.Loads_CW_EccentricityY.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Plate.LoadEccentricity.Y',
                    Size: 50
                },
            ]
        };
    }

    private createMenu_Plate_PlateDesign(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabPlate.PlateDesign',
            Id: 31,
            Name: 'cw-plate-design-section',
            Controls: [
                {
                    ControlType: 'RadioButtonGroup',
                    Name: 'cw-baseplate-number-of-plates',
                    UIPropertyId: PropertyMetaData.Plate_CW_NumberOfPlates.id,
                    CodelistName: 'NumberOfPlates',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabBasePlate.NumberOfPlates',
                    TooltipTitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabBasePlate.NumberOfPlates.DisabledForMultiLoad'
                },
                {
                    ControlType: 'ToggleButtonGroup',
                    Name: 'cw-baseplate-shape',
                    CodelistName: 'PlateShapes',
                    ButtonGroupUnselectable: true,
                    ButtonGroupDisplay: ButtonGroupDisplay.Separated,
                    ButtonGroupType: ButtonGroupType.Single,
                    UIPropertyId: PropertyMetaData.Plate_CW_Shape.id,
                    HideChildDisplayText: true,
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabBasePlate.PlateShapeRegion.PlateShape',
                },
                {
                    ControlType: 'CustomControl',
                    Name: 'cw-baseplate-size',
                    UIPropertyId: PropertyMetaData.Plate_CW_Size.id,
                    Size: 100,
                    initialize: (
                        controlProps: IPlateSizeProps,
                        modals: Record<number, (input?: object) => IModalOpened>,
                        setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu
                    ) => initializePlateSizeControl(controlProps, this.design, PropertyMetaData.Plate_CW_Size.id, modals, this.localizationService, this.unitService, setState)
                } as unknown as CustomControl<IPlateSizeProps, MainMenuCommand>,
                {
                    ControlType: 'PopupGrid',
                    Name: 'cw-plate-standoff-type',
                    UIPropertyId: PropertyMetaData.Plate_CW_Standoff_Type.id,
                    CodelistName: 'StandoffTypes',
                    TitleDisplayKey: 'Agito.Hilti.CW.Plate.Standoff.Type',
                    DisplayKey: 'Agito.Hilti.CW.Plate.Standoff.Type.Popup',
                    TooltipType: TooltipType.Popup,
                    Command: commandFromService(Command.OpenPlateStandoffPopup)
                },
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.Length,
                    Name: 'cw-plate-standoff-distance',
                    UIPropertyId: PropertyMetaData.Plate_CW_Standoff_Distance.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Plate.Standoff.Distance',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.None,
                    Name: 'cw-plate-standoff-restraint-level',
                    UIPropertyId: PropertyMetaData.Plate_CW_Standoff_RestraintLevel.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Plate.Standoff.RestraintLevel',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.Length,
                    Name: 'cw-plate-standoff-nut-and-washer-height',
                    UIPropertyId: PropertyMetaData.Plate_CW_NutAndWasherHeight.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Plate.NutAndWasherHeight',
                    Size: 50
                },
                {
                    ControlType: 'Checkbox',
                    Name: 'cw-plate-standoff-mortar-strength',
                    UIPropertyId: PropertyMetaData.Plate_CW_Standoff_MortarStrength.id,
                    DisplayKey: 'Agito.Hilti.CW.Plate.Standoff.MortarStrength',
                    TooltipTitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabBasePlate.RegionStandOff.MortarStrength.Tooltip.Title',
                    TooltipDisplayKey: 'Agito.Hilti.CW.Navigation.TabBasePlate.RegionStandOff.MortarStrength.Tooltip'
                },
                {
                    CodelistName: 'GroutTypes',
                    ControlType: 'Dropdown',
                    TitleDisplayKey: 'Agito.Hilti.CW.Plate.GroutTypes',
                    Name: 'cw-plate-grout-type',
                    UIPropertyId: PropertyMetaData.Plate_CW_Grout.id,
                },
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.Stress,
                    Name: 'cw-plate-standoff-custom-grout-compressive-strength',
                    UIPropertyId: PropertyMetaData.Plate_CW_CustomGroutCompressiveStrength.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Plate.CustomGroutCompressiveStrength',
                    Size: 50
                },
            ]
        };
    }

    private createMenu_Concrete(): Tab {
        return {
            DisplayKey: 'Agito.Hilti.CW.TabConcrete',
            Id: 50,
            Name: 'cw-concrete-tab',
            Image: 'tab-base-material',
            IconImage: getSpriteAsIconStyle('sprite-tab-base-material'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.createMenu_Concrete_BaseMaterial(),
                this.createMenu_Concrete_Temperature(),
                this.createMenu_Concrete_Geometry(),
                this.createMenu_Concrete_InstallationConditions(),
                this.createMenu_Concrete_TorquingType()
            ]
        };
    }

    private createMenu_Concrete_BaseMaterial(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabConcrete.BaseMaterial',
            Id: 51,
            Name: 'cw-base-material-section',
            Controls: [
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.CW.Concrete.CrackedConcrete',
                    KBTooltipDisplayKey: 'Agito.Hilti.Profis3.ControlTooltip.Popup',
                    Name: 'cw-concrete-crackedconcrete',
                    UIPropertyId: PropertyMetaData.BaseMaterial_CW_CrackedConcrete.id
                },
                {
                    CodelistName: 'BaseMaterials',
                    ControlType: 'Dropdown',
                    DisplayKey: 'Agito.Hilti.CW.Concrete.BaseMaterial',
                    Name: 'cw-concrete-basematerial',
                    UIPropertyId: PropertyMetaData.BaseMaterial_CW_Material.id,
                },
                {
                    ControlType: 'TextBox',
                    DisplayKey: 'Agito.Hilti.CW.Concrete.FcCyl',
                    Name: 'cw-concrete-fccyl',
                    UIPropertyId: PropertyMetaData.BaseMaterial_CW_FcCyl.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Concrete.FcCyl',
                    UnitGroup: UnitGroup.Stress
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.CW.Concrete.LightweightConcrete',
                    Name: 'cw-concrete-lightweightconcrete',
                    UIPropertyId: PropertyMetaData.BaseMaterial_CW_LightweightConcrete.id
                },
                {
                    ControlType: 'TextBox',
                    DisplayKey: 'Agito.Hilti.CW.Concrete.Lambda',
                    Name: 'cw-concrete-lambda',
                    UIPropertyId: PropertyMetaData.BaseMaterial_CW_Lambda.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Concrete.Lambda',
                    Size: 50
                },
                {
                    CodelistName: 'ConcreteMixes',
                    ControlType: 'Dropdown',
                    DisplayKey: 'Agito.Hilti.CW.Concrete.ConcreteMix',
                    Name: 'cw-concrete-concretemix',
                    UIPropertyId: PropertyMetaData.BaseMaterial_CW_ConcreteMix.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Concrete.ConcreteMix',
                    Size: 50,
                    MultilineGroup: true
                },
                {
                    ControlType: 'TextBox',
                    DisplayKey: 'Agito.Hilti.CW.Concrete.Density',
                    Name: 'cw-concrete-density',
                    UIPropertyId: PropertyMetaData.BaseMaterial_CW_Density.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Concrete.Density',
                    Size: 50,
                    MultilineGroup: true,
                    UnitGroup: UnitGroup.Density
                },
            ]
        };
    }

    private createMenu_Concrete_Geometry(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabConcrete.Geometry',
            KBTooltipDisplayKey: 'Agito.Hilti.Profis3.ControlTooltip.Popup',
            Id: 52,
            Name: 'cw-geometry-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.ConcreteThickness',
                    Name: 'cw-geometry-concretethickness',
                    UIPropertyId: PropertyMetaData.Geometry_CW_Thickness.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Geometry.ConcreteThickness',
                    UnitGroup: UnitGroup.Length
                },
                {
                    ControlType: 'Group',
                    Name: 'cw-edge-distance-x-positive',
                    MultilineGroup: true,
                    Size: 50
                },
                {
                    ControlType: 'Group',
                    Name: 'cw-edge-distance-x-negative',
                    MultilineGroup: true,
                    Size: 50
                },
                {
                    ControlType: 'Group',
                    Name: 'cw-edge-distance-y-positive',
                    MultilineGroup: true,
                    Size: 50
                },
                {
                    ControlType: 'Group',
                    Name: 'cw-edge-distance-y-negative',
                    MultilineGroup: true,
                    Size: 50
                },
                {
                    ControlType: 'Group',
                    Name: 'cw-edge-distance-z-negative',
                    MultilineGroup: true,
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceX',
                    Name: 'cw-geometry-edgedistancex',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceX.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceX',
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'cw-edge-distance-x-positive'
                },
                {
                    ControlType: 'TextBox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceNegativeX',
                    Name: 'cw-geometry-edgedistancenegativex',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceNegativeX.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceNegativeX',
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'cw-edge-distance-x-negative'
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceXInfinity',
                    Name: 'cw-geometry-edgedistancexinfinity',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceXInfinity.id,
                    Size: 100,
                    Image: 'lock-small',
                    RotateRightUIPropertyId: this.retrieveEdgeLockProperty(PropertyMetaData.Geometry_CW_EdgeDistanceXLock.id),
                    ParentControlName: 'cw-edge-distance-x-positive'
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceNegativeXInfinity',
                    Name: 'cw-geometry-edgedistancenegativexinfinity',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceNegativeXInfinity.id,
                    Size: 100,
                    Image: 'lock-small',
                    RotateRightUIPropertyId: this.retrieveEdgeLockProperty(PropertyMetaData.Geometry_CW_EdgeDistanceNegativeXLock.id),
                    ParentControlName: 'cw-edge-distance-x-negative'
                },
                {
                    ControlType: 'TextBox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceNegativeZ',
                    Name: 'cw-geometry-edgedistancenegativez',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceNegativeZ.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceNegativeZ',
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'cw-edge-distance-z-negative'
                },
                {
                    ControlType: 'TextBox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceY',
                    Name: 'cw-geometry-edgedistancey',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceY.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceY',
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'cw-edge-distance-y-positive'
                },
                {
                    ControlType: 'TextBox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceNegativeY',
                    Name: 'cw-geometry-edgedistancenegativey',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceNegativeY.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceNegativeY',
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'cw-edge-distance-y-negative'
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceNegativeZInfinity',
                    Name: 'cw-geometry-edgedistancenegativezinfinity',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceNegativeZInfinity.id,
                    Image: 'lock-small',
                    ParentControlName: 'cw-edge-distance-z-negative'
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceYInfinity',
                    Name: 'cw-geometry-edgedistanceyinfinity',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceYInfinity.id,
                    Image: 'lock-small',
                    RotateRightUIPropertyId: this.retrieveEdgeLockProperty(PropertyMetaData.Geometry_CW_EdgeDistanceYLock.id),
                    ParentControlName: 'cw-edge-distance-y-positive'
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.CW.Geometry.EdgeDistanceNegativeYInfinity',
                    Name: 'cw-geometry-edgedistancenegativeyinfinity',
                    UIPropertyId: PropertyMetaData.Geometry_CW_EdgeDistanceNegativeYInfinity.id,
                    Image: 'lock-small',
                    RotateRightUIPropertyId: this.retrieveEdgeLockProperty(PropertyMetaData.Geometry_CW_EdgeDistanceNegativeYLock.id),
                    ParentControlName: 'cw-edge-distance-y-negative'
                }
            ]
        };
    }

    private createMenu_Concrete_Temperature(): Region {

        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabConcrete.Temperature',
            Id: 53,
            Name: 'cw-temperature-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'cw-temperature-shortterm',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.CW.Temperature.ShortTerm',
                    DisplayKey: 'Agito.Hilti.CW.Temperature.ShortTerm',
                    UIPropertyId: PropertyMetaData.Temperature_CW_ShortTerm.id,
                    UnitGroup: UnitGroup.Temperature,
                    TooltipDisplayKey: '',
                    TooltipTitleDisplayKey: ''
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-temperature-longterm',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.CW.Temperature.LongTerm',
                    DisplayKey: 'Agito.Hilti.CW.Temperature.LongTerm',
                    UIPropertyId: PropertyMetaData.Temperature_CW_LongTerm.id,
                    UnitGroup: UnitGroup.Temperature,
                    TooltipDisplayKey: '',
                    TooltipTitleDisplayKey: ''
                }
            ]
        };
    }

    private createMenu_Concrete_InstallationConditions(): Region {

        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabConcrete.InstallationConditions',
            Id: 54,
            Name: 'cw-installationconditions-section',
            Controls: [
                {
                    ControlType: 'Dropdown',
                    DisplayKey: 'Agito.Hilti.CW.InstallationConditions.DrillingMethod',
                    TitleDisplayKey: 'Agito.Hilti.CW.InstallationConditions.DrillingMethod',
                    Name: 'cw-installationconditions-drillingmethod-dropdown',
                    UIPropertyId: PropertyMetaData.InstallationConditions_CW_DrillingMethod.id,
                    CodelistName: 'DrillingMethods'
                },
                {
                    ControlType: 'Dropdown',
                    DisplayKey: 'Agito.Hilti.CW.InstallationConditions.HoleCondition',
                    TitleDisplayKey: 'Agito.Hilti.CW.InstallationConditions.HoleCondition',
                    TooltipDisplayKey: 'Agito.Hilti.CW.InstallationConditions.HoleCondition.Tooltip',
                    TooltipTitleDisplayKey: 'Agito.Hilti.CW.InstallationConditions.HoleCondition.Tooltip.Title',
                    Name: 'cw-installationconditions-holecondition-dropdown',
                    UIPropertyId: PropertyMetaData.InstallationConditions_CW_HoleCondition.id,
                    CodelistName: 'HoleConditions'
                },
                {
                    ControlType: 'Dropdown',
                    DisplayKey: 'Agito.Hilti.CW.InstallationConditions.HoleType',
                    TitleDisplayKey: 'Agito.Hilti.CW.InstallationConditions.HoleType',
                    Name: 'cw-installationconditions-holetype-dropdown',
                    UIPropertyId: PropertyMetaData.InstallationConditions_CW_HoleType.id,
                    CodelistName: 'HoleTypes'
                },
                {
                    ControlType: 'Dropdown',
                    DisplayKey: 'Agito.Hilti.CW.InstallationConditions.InstallationDirection',
                    TitleDisplayKey: 'Agito.Hilti.CW.InstallationConditions.InstallationDirection',
                    Name: 'cw-installationconditions-installationdirection-dropdown',
                    UIPropertyId: PropertyMetaData.InstallationConditions_CW_InstallationDirection.id,
                    CodelistName: 'InstallationDirections'
                }
            ]
        };
    }

    private createMenu_Concrete_TorquingType(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabConcrete.TorquingMethod',
            Id: 55,
            Name: 'cw-torquingtype-section',
            Controls: [
                {
                    ControlType: 'RadioButtonGroup',
                    Name: 'cw-torquing-method',
                    UIPropertyId: PropertyMetaData.Torquing_CW_TorquingType.id,
                    CodelistName: 'TorquingTypes',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabConcrete.TorquingMethod.Torquing',
                    TooltipDisplayKey: 'Agito.Hilti.CW.Navigation.TabConcrete.TorquingMethod.Torquing.Tooltip',
                    TooltipTitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabConcrete.TorquingMethod.Torquing.Tooltip.Title',
                    Command: commandFromService(Command.OpenTorquingTypePopup),
                    TooltipType: TooltipType.Popup
                }
            ]
        };
    }

    private createMenu_Reinforcement(): Tab {
        return {
            DisplayKey: 'Agito.Hilti.CW.TabReinforcement',
            Id: 70,
            Name: 'cw-reinforcement-tab',
            Image: 'tab-concrete-reinforcement',
            IconImage: getSpriteAsIconStyle('sprite-tab-concrete-reinforcement'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.createMenu_Reinforcement_SupplementaryReinforcement(),
                this.createMenu_Reinforcement_AnchorReinforcement()
            ]
        };
    }

    private createMenu_Reinforcement_SupplementaryReinforcement(): Region {

        let regionTitleKey = 'Agito.Hilti.CW.Navigation.TabReinforcement.SupplementaryReinforcement';
        let edgeReinforcementControl: Control = {
            ControlType: 'RadioButtonGroup',
            Name: 'cw-edge-reinforcement',
            UIPropertyId: PropertyMetaData.Reinforcement_CW_EdgeReinforcement.id,
            CodelistName: 'EdgeReinforcements',
            TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.EdgeReinforcements',
            TooltipDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.EdgeReinforcements.Tooltip',
            TooltipTitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.EdgeReinforcements.Tooltip.Title'
        };

        if (DesignStandardHelper.isEnBasedDesignStandard(this.design?.designStandard?.id)) {
            regionTitleKey = 'Agito.Hilti.CW.Navigation.TabReinforcement.ConcreteReinforcement';

            // For EN design standard, edge reinforcement is a dropdown
            edgeReinforcementControl = {
                ...edgeReinforcementControl,

                TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.EdgeReinforcements.ETAG',
                TooltipDisplayKey: undefined,
                TooltipTitleDisplayKey: undefined,
                ControlType: 'Dropdown',
                MultilineGroup: true,
                Size: 50
            } as Control;
        }

        return {
            DisplayKey: regionTitleKey,
            Id: 71,
            Name: 'cw-reinforcement-supplementary-section',
            Controls: [
                {
                    ControlType: 'Dropdown',
                    Name: 'cw-concrete-reinforcement',
                    UIPropertyId: PropertyMetaData.Reinforcement_CW_ConcreteReinforcement.id,
                    CodelistName: 'ConcreteReinforcements',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.Concrete',
                    MultilineGroup: true,
                    Size: 50
                },
                edgeReinforcementControl,
                {
                    ControlType: 'RadioButtonGroup',
                    Name: 'cw-reinforcement-shear-condition',
                    UIPropertyId: PropertyMetaData.Reinforcement_CW_ShearCondition.id,
                    CodelistName: 'ShearConditions',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.ShearConditions',
                    TooltipDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.ShearConditions.Tooltip',
                    TooltipTitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.ShearConditions.Tooltip.Title'
                },
                {
                    ControlType: 'RadioButtonGroup',
                    Name: 'cw-reinforcement-tension-condition',
                    UIPropertyId: PropertyMetaData.Reinforcement_CW_TensionCondition.id,
                    CodelistName: 'TensionConditions',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.TensionConditions',
                    TooltipDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.TensionConditions.Tooltip',
                    TooltipTitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.TensionConditions.Tooltip.Title'
                },
                {
                    ControlType: 'Checkbox',
                    Name: 'cw-reinforcement-has-splitting-reinforcement',
                    UIPropertyId: PropertyMetaData.Reinforcement_CW_HasSplittingReinforcement.id,
                    DisplayKey: 'Agito.Hilti.CW.Navigation.TabReinforcement.HasSplittingReinforcement'
                },
            ]
        };
    }

    private createMenu_Reinforcement_AnchorReinforcement(): Region {
        let regionTitleKey = 'Agito.Hilti.CW.Navigation.TabReinforcement.AnchorReinforcement';
        if (DesignStandardHelper.isEnBasedDesignStandard(this.design?.designStandard?.id))
            regionTitleKey = 'Agito.Hilti.CW.Navigation.TabReinforcement.SupplementaryReinforcement';

        return {
            DisplayKey: regionTitleKey,
            Id: 71,
            Name: 'cw-anchor-reinforcement-section',
            Controls: [
                {
                    ControlType: 'ImageNameCheckbox',
                    Name: 'cw-anchor-reinforcement',
                    UIPropertyId: PropertyMetaData.AnchorReinforcement_CW_Overall_IsEnabled.id,
                    IconImage: getSpriteAsIconStyle('sprite-anchor-reinforcement'),
                    DisplayKey: regionTitleKey,
                    Command: commandFromService(Command.OpenAnchorReinforcement)
                }
            ]
        };
    }

    private createMenu_Product(): Tab {
        return {
            DisplayKey: 'Agito.Hilti.CW.TabProduct',
            Id: 90,
            Name: 'cw-product-tab',
            Image: 'tab-product',
            IconImage: getSpriteAsIconStyle('sprite-tab-product'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.isPostInstallAnchorProduct() ? this.createMenu_Product_PostInstalledAnchor() : this.createMenu_Product_AnchorChannel(),
                this.createMenu_Product_Bolt(),
                this.isPostInstallAnchorProduct() ? this.createMenu_Product_PostInstalledAnchor_Layout() : this.createMenu_Product_Bolt_Layout(),
            ]
        };
    }

    private createMenu_Product_AnchorChannel(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel',
            Id: 91,
            Name: 'cw-anchor-channel-section',
            Controls: [
                {
                    ControlType: 'PopupGrid',
                    Name: 'cw-anchor-channel-family',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_Family.id,
                    CodelistName: 'AnchorChannelFamilyGroup',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.Family',
                },
                {
                    ControlType: 'Dropdown',
                    Name: 'cw-anchor-channel-type',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_Type.id,
                    CodelistName: 'AnchorChannelFamily',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.Type'
                },
                {
                    CodelistName: 'AnchorChannelLength',
                    ControlType: 'Dropdown',
                    Name: 'cw-anchor-channel-length',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.Length',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_Length.id,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-anchor-channel-user-defined-channel-length',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.Length.UserDefined',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_UserDefinedLength.id,
                    UnitGroup: UnitGroup.Length,
                    StepValue: 5
                },
                {
                    CodelistName: 'AnchorChannelEmbedmentDepth',
                    ControlType: 'Dropdown',
                    Name: 'cw-anchor-channel-embedment-depth',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_EmbedmentDepth.id
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-anchor-channel-custom-rebar-length',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.CustomRebarLength',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_CustomRebarLength.id,
                    UnitGroup: UnitGroup.Length
                },
                {
                    ControlType: 'Button',
                    ButtonSize: ButtonSize.Full,
                    ButtonType: ButtonType.Default,
                    Name: 'cw-approvals',
                    DisplayKey: 'Agito.Hilti.CW.ViewApproval',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_Approvals.id,
                    Command: commandFromService(Command.OpenApproval)
                },
                {
                    ControlType: 'Label',
                    Name: 'cw-anchor-channel-channel-item',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.ItemNumber',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_ItemNumber.id
                },
                {
                    ControlType: 'Group',
                    Name: 'cw-anchor-channel-group',
                    CollapsableGroup: true,
                    MultilineGroup: true
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-anchor-channel-num-of-anchors',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.NumberOfAnchors',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_NumberOfAnchors.id,
                    ParentControlName: 'cw-anchor-channel-group'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-anchor-channel-projection',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.Projection',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_Projection.id,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'cw-anchor-channel-group'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-anchor-channel-spacing',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.Spacing',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_Spacing.id,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'cw-anchor-channel-group'
                },
                {
                    CodelistName: 'RebarPlate',
                    ControlType: 'Dropdown',
                    Name: 'cw-anchor-channel-rebar-plate',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.RebarPlate',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_RebarPlate.id,
                    ParentControlName: 'cw-anchor-channel-group'
                },
                {
                    CodelistName: 'LipStrengthClips',
                    ControlType: 'Dropdown',
                    Name: 'cw-anchor-channel-lip-strength-clip',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.LipStrengthClip',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_LipStrengthClip.id,
                    ParentControlName: 'cw-anchor-channel-group'
                }
            ]
        };
    }

    private createMenu_Product_PostInstalledAnchor(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel',
            Id: 91,
            Name: 'cw-post-installed-anchor-section',
            Controls: [
                {
                    ControlType: 'Checkbox',
                    Name: 'cw-post-installed-anchor-showfullinternalportfolio-enabled',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_ShowFullInternalPortfolio.id,
                    DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ShowFullInternalPortfolio'
                },
                {
                    ControlType: 'PopupGrid',
                    Name: 'cw-post-installed-anchor-family',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_Family.id,
                    CodelistName: 'AnchorChannelFamilyGroup',
                    TitleDisplayKey: 'Agito.Hilti.CW.AnchorChannel.Family',
                },
                {
                    CodelistName: 'AnchorTypes',
                    ControlType: 'Dropdown',
                    Name: 'cw-post-installed-anchor-type',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.InfoSection.AnchorSection.Type',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_Type.id
                },
                {
                    CodelistName: 'AnchorSizes',
                    ControlType: 'Dropdown',
                    Name: 'cw-post-installed-anchor-size',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.InfoSection.AnchorSection.Size',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_Size.id,
                },
                {
                    ControlType: 'Button',
                    ButtonSize: ButtonSize.Full,
                    ButtonType: ButtonType.Default,
                    Name: 'cw-approvals',
                    DisplayKey: getPostInstallApprovalKey(this.design),
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_Approvals.id,
                    Command: commandFromService(Command.OpenApproval)
                },
                {
                    ControlType: 'Label',
                    Name: 'cw-post-installed-anchor-item',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.AnchorRodItem',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_ItemNumber.id
                },
                {
                    ControlType: 'Label',
                    Name: 'cw-post-installed-adhesive-number',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.AdhesiveNumber',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_AdhesiveNumber.id
                },
                {
                    ControlType: 'Label',
                    Name: 'cw-post-installed-capsule-number',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.CapsuleNumber',
                    UIPropertyId: PropertyMetaData.AnchorChannel_CW_CapsuleNumber.id
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-post-installed-anchor-cip-head-thickness',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.HeadThickness',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_CipHeadThickness.id,
                    UnitGroup: UnitGroup.Length,
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-post-installed-anchor-cip-head-diameter',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.HeadDiameter',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_CipHeadDiameter.id,
                    UnitGroup: UnitGroup.Length,
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-post-installed-anchor-cip-head-area',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.CipAh.Head',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_CipHeadArea.id,
                    UnitGroup: UnitGroup.Length,
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-post-installed-anchor-cip-min-spacing',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.CipMinSpacing',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_CipMinSpacing.id,
                    UnitGroup: UnitGroup.Length,
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-post-installed-anchor-cip-min-edge-distance',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.CipMinimumEdgeDistance',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_CipMinEdgeDistance.id,
                    UnitGroup: UnitGroup.Length,
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-post-installed-anchor-cip-k8',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.CipK8',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_CipK8.id,
                    UnitGroup: UnitGroup.Length,
                    Size: 50
                },
                {
                    ControlType: 'Label',
                    Name: 'cw-post-installed-anchor-embedment',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.InfoSection.AnchorSection.EmbedmentDepth'
                },
                {
                    ControlType: 'ToggleButtonGroup',
                    ButtonGroupDisplay: ButtonGroupDisplay.Join,
                    ButtonGroupType: ButtonGroupType.Single,
                    ButtonGroupUnselectable: true,
                    Name: 'cw-post-installed-anchor-embedment-type',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthOptimizationType.id,
                    CodelistName: 'EmbedmentOptimizationTypes',
                    TooltipType: TooltipType.Normal,
                    HideChildDisplayText: true,
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-post-installed-anchor-embedment-depth-value-input',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthVariable.id,
                    UnitGroup: UnitGroup.Length,
                    Size: 50
                },
                {
                    CodelistName: 'AnchorEmbedmentDepths',
                    ControlType: 'Dropdown',
                    Name: 'cw-post-installed-anchor-embedment-depth-value-dropdown',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthFixedMultiple.id,
                    UnitGroup: UnitGroup.Length,
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-post-installed-anchor-nominal-embedment-depth',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ControlHNom.Title',
                    UIPropertyId: PropertyMetaData.PostInstalledAnchor_CW_NominalEmbedment.id,
                    UnitGroup: UnitGroup.Length,
                    Size: 100
                }
            ]
        };
    }

    private createMenu_Product_Bolt(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.Bolt',
            Id: 92,
            Name: 'cw-bolt-section',
            Controls: [
                {
                    ControlType: 'PopupGrid',
                    Name: 'cw-bolt-type',
                    UIPropertyId: PropertyMetaData.Bolt_CW_Type.id,
                    CodelistName: 'BoltFamilies',
                    TitleDisplayKey: 'Agito.Hilti.CW.Bolt.Family',
                },
                {
                    ControlType: 'Dropdown',
                    Name: 'cw-bolt-size',
                    UIPropertyId: PropertyMetaData.Bolt_CW_Size.id,
                    CodelistName: 'BoltSizes',
                    TitleDisplayKey: 'Agito.Hilti.CW.Bolt.Size'
                },
                {
                    ControlType: 'Dropdown',
                    Name: 'cw-bolt-length',
                    UIPropertyId: PropertyMetaData.Bolt_CW_Length.id,
                    CodelistName: 'BoltLengths',
                    TitleDisplayKey: 'Agito.Hilti.CW.Bolt.Length'
                },
                {
                    ControlType: 'Label',
                    Name: 'cw-bolt-bolt-item',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.Bolt.ItemNumber',
                    UIPropertyId: PropertyMetaData.Bolt_CW_ItemNumber.id
                }
            ]
        };
    }

    private createMenu_Product_Bolt_Layout(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.BoltLayout',
            Id: 93,
            Name: 'cw-bolt-layout-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'cw-bolt-layout-number-of-bolts',
                    UIPropertyId: PropertyMetaData.Bolt_CW_NumberOfBolts.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Bolt.NumberOfBolts'
                },
                {
                    ControlType: 'Dropdown',
                    Name: 'cw-inspection-type',
                    CodelistName: 'InspectionTypes',
                    TooltipType: TooltipType.Popup,
                    Command: commandFromService(Command.OpenInspectionTypePopup),
                    UIPropertyId: PropertyMetaData.Application_CW_InspectionType.id,
                    DisplayKey: 'Agito.Hilti.CW.Bolt.InspectionType',
                    TitleDisplayKey: 'Agito.Hilti.CW.Bolt.InspectionType'
                }
            ]
        };
    }

    private createMenu_Product_PostInstalledAnchor_Layout(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabProduct.AnchorLayout',
            Id: 93,
            Name: 'cw-post-installed-anchor-layout-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'cw-post-installed-anchor-layout-number-of-anchors',
                    UIPropertyId: PropertyMetaData.Bolt_CW_NumberOfBolts.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Bolt.NumberOfAnchors'
                }
            ]
        };
    }

    private createMenu_Loads(): Tab {
        return {
            DisplayKey: 'Agito.Hilti.CW.TabLoads',
            Id: 110,
            Name: 'cw-loads-tab',
            Image: 'tab-loads',
            IconImage: getSpriteAsIconStyle('sprite-tab-loads'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.createMenu_Loads_LoadType(),
                this.createMenu_Loads_Design(),
                this.createMenu_Loads_Tolerance(),
            ]
        };
    }

    private createMenu_Loads_LoadType(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.LoadType',
            Id: 111,
            Name: 'cw-load-type-section',
            Controls: [
                {
                    ControlType: 'ToggleButtonGroup',
                    ButtonGroupDisplay: ButtonGroupDisplay.Join,
                    ButtonGroupType: ButtonGroupType.Single,
                    ButtonGroupUnselectable: true,
                    Name: 'cw-loads-loadtype',
                    UIPropertyId: PropertyMetaData.Load_CW_LoadType.id,
                    CodelistName: 'LoadTypes',
                    TooltipType: TooltipType.Normal,
                    HideChildDisplayText: true
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'LongitudinalShearCalculation',
                    Name: 'cw-loads-longitudinal-shear-calculation',
                    UIPropertyId: PropertyMetaData.Loads_CW_LongitudinalShearCalculation.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.LongitudinalShearCalculation.Title'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-loads-nonductile',
                    Size: 50,
                    UIPropertyId: PropertyMetaData.Load_CW_PhiNonductile.id,
                    TooltipType: TooltipType.Normal,
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.RegionSeismicDesign.ControlPhiNonductile'
                },
                {
                    ControlType: 'PopupGrid',
                    Name: 'cw-loads-seismictensiontype',
                    DisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.SeismicTensionType.GridTitle',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.SeismicTensionType.Title',
                    Size: 100,
                    UIPropertyId: PropertyMetaData.Load_CW_SeismicTensionType.id,
                    CodelistName: 'SeismicTensionTypes'
                },
                {
                    ControlType: 'PopupGrid',
                    Name: 'cw-loads-seismicsheartype',
                    DisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.SeismicShearType.GridTitle',
                    TitleDisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.SeismicShearType.Title',
                    Size: 100,
                    UIPropertyId: PropertyMetaData.Load_CW_SeismicShearType.id,
                    CodelistName: 'SeismicShearTypes',
                }
            ]
        };
    }

    private createMenu_Loads_Design(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.Design',
            Id: 112,
            Name: 'cw-load-design-section',
            Controls: [
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'DesignMethodGroups',
                    Name: 'cw-design-method-groups',
                    UIPropertyId: PropertyMetaData.Option_CW_DesignMethodGroup.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.ApplicationSettings.DesignMethodSelection'
                }
            ]
        };
    }

    private createMenu_Plate_SlottedHoles(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.CalculationOptions',
            Id: 114,
            Name: 'cw-load-slotted-holes-section',
            Controls: [
                {
                    ControlType: 'Checkbox',
                    Name: 'cw-loads-slotted-holes-enabled',
                    UIPropertyId: PropertyMetaData.Plate_CW_SlottedHole_Enabled.id,
                    DisplayKey: 'Agito.Hilti.CW.Loads.SlottedHoles.Enabled'
                },
                {
                    ControlType: 'Checkbox',
                    Name: 'cw-loads-slotted-holes-bolt-bending',
                    UIPropertyId: PropertyMetaData.Plate_CW_SlottedHole_BoltBending.id,
                    DisplayKey: 'Agito.Hilti.CW.Loads.SlottedHoles.BoltBending'
                },
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.Length,
                    Name: 'cw-loads-slotted-holes-washer-height',
                    UIPropertyId: PropertyMetaData.Plate_CW_SlottedHole_WasherHeight.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Loads.SlottedHoles.WasherHeight',
                }
            ]
        };
    }

    private createMenu_Loads_Tolerance(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.Navigation.TabLoads.Tolerance',
            Id: 113,
            Name: 'cw-load-tolerance-section',
            Controls: [
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'LoadTolerances',
                    Name: 'cw-loads-tolerance-type',
                    UIPropertyId: PropertyMetaData.Loads_CW_Tolerance_Type.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Loads.ToleranceX.Type'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-loads-tolerance-customize',
                    UIPropertyId: PropertyMetaData.Loads_CW_Tolerance_Custom.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Loads.Tolerance.Customize',
                    UnitGroup: UnitGroup.Length,
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'LoadTolerancesY',
                    Name: 'cw-loads-tolerance-y-type',
                    UIPropertyId: PropertyMetaData.Loads_CW_ToleranceY_Type.id,
                    TitleDisplayKey: DesignHelper.isTopView(this.design?.applicationType)
                        ? 'Agito.Hilti.CW.Loads.ToleranceY.ToS.Type'
                        : 'Agito.Hilti.CW.Loads.ToleranceY.FoS.Type'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-loads-tolerance-y-customize',
                    UIPropertyId: PropertyMetaData.Loads_CW_ToleranceY_Custom.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Loads.Tolerance.Customize',
                    UnitGroup: UnitGroup.Length,
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'LoadTolerancesZ',
                    Name: 'cw-loads-tolerance-z-type',
                    UIPropertyId: PropertyMetaData.Loads_CW_ToleranceZ_Type.id,
                    TitleDisplayKey: DesignHelper.isTopView(this.design?.applicationType)
                        ? 'Agito.Hilti.CW.Loads.ToleranceZ.ToS.Type'
                        : 'Agito.Hilti.CW.Loads.ToleranceZ.FoS.Type'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'cw-loads-tolerance-z-customize',
                    UIPropertyId: PropertyMetaData.Loads_CW_ToleranceZ_Custom.id,
                    TitleDisplayKey: 'Agito.Hilti.CW.Loads.Tolerance.Customize',
                    UnitGroup: UnitGroup.Length,
                }
            ]
        };
    }

    private createMenu_FireStop(): Tab {

        const tabRegions = this.isFirestopRegionAllowed() && this.isFirestopDesignMethodAllowed()
            ? [this.createMenu_DummyFireRegion()]
            : [];

        return {
            DisplayKey: 'Agito.Hilti.CW.TabFirestop',
            Id: 130,
            Name: Constants.FirestopTabName,
            Image: 'tab-firestop',
            IconImage: getSpriteAsIconStyle('sprite-tab-firestop'),
            BackgroundColor: '#c90015',
            TextColor: '#FFFFFF',
            Width: NavigationTabWidth.Normal,
            TabRegions: tabRegions
        };
    }

    private isFirestopRegionAllowed(): boolean {
        return Constants.FirestopRegionAllowed.includes(this.design?.regionId ?? 0);
    }

    private isFirestopDesignMethodAllowed(): boolean {
        return Constants.FirestopDesignMethodAllowed.includes(this.design?.designMethodGroup?.id ?? 0);
    }

    private createMenu_DummyFireRegion(): Region {
        return {
            DisplayKey: 'Agito.Hilti.CW.TabFirestop',
            Id: 131,
            Name: 'cw-firestop-section',
            Controls: [
                {
                    ControlType: 'Label',
                    Name: 'cw-firestop-item',
                    TitleDisplayKey: 'Agito.Hilti.CW.TabFirestop'
                },
            ]
        };
    }

    private retrieveEdgeLockProperty(propertyId: number): number | undefined {
        return Constants.IsEdgeDistanceLockingEnabled ? propertyId : undefined;
    }

    public isPostInstallAnchorProduct() {
        return (this.design?.projectDesign?.product?.anchorChannel?.familyGroupId ?? 0) < 0;
    }
}
