<div class="resizer-row"
    *ngIf="isDataOk && dataLoaded"
    [class.loads-row]="!isAdd"
    [class.loads-row-first]="!isAdd && loadIndex == 0"
    [class.new-load]="isAdd"
    [class.dynamic]="load.hasSustainedLoads">

    <!-- Active -->
    <div class="column-selected" *ngIf="helper.isSelectingCombinationEnabled">
        <pe-radio-button
            *ngIf="!isAdd"
            id="LC-table-select-combination-radio-button-{{loadIndex != null ? loadIndex : 'new-load'}}"
            class="selected-container selected-radio-button"
            [tooltip]="translate('Agito.Hilti.CW.Loads.LoadSelect.TooltipText')"
            [disableTooltip]="disabled"
            [items]="selectedLoadItems"
            [selectedValue]="selectedLoadId"
            (selectedValueChange)="selectLoad()"
            [disabled]="disabled || submitted">
        </pe-radio-button>
    </div>

    <!-- Rank -->
    <div class="column-rank">
        <div class="name-container" [ngbTooltip]="load.name">
            <div class="text" [class.bold]="isDecisive">{{ loadIndex != null ? loadIndex + 1 : 0 }}</div>
        </div>
    </div>

    <!-- Name -->
    <div class="column-name resizer-column bordered-column" [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.name) }">
        <div id="LC-table-combination-name-textbox-{{loadIndex != null ? loadIndex : 'new-load'}}"
            class="name-container">
            <pe-text-box
                *ngIf="!load.isWizardGenerated"
                id="{{loadIndex != null ? 'LC-table-combination-name-textbox-' + loadIndex : 'LC-table-add-new-combination-name-textbox'}}"
                class="input name-input clip"
                [borderTop]="'None'"
                [borderBottom]="'None'"
                [borderLeft]="'None'"
                [borderRight]="'None'"
                [marginBottom]="'None'"
                [display]="textBoxDisplay"
                [height]="nameInputHeight"
                [value]="load.name"
                (inputBlur)="loadNameChanged($any($event).detail)"
                [updateOnBlur]="true"
                [disabled]="helper.isNameDisabled">
            </pe-text-box>
            <div class="text clip" [class.bold]="isDecisive" *ngIf="load.isWizardGenerated" [ngbTooltip]="loadNameTooltip(load)">{{load.name}}</div>
        </div>
        <div class="name-container bottom" *ngIf="load.description != null && load.hasSustainedLoads">
            <div class="text clip disabled-text">{{load.description}}</div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Load type -->
    <!-- Size of this column cannot be edited by the user, so the element after it has to include the left-resizer. -->
    <div class="column-type" [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }">
        <pe-toggle-button-group
            class="type-buttons"
            [model]="load.loadType"
            (modelChange)="loadLoadTypeChange($any($event).detail)"
            [items]="loadTypeToggleItems"
            [disabled]="submitted || !helper.isLoadTypeSelectingOnRowEnabled || load.isWizardGenerated">
        </pe-toggle-button-group>
    </div>

    <!-- Loads legend -->
    <div class="column-loads" *ngIf="load.hasSustainedLoads">
        <div class="name-container" [ngbTooltip]="helper.firstRowLoadLegendTooltip">
            <div class="text clip bordered-text">{{helper.firstRowLoadLegendTranslation}}</div>
        </div>

        <div class="dynamic-container"
            [ngbTooltip]="helper.secondRowLoadLegendTooltip">
            <div class="text clip bordered-text">{{helper.secondRowLoadLegendTranslation}}</div>
        </div>
    </div>

    <!-- Forces -->
    <div *ngIf="helper.columnVisibilities.forceVx"
        class="column-force column-vx resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceVx) }">
        <div class="column-force">
            <div class="general-number">
                <div class="number-container">
                    <pe-numeric-text-box
                        id="{{loadIndex != null ? 'LC-table-Vx-force-textbox-' + loadIndex : 'LC-table-add-new-combination-Vx-textbox'}}"
                        class="input vx-input number-input clip"
                        [borderTop]="'None'"
                        [borderBottom]="'None'"
                        [borderLeft]="'None'"
                        [borderRight]="'None'"
                        [marginBottom]="'None'"
                        [display]="textBoxDisplay"
                        [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.LoadCombination_CW_ForceX.id, load.forceX)"
                        (valueChange)="loadValueChange('forceX', $any($event).detail)"
                        [showStepper]="false"
                        [unit]="helper.forceUnit"
                        [appendUnit]="false"
                        [updateOnBlur]="true"
                        [required]="true"
                        [minValue]="helper.forceXRange?.min"
                        [maxValue]="helper.forceXRange?.max"
                        [disabled]="isLoadPropertyDisabled(propertyMetaData.LoadCombination_CW_ForceX.id)">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>

        <div class="dynamic-number">
            <div class="number-container"></div>
        </div>

        <div class="resizer-left" (mousedown)="beginColumnResizing($event)"></div>
        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.forceVy"
        class="column-force column-vy resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceVy) }">
        <div class="column-force">
            <div class="general-number">
                <div class="number-container">
                    <pe-numeric-text-box
                        id="{{loadIndex != null ? 'LC-table-Vy-force-textbox-' + loadIndex : 'LC-table-add-new-combination-Vy-textbox'}}"
                        class="input vy-input number-input clip"
                        [borderTop]="'None'"
                        [borderBottom]="'None'"
                        [borderLeft]="'None'"
                        [borderRight]="'None'"
                        [marginBottom]="'None'"
                        [display]="textBoxDisplay"
                        [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.LoadCombination_CW_ForceY.id, load.forceY)"
                        (valueChange)="loadValueChange('forceY', $any($event).detail)"
                        [showStepper]="false"
                        [unit]="helper.forceUnit"
                        [appendUnit]="false"
                        [updateOnBlur]="true"
                        [required]="true"
                        [minValue]="helper.forceYRange?.min"
                        [maxValue]="helper.forceYRange?.max"
                        [disabled]="isLoadPropertyDisabled(propertyMetaData.LoadCombination_CW_ForceY.id)">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>

        <div class="dynamic-number">
            <div class="number-container"></div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.forceN"
        class="column-force column-n resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceN) }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-N-force-textbox-' + loadIndex : 'LC-table-add-new-combination-N-textbox'}}"
                    class="input n-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.LoadCombination_CW_ForceZ.id, load.forceZ)"
                    (valueChange)="loadValueChange('forceZ', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.forceZRange?.min"
                    [maxValue]="helper.forceZRange?.max"
                    [disabled]="isLoadPropertyDisabled(propertyMetaData.LoadCombination_CW_ForceZ.id)">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number" *ngIf="load.hasSustainedLoads">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-dN-force-textbox-' + loadIndex : 'LC-table-add-new-combination-dN-textbox'}}"
                    class="input n-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.LoadCombination_CW_SustainedForceZ.id, load.dynamicForceZ)"
                    (valueChange)="loadValueChange('dynamicForceZ', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.forceZRange?.min"
                    [maxValue]="helper.forceZRange?.max"
                    [disabled]="isLoadPropertyDisabled(propertyMetaData.LoadCombination_CW_SustainedForceZ.id)">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Moments -->
    <div *ngIf="helper.columnVisibilities.momentMx"
        class="column-moment column-mx resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMx) }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-Mx-moment-textbox-' + loadIndex : 'LC-table-add-new-combination-Mx-textbox'}}"
                    class="input mx-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.LoadCombination_CW_MomentX.id, load.momentX)"
                    (valueChange)="loadValueChange('momentX', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.momentXRange?.min"
                    [maxValue]="helper.momentXRange?.max"
                    [disabled]="isLoadPropertyDisabled(propertyMetaData.LoadCombination_CW_MomentX.id)">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number" *ngIf="load.hasSustainedLoads">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-dMx-moment-textbox-' + loadIndex : 'LC-table-add-new-combination-dMx-textbox'}}"
                    class="input mx-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.LoadCombination_CW_SustainedMomentX.id, load.dynamicMomentX)"
                    (valueChange)="loadValueChange('dynamicMomentX', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicMomentXRange?.min"
                    [maxValue]="helper.dynamicMomentXRange?.max"
                    [disabled]="isLoadPropertyDisabled(propertyMetaData.LoadCombination_CW_SustainedMomentX.id)">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-left" (mousedown)="beginColumnResizing($event)"></div>
        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.momentMy"
        class="column-moment column-my resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMy) }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-My-moment-textbox-' + loadIndex : 'LC-table-add-new-combination-My-textbox'}}"
                    class="input my-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.LoadCombination_CW_MomentY.id, load.momentY)"
                    (valueChange)="loadValueChange('momentY', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.momentYRange?.min"
                    [maxValue]="helper.momentYRange?.max"
                    [disabled]="isLoadPropertyDisabled(propertyMetaData.LoadCombination_CW_MomentY.id)">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number" *ngIf="load.hasSustainedLoads">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-dMy-moment-textbox-' + loadIndex : 'LC-table-add-new-combination-dMy-textbox'}}"
                    class="input my-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.LoadCombination_CW_SustainedMomentY.id, load.dynamicMomentY)"
                    (valueChange)="loadValueChange('dynamicMomentY', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicMomentYRange?.min"
                    [maxValue]="helper.dynamicMomentYRange?.max"
                    [disabled]="isLoadPropertyDisabled(propertyMetaData.LoadCombination_CW_SustainedMomentY.id)">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="anchor-theory-number">
            <div class="number-container"></div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div *ngIf="helper.columnVisibilities.momentMz"
        class="column-moment column-mz resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMz) }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-Mz-moment-textbox-' + loadIndex : 'LC-table-add-new-combination-Mz-textbox'}}"
                    class="input my-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="valueOrNullIfPropHiddenOrDisabled(propertyMetaData.LoadCombination_CW_MomentZ.id, load.momentZ)"
                    (valueChange)="loadValueChange('momentZ', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.momentZRange?.min"
                    [maxValue]="helper.momentZRange?.max"
                    [disabled]="isLoadPropertyDisabled(propertyMetaData.LoadCombination_CW_MomentZ.id)">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number">
            <div class="number-container"></div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Results -->
    <!-- isAdd == true -->
    <div *ngIf="isAdd" class="column-add resizer-column"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.utilization) }">
        <div class="add-buttons">
            <pe-button
                id="{{parentId}}-confirm-add-button"
                class="confirm-add-button button button-default"
                [look]="'DefaultIcon'"
                (click)="addNewLoad()"
                [disabled]="submitted"
                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.ConfirmAdd')">
                <span class="pe-ui-cw-sprite pe-ui-cw-sprite-ok"></span>
            </pe-button>

            <pe-button
                id="{{parentId}}-cancel-add-button"
                class="cancel-add-button button button-default"
                [look]="'DefaultIcon'"
                [disabled]="submitted"
                (click)="helper.addingNewLoad = false"
                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.CancelAdd')">
                <span class="pe-ui-cw-sprite pe-ui-cw-sprite-x"></span>
            </pe-button>
        </div>

    </div>

    <!-- isAdd == false -->
    <div id="LC-table-utilization-section-{{loadIndex != null ? loadIndex : 'new-load'}}"
        class="resizer-column right-side-border"
        *ngIf="helper.showColumnUtilization && !isAdd"
        [ngClass]="helper.isPostInstallAnchorProduct ? 'column-utilization-pi' : 'column-utilization'"
        [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.utilization) }">

        <ng-template #utilizationTooltip>
            <div class="utilization-container" style="text-align: left;">
                <div class="utilization-container-item" style="display: flex">
                    <span [innerHtml]="getUtilizationMaxTooltipValues(load)"></span>
                </div>
            </div>
        </ng-template>

        <div *ngIf="showUtilizations" class="general-utilization" [class.bold]="isDecisive">
            <div class="utilization-number clip" [class.invalid-value]="maxTensionUtilizationInvalid"
                *ngIf="helper.isPostInstallAnchorProduct && !isUtilizationCompact">{{helper.formatPercentage(maxTensionUtilizationValue.toString())}}</div>
            <div class="utilization-number clip" [class.invalid-value]="maxShearUtilizationInvalid"
                *ngIf="helper.isPostInstallAnchorProduct && !isUtilizationCompact">{{helper.formatPercentage(maxShearUtilizationValue.toString())}}</div>
            <div class="utilization-number clip" [class.invalid-value]="maxCombinationUtilizationInvalid"
                *ngIf="helper.isPostInstallAnchorProduct && !isUtilizationCompact">{{helper.formatPercentage(maxCombinationUtilizationValue.toString())}}</div>

            <div class="utilization-number clip" *ngIf="getShowUtilizations(load) && (load.id == selectedLoadId || helper.isMultipleLoadCombinations)"
                [class.invalid-value]="getMaxGeneralUtilizationInvalid(load)"
                [ngbTooltip]="utilizationTooltip" [openDelay]="0" tooltipClass="loads-utilization-tooltip"
                [innerHtml]="unitService.formatInternalValueAsDefault(getMaxGeneralUtilizationValue(load), unitGroupEnum.Percentage)">
            </div>

        </div>
    </div>

    <div class="column-messages">
        <button
            id="LC-table-show-warning-icon-{{loadIndex != null ?  + loadIndex : 'new-load'}}-messages-button"
            type="button"
            class="messages-button"
            *ngIf="!isAdd && helper.hasMessages(load)"
            (click)="helper.showMessages(load)"
            [disabled]="submitted"
            [ngbTooltip]="translate('Agito.Hilti.CW.Loads.Messages')">
            <span class="pe-ui-cw-sprite pe-ui-cw-sprite-warning"></span>
        </button>
    </div>

    <div class="column-delete">
        <div class="tooltip-wrap"
            [disableTooltip]="submitted"
            [ngbTooltip]="translate('Agito.Hilti.CW.Loads.Delete')" *ngIf="!isAdd">
            <button
                id="LC-table-bin-button-{{loadIndex != null ? loadIndex : 'new-load'}}"
                type="button"
                class="delete-button"
                (click)="deleteLoad(load)"
                [disabled]="load.id == selectedLoadId || load.isWizardGenerated || helper.haveSingleLoad || !helper.isSelectingCombinationEnabled || submitted">
                <span class="pe-ui-cw-sprite pe-ui-cw-sprite-trash"></span>
            </button>
        </div>
    </div>

</div>
