
<div id="resize-anchor-design" class="anchor-design box-section box-section-white" *ngIf="showUtilizations">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static anchor-design-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.CW.Main.Region.DragToMove')">
            <div class="image" *ngIf="isUtilizationTypeDefault">
                <div class="pe-ui-cw-sprite pe-ui-cw-sprite-anchor-design"></div>
            </div>
            <span class="bar-title" [innerHtml]="title"></span>
        </div>

        <cw-utilization-bar [collapsed]="collapsed" [utilizationInvalid]="maxGeneralUtilizationInvalid" [utilizationValue]="maxGeneralUtilizationValue" [utilizationUnit]="getTitleUtilizationUnit"></cw-utilization-bar>

        <pe-collapse
            [container]="'anchor-design'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>
    <div class="utilizations" *ngIf="!collapsed">
        <div class="items box-section-content">

            <ng-container *ngFor="let utilization of utilizations(); trackBy: trackUtilizationByProperty">
                <div class="utilization"
                    [class.invalid-value]="isUtilizationValueInvalid(getUtilizationMaxValueDetail(utilization.property)?.name ?? '')">
                    <div id="{{id}}-{{utilization.property}}-button" class="utilization-button">
                        <div class="button-content">
                            <div class="text-progress-container" *ngIf="isUtilizationTypeDefault">
                                <p class="text" [innerHtml]="translate(utilization.text)"></p>
                                <div class="progress-percent-container">
                                    <div class="progress-percent"
                                        [ngStyle]="{ 'width': getUtilizationMaxValue(utilization.property) + '%' }">
                                    </div>
                                </div>
                            </div>

                            <div class="number-container">
                                <div class="details">
                                    <div *ngFor="let detail of utilization.details; trackBy: trackUtilizationDetailByProperty">
                                        <div class="details-line"
                                            [class.invalid-value]="isUtilizationValueInvalid(detail.property)"
                                            *ngIf="getUtilizationValue(detail.property) != undefined && (isUtilizationOpened(utilization.property) || getUtilizationMaxValueDetail(utilization.property)?.name == detail.property)">
                                            <span class="pe-ui-cw-sprite" [class]="image(detail.image)"></span>
                                            <p class="text" [innerHtml]="detail.text != '' ? translate(detail.text) : ''"></p>
                                            <p class="number notranslate" [innerHtml]="getUtilizationValue(detail.property)"></p>
                                            <span *ngIf="isUtilizationValueInvalid(detail.property)"
                                                class="pe-ui-cw-sprite pe-ui-cw-sprite-notification-alert-small warning"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="number-container"
                                *ngIf="isUtilizationTypeDefault && !getUtilizationMaxValueDetail(utilization.property)">
                                <p class="number notranslate" [innerHtml]="'0 %'"></p>
                            </div>

                            <div class="design-right-collapse-container"
                                [class.opened]="isUtilizationOpened(utilization.property)"
                                *ngIf="isUtilizationTypeDefault && getUtilizationMaxValueDetail(utilization.property) && haveMultiItems(utilization.property)">
                                <span class="design-right-collapse-line"></span>

                                <button type="button" class="design-right-collapse-button"
                                    (click)="changeUtilizationOpened(utilization.property)">
                                    <span class="pe-ui-cw-sprite" [ngClass]="isUtilizationOpened(utilization.property) ? 'pe-ui-cw-sprite-arrow-up' : 'pe-ui-cw-sprite-arrow-down'"></span>
                                </button>

                                <span class="design-right-collapse-line"></span>
                            </div>
                        </div>
                    </div>
                </div>
             </ng-container>
        </div>

    </div>
</div>
