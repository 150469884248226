import { Injectable } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { ApplicationState, getSpecialRegionName } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { sortByUnicode } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CommonCodeListService } from '../services/common-code-list.service';
import { LocalizationService } from '../services/localization.service';
import { NumberService } from '../services/number.service';
import { UserSettingsService } from '../services/user-settings.service';
import { Unit as UnitItem } from '../entities/code-lists/unit';
import { CommonRegion as Region, ICommonRegionConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { ProjectCodeList } from '../entities/enums/project-code-list';
import { DesignStandard } from '../entities/code-lists/design-standard';
import { CodeListService } from '../services/code-list.service';
import { DesignMethodGroup } from '../entities/code-lists/design-method-group';
import { IFixing } from '../entities/fixing';
import { PropertyMetaData } from '../entities/properties';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';

export class AnchorPlateFactors {
    public static DefaultAnchorPlateFactor = 100;
    public static DefaultValue = 1.5;
    public static DefaultMinValue = 0;
    public static DefaultMaxValue = 2;
}

export interface IDesignStandardMethodGroup {
    designStandardId: number;
    designMethodGroupId: number;
}

@Injectable()
export class AppSettingsHelper {

    constructor(
        protected localizationService: LocalizationService,
        protected userSettings: UserSettingsService,
        protected codeList: CodeListService,
        protected commonCodeListService: CommonCodeListService,
        protected numberService: NumberService
    ) {
        this.localizationService = localizationService;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public get designType() {
        return this.codeList.designType;
    }

    public get regions() {
        return this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as Region[];
    }

    public createRegionDropdown(id: string, translationKey: string) {

        const regionItems = this.getAvailableRegions().map(region => ({
            value: region.id,
            text: this.translate(region.nameResourceKey ?? '')
        }) as DropdownItem<number>);

        return this.createDropdownComponent<number>(
            id,
            translationKey,
            regionItems
        );
    }

    public createUnitDropdown(id: string, translationKey: string, codeList: CommonCodeList) {
        return this.createDropdownComponent<number>(
            `unit-${id}`,
            translationKey,
            (this.commonCodeListService.commonCodeLists[codeList] as UnitItem[]).map(unit => ({
                value: unit.id,
                text: unit.name
            }) as DropdownItem<number>)
        );
    }

    public createDropdownComponent<TValue>(id: string, translationKey: string, items?: DropdownItem<TValue>[], selectedValue?: TValue) {
        const dropdown: DropdownProps<TValue> = {
            id: `application-settings-${id}`,
            title: translationKey ? this.translate(`Agito.Hilti.CW.ApplicationSettings.${translationKey}`) : '',
            items,
            selectedValue
        };
        return dropdown;
    }

    public createBaseFactorComponent(id: string, translationKey: string, fixing: IFixing) {

        const basePlateFactorValue = this.codeList.getPropertyValue(PropertyMetaData.Option_CW_BasePlateFactor.id, fixing.region);
        return this.createNumericTextBox(
            `application-settings-${id}`,
            translationKey,
            fixing.basePlateFactor ?? undefined,
            basePlateFactorValue?.minValue ?? AnchorPlateFactors.DefaultMinValue,
            basePlateFactorValue?.maxValue ?? AnchorPlateFactors.DefaultMaxValue,
            basePlateFactorValue?.defaultValue ?? AnchorPlateFactors.DefaultValue,
        );
    }

    public createNumericTextBox(id: string, translationKey: string, value?: number, minValue?: number, maxValue?: number, placeholder?: number | string, disabled?: boolean, precision?: number) {
        const numericTextBox: NumericTextBoxProps = {
            id: `application-settings-${id}`,
            title: this.translate(`Agito.Hilti.CW.ApplicationSettings.${translationKey}`),
            value,
            minValue,
            maxValue,
            placeholder,
            disabled,
            precision
        };

        return numericTextBox;
    }

    public setDefaultUnits(fixing: IFixing, regionId: number) {
        const region = this.getRegionById(regionId) ?? new Region({ id: 0 } as ICommonRegionConstructor);

        fixing.length = region.defaultUnitLength ?? 0;
        fixing.area = region.defaultUnitArea ?? 0;
        fixing.stress = region.defaultUnitStress ?? 0;
        fixing.force = region.defaultUnitForce ?? 0;
        fixing.moment = region.defaultUnitMoment ?? 0;
        fixing.temperature = region.defaultUnitTemperature ?? 0;
        fixing.forcePerLength = region.defaultUnitForcePerLength ?? 0;
        fixing.momentPerLength = region.defaultUnitMomentPerLength ?? 0;
        fixing.density = region.defaultUnitDensity ?? 0;
        fixing.applicationStateControl = ApplicationState.Unknown;
    }

    public getCommonCodelistOrDefault(codelistItems: CommonCodeList, itemId: number | null, defaultId: number): number {
        return this.commonCodeListService.commonCodeLists[codelistItems].find(x => x.id == itemId)?.id ?? defaultId;
    }

    public getProjectCodelistOrDefault(codelistItems: ProjectCodeList, itemId: number | null, defaultId: number): number {
        return this.codeList.projectCodeLists[codelistItems].find(x => x.id == itemId)?.id ?? defaultId;
    }

    public getDefaultDesignStandard(regionId: number) {
        const designStandardValue = this.codeList.getPropertyValue(PropertyMetaData.Option_CW_DesignStandard.id, regionId);

        const designStandardList = this.codeList.projectCodeLists[ProjectCodeList.DesignStandards] as DesignStandard[];
        const designStandardId = designStandardList.find((d) => d.id == (designStandardValue?.defaultValue ?? 0));

        return designStandardId !== undefined
            ? designStandardId
            : designStandardList[0];
    }

    public setDefaultDesignStandard(fixing: IFixing, regionId: number) {
        const designStandard = this.getDefaultDesignStandard(regionId);
        fixing.designStandardId = designStandard?.id ?? 0;
    }

    public getDefaultDesignMethod(regionId: number, designStandardId: number) {
        const designMethodGroupValue = this.codeList.getPropertyValue(PropertyMetaData.Option_CW_DesignMethodGroup.id, regionId, designStandardId);

        const designMethodGroupList = this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroups] as DesignMethodGroup[];
        const designMethodGroup = designMethodGroupList.find((d) => d.id == (designMethodGroupValue?.defaultValue ?? 0));

        return designMethodGroup !== undefined
            ? designMethodGroup
            : designMethodGroupList[0];
    }

    public setDefaultDesignMethod(fixing: IFixing, regionId: number) {
        const designMethodGroup = this.getDefaultDesignMethod(regionId, fixing.designStandardId ?? 0);
        fixing.designMethodGroupId = designMethodGroup?.id ?? 0;
    }

    public isRegionAllowed(regionId: number): boolean {
        const allRegions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as Region[];
        const allowedRegions = allRegions.filter(r => this.codeList.designType?.regions.includes(r.id ?? 0));
        return allowedRegions.some(r => (r.id ?? 0) === regionId);
    }

    public getRegionById(regionId: number): Region {
        return this.commonCodeListService.getRegionById(regionId) ?? new Region({ id: 0 } as ICommonRegionConstructor);
    }

    public getAvailableRegions() {
        // none and default regions
        const specialRegions = this.getSpecialRegions();
        const allRegions = this.regions;
        const availableRegions = allRegions.filter(r => this.codeList.designType?.regions.includes(r.id ?? 0));

        // sort
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const availableRegionsSorted = sortByUnicode(
            availableRegions.map(
                (region) => {
                    return {
                        region,
                        text: region.getTranslatedNameText(codeListDeps) ?? ''
                    };
                }
            ),
            'text'
        ).map(
            (o) => {
                return o.region;
            }
        );

        return [...specialRegions, ...availableRegionsSorted];
    }

    public getSpecialRegions() {
        const specialRegions: any[] = [];

        for (let i = 0; i < 2; i++) {
            specialRegions.push({
                id: -(i + 1),
                nameResourceKey: 'Agito.Hilti.CW.ApplicationSettings.SpecialRegion.' + getSpecialRegionName(-(i + 1))
            });
        }

        return specialRegions;
    }

    public getDesignStandards() {
        return this.codeList.projectCodeLists[ProjectCodeList.DesignStandards] as DesignStandard[];
    }

    public getDesignStandardItems() {
        const designStandardItems = this.getDesignStandards();
        if (designStandardItems.length == 0) {
            return;
        }
        return designStandardItems.map(designStandard => ({
            value: designStandard.id,
            text: this.translate(designStandard.nameResourceKey ?? '')
        }) as DropdownItem<number>);
    }

    public getDesignStandardById(designStandardId: number) {
        const designStandards = this.getDesignStandards();
        return designStandards.find((designStandard) => designStandard.id == designStandardId);
    }

    public getDesignMethodGroups() {
        return this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroups] as DesignMethodGroup[];
    }

    public getDesignMethodItems() {
        const designMethodItems = this.getDesignMethodGroups();
        if (designMethodItems.length == 0) {
            return;
        }
        return designMethodItems.map(designMethod => ({
            value: designMethod.id,
            text: this.translate(designMethod.nameResourceKey ?? '')
        }) as DropdownItem<number>);
    }

    public getDesignMethodById(designMethodId: number) {
        const designMethods = this.getDesignMethodGroups();
        return designMethods.find((designMethod) => designMethod.id == designMethodId);
    }

    public getDesignMethodIdOrDefault(regionId: number, designStandardId: number, designMethodId: number): number {
        const designMethods = this.getDesignMethodGroupsForRegion(designStandardId, regionId);

        if (designMethods.find(x => x.id == designMethodId))
            return designMethodId;

        return this.getDefaultDesignMethod(regionId, designStandardId)?.id ?? 0;
    }

    public getDesignStandardMethodPairs(regionId: number): IDesignStandardMethodGroup[] {
        return this.codeList.getDesignStandardMethodRegion()
            .filter(x => x.regionId == regionId)
            .map(x => ({ designStandardId: x.designStandardId, designMethodGroupId: x.designMethodGroupId }));
    }

    public getDesignStandardsForRegion(regionId: number) {
        const allDesignStandards = this.codeList.getDesignStandards();
        const designMethodGroups = (this.codeList.getDesignMethodGroups())
            .filter((methodGroup) => this.getDesignStandardMethodPairs(regionId)
            .some(x => x.designMethodGroupId == methodGroup.id)) || [];

        const standards = allDesignStandards
            .filter(standard => designMethodGroups
                .filter(designMethod => this.getDesignStandardMethodPairs(regionId)
                    .filter(x => x.designMethodGroupId == designMethod.id)
                    .map(y => y.designStandardId)
                    .includes(standard.id)).length > 0);

        return standards;
    }

    public getDesignMethodGroupsForRegion(designStandard: number | undefined, regionId: number) {
        if (!designStandard) {
            return [] as DesignMethodGroup[];
        }

        const allMethodGroups = this.codeList.getDesignMethodGroups();

        const designMethodGroups = allMethodGroups
            .filter((methodGroup) => this.getDesignStandardMethodPairs(regionId).some(x => methodGroup.id == x.designMethodGroupId && x.designStandardId == designStandard));

        return designMethodGroups;
    }
}
