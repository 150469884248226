import { environment } from '../../environments/environmentCW';
import { Design } from '../entities/design';
import { ApprovalsEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { PropertyMetaData } from '../entities/properties';
import { ModalService } from '../services/modal.service';
import { OfflineService } from '../services/offline.service';
import { DesignMethodGroups } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';

export function openApprovalLinkOrModalDialog(design: Design, modalService: ModalService, offlineService: OfflineService) {

    const approvals = design.model[PropertyMetaData.AnchorChannel_CW_Approvals.id] as ApprovalsEntity[];

    // Open popup with multiple approvals showing allowing user to open each separately
    // and preventing browser to block second tab-opening as popup
    if (approvals.length > 1) {
        modalService.openMultipleApprovals(approvals);
    }
    // Open tab otherwise
    else {
        if (approvals[0] != null && approvals[0].filename != '') {
            offlineService.nativeLocalPathOpen(`${environment.baseUrl}approvals/${approvals[0].fileUrl}`, approvals[0].filename, true, true);
        }
    }
}

export function getPostInstallApprovalKey(design: Design | undefined): string {

    switch (design?.designMethodGroup?.id) {
        case DesignMethodGroups.ACI31811:
        case DesignMethodGroups.ACI31814:
        case DesignMethodGroups.ACI31819:
        case DesignMethodGroups.CSAA23314:
        case DesignMethodGroups.CSAA23319:
            return 'Agito.Hilti.CW.ViewApproval.HNA';
        case DesignMethodGroups.EN19924:
            return 'Agito.Hilti.CW.ViewApproval.ETA';
        default:
            return '';
    }
}

