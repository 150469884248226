import { ISignalRError, ConnectionType, ErrorReason } from '@profis-engineering/pe-ui-common/services/signalr.common';
import { AuthenticationService } from '../authentication.service';
import { LocalizationService } from '../localization.service';
import { ModalService } from '../modal.service';
import { SignalRService } from '../signalr.service';

/**
 * Class that handles exceptions from SignalR service
 */
export class SignalRExceptionHandler {

    private exceptionReason!: ISignalRError | string;

    constructor(
        private modalService: ModalService,
        private localizationService: LocalizationService,
        private authenticationService: AuthenticationService
    ) { }

    public async handle(reason: ISignalRError | string, endPointUrl: string, connectionType: ConnectionType, requestId: string | undefined): Promise<void> {
        this.exceptionReason = reason;

        await this.handleInternal(endPointUrl, connectionType, requestId);
    }

    private async handleInternal(endPointUrl: string, connectionType: ConnectionType, requestId: string | undefined): Promise<void> {
        if (connectionType == ConnectionType.Websocket && typeof this.exceptionReason == 'string') {
            this.handleWebSocketErrors(this.exceptionReason, endPointUrl, requestId);
        }

        if (typeof this.exceptionReason == 'string') {
            this.openDialogAndThrowErr(this.exceptionReason, this.exceptionReason, endPointUrl, requestId);
            return;
        }

        await this.handleSignalRErrors(this.exceptionReason, endPointUrl, requestId);
    }

    private handleWebSocketErrors(reason: string, endPointUrl: string, requestId: string | undefined) {
        // Check and handle web socket closed error
        this.handleWebSocketClosedError(reason, endPointUrl, requestId);

        // Check and handle hub exception
        this.handleHubExceptionError(reason);
    }

    private handleWebSocketClosedError(reason: string, endPointUrl: string, requestId: string | undefined) {
        // Handle websocket status code 1006 (connection was closed abnormally)
        const webSocketExceptionIndex = reason.indexOf('WebSocket');
        const webSocketClosedIndex = reason.indexOf('1006');

        if (webSocketExceptionIndex >= 0 && webSocketClosedIndex >= 0) {
            this.openDialogAndThrowErr(
                reason,
                this.localizationService.getString('Agito.Hilti.Profis3.ServerErrorAlert.ConnectionClosed'),
                endPointUrl,
                requestId,
                reason);
        }
    }

    private handleHubExceptionError(reason: string): void {
        let hubExceptionIndex = reason.indexOf('HubException: {');
        if (hubExceptionIndex < 0)
            return;

        hubExceptionIndex += 'HubException: '.length;

        const hubExceptionJson = reason.substring(hubExceptionIndex);
        try {
            const signalRError: ISignalRError = JSON.parse(hubExceptionJson);

            // Set the global exception reason to the parsed as SignalRError (to be handled in the next steps or error handling)
            this.exceptionReason = {
                message: signalRError.message,
                data: signalRError.data
            };
        }
        catch (error) {
            console.error(error);
        }
    }

    private async handleSignalRErrors(reason: ISignalRError, endPointUrl: string, requestId?: string): Promise<void> {
        if (reason?.data?.ReasonPhrase == ErrorReason.CalculationCanceled) {
            throw new Error(ErrorReason.CalculationCanceled);
        }

        // First part handles error from "hubProxy.invoke", second part from "connection.start"
        if (SignalRService.getStatusCode(reason) == 401) {
            await this.handleUnauthorizedError(reason);
            return;
        }

        const errorMessage = reason?.data?.StatusCode?.toString() ?? 'Unknown error';
        this.openDialogAndThrowErr(errorMessage, reason, endPointUrl, requestId);
    }

    private async handleUnauthorizedError(reason: ISignalRError): Promise<void> {
        // do not resolve or reject the promise
        // openUnauthorizedAccess and logout will both redirect to the login page
        if (reason?.data?.ReasonPhrase == ErrorReason.LoginOtherDevice) {
            this.modalService.openUnauthorizedAccess();
            return;
        }

        const token = await this.authenticationService.tryExtendToken();
        if (!token) {
            // token cannot be extended, user is logged out
            await this.authenticationService.logout();
        }
    }

    private openDialogAndThrowErr(
        errorMessage: string,
        response: ISignalRError | string,
        endPointUrl: string,
        correlationId: string | undefined,
        responsePayload?: object | string | undefined
    ) {
        this.modalService.openAlertSignalRError({
            response,
            correlationId,
            responsePayload,
            endPointUrl
        });

        throw new Error(errorMessage);
    }
}