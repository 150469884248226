import {
    CodeList, ICodeLists
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { IModalGridItem } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import {
    LocalizationServiceBase as LocalizationService
} from '@profis-engineering/pe-ui-common/services/localization.common';
import {
    UnitServiceBase as UnitService
} from '@profis-engineering/pe-ui-common/services/unit.common';

import { DesignCodeList } from '../entities/enums/design-code-list';
import { getProperty } from './object-helper';

export class ModalGridHelper {
    public static createPopupGridItems(codeList: DesignCodeList | undefined, designCodeLists: ICodeLists, mapping: { [property: string]: string | ((codeListItem: CodeList) => string) }) {
        if (codeList != null) {
            const codeListItems = designCodeLists[codeList];

            return codeListItems?.map((codeListItem): IModalGridItem<number> => {
                return {
                    value: codeListItem.id,
                    name: this.mapCodeListProperty<string>('translatedNameText', mapping, codeListItem),
                    description: this.mapCodeListProperty<string>('description', mapping, codeListItem),
                    subDescription: this.mapCodeListProperty<string>('subDescription', mapping, codeListItem),
                    image: `sprite-${this.mapCodeListProperty<string>('image', mapping, codeListItem)}`,
                    kbNumberRegion: codeListItem.kbNumberRegion,
                    kbTooltip: codeListItem.kbTooltip
                };
            });
        }

        return undefined;
    }

    public static getPopupGridItemMapping(localization: LocalizationService, unit: UnitService, codeList?: DesignCodeList): { [property: string]: string | ((codeListItem: CodeList) => string) } {
        switch (codeList) {
            case DesignCodeList.SeismicTensionTypes:
            case DesignCodeList.SeismicShearTypes:
                return {
                    description: (codeListItem: CodeList) => {
                        return codeListItem.getTranslatedDescriptionText({ localizationService: localization }) ?? '';
                    },
                    translatedNameText: (codeListItem: CodeList) => {
                        return codeListItem.getTranslatedNameText({ localizationService: localization }) ?? '';
                    }
                };
            default:
                return {
                    translatedNameText: (codeListItem: CodeList) => {
                        return codeListItem.getTranslatedNameText({ localizationService: localization }) ?? '';
                    }
                };
        }
    }

    private static mapCodeListProperty<TValue>(property: string, mapping: { [property: string]: string | ((codeListItem: CodeList) => TValue) }, codeListItem: CodeList): TValue {
        if (mapping == null) {
            mapping = {};
        }

        const propertyMap = mapping[property];

        if (propertyMap != null && propertyMap != '') {
            if (typeof propertyMap == 'function') {
                return propertyMap(codeListItem);
            }

            return getProperty(codeListItem, propertyMap);
        }

        return getProperty(codeListItem, property);
    }
}
