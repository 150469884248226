import isEqual from 'lodash-es/isEqual';
import { Subscription } from 'rxjs';
import {
    Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit,
    Output, SimpleChanges, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    filterProductFilterGroups,
    filterProductFilters
} from '../../../cw/helpers/product-helper';
import { DesignEvent } from '@profis-engineering/pe-ui-common/entities/design';
import { DesignCodeList } from '../../entities/enums/design-code-list';
import { IPropertyMetaData, PropertyMetaData } from '../../entities/properties';
import { openApprovalLinkOrModalDialog } from '../../helpers/approval-helper';
import { CodelistHelper } from '../../helpers/codelist-helper';
import { CalculationService } from '../../services/calculation.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { ProductService } from '../../services/product-service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle, includeSprites, Sprite } from '../../sprites';
import { isAnchorRebar } from '../../services/helpers/static-menu-helper';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { Constants } from '../../entities/constants';
import { Design } from '../../entities/design';
import { Tooltip } from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import { ToggleButtonGroupItem, ToggleButtonGroupProps } from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import { getPostInstallApprovalKey } from '../../helpers/approval-helper';
import { IProduct } from '../../entities/product';
import { AnchorFilterGroupOperator as GroupOperator} from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { AnchorFilter as AnchorFilterEntity } from '../../entities/code-lists/anchor-filter';
import { AnchorFilterGroup as AnchorFilterGroupEntity } from '../../entities/code-lists/anchor-filter-groups';
import { ProductFilterEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';

interface IFiltersGroupNumber {
    [groupId: number]: number;
}

interface ProductFilterDisplay {
    id: number;
    title?: string;
    anchorFilterGroupId?: number;
}

interface ISelectProductFilterGroupConstructor {
    groupOperator?: GroupOperator;
    items?: SelectProductFilterGroupItem[];
}

export class SelectProductFilterGroup {
    public groupOperator?: GroupOperator;
    public items: SelectProductFilterGroupItem[] = [];

    constructor(ctor?: ISelectProductFilterGroupConstructor) {
        if (ctor != null) {
            this.groupOperator = ctor.groupOperator;
            this.items = ctor.items ?? [];
        }
    }
}

interface ISelectProductFilterGroupItemConstructor {
    id?: number;
    textKey?: string;
    disabled?: boolean;
    selected?: boolean;
}

class SelectProductFilterGroupItem {
    public id?: number;
    public textKey?: string;
    public disabled?: boolean;
    public selected?: boolean;

    constructor(ctor?: ISelectProductFilterGroupItemConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.textKey = ctor.textKey;
            this.disabled = ctor.disabled;
            this.selected = ctor.selected;
        }
    }
}

@Component({
    selector: 'cw-product-drop-down',
    templateUrl: './product-drop-down.component.html',
    styleUrls: ['./product-drop-down.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ProductDropDownComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    public collapsed = false;

    @Input()
    public parentElement?: HTMLElement;

    @Output()
    public collapsedChange = new EventEmitter<boolean>();

    public propertyMetaData = PropertyMetaData;
    public products: IProduct[] = [];
    public productType: DropdownProps<number> = {};
    public channelLength: DropdownProps<number> = {};
    public userDefinedChannelLength: NumericTextBoxProps | undefined = {};
    public customRebarLength: NumericTextBoxProps | undefined = {};
    public embedmentDepth: DropdownProps<number> = {};

    public postInstallAnchorType: DropdownProps<number> = {};
    public postInstallAnchorSize: DropdownProps<number> = {};
    public embedmentDepthTypeToggleButtonGroup!: ToggleButtonGroupProps<number>;
    public embedmentDepthValueDropdown: DropdownProps<number> = {};
    public embedmentDepthTooltip!: Tooltip;

    private productFilterGroups: SelectProductFilterGroup[] = [];
    private productFilters?: ProductFilterDisplay[];

    private infoSectionHeight!: number;
    private allowedValuesChangedFn?: () => void;
    private localizationChangeSubscription?: Subscription;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private calculationService: CalculationService,
        private numberService: NumberService,
        private unitService: UnitService,
        private productService: ProductService,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone,
        public offlineService: OfflineService,
        private modalService: ModalService,
    ) { }

    public get title() {
        return this.translate(this.isPostInstallAnchorProduct() ? 'Agito.Hilti.CW.ProductDropDown.Anchor' : 'Agito.Hilti.CW.ProductDropDown.Product');
    }

    public get design() {
        return this.userService.design;
    }

    public get selectedProductId() {
        return this.design.selectedProductId;
    }

    public isPostInstallAnchorProduct() {
        return this.design.isPostInstallAnchorProduct();
    }

    public get selectedProductProperty() {
        return this.design.properties.get(PropertyMetaData.AnchorChannel_CW_Family.id);
    }

    public get productHidden() {
        return this.selectedProductProperty.hidden;
    }

    public itemNumberTitle() {
        return this.isPostInstallAnchorProduct()
            ? this.translate('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.AnchorRodItem')
            : this.translate('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.ItemNumber');
    }

    public adhesiveNumberTitle() {
        return this.translate('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.AdhesiveNumber');
    }

    public capsuleNumberTitle() {
        return this.translate('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.CapsuleNumber');
    }


    public get getProductFamilyName() {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const productFamilyGroup = this.isPostInstallAnchorProduct() ? this.design.anchorFamily : this.design.anchorChannelFamilyGroup;

        return productFamilyGroup?.getTranslatedNameText(codeListDeps);
    }

    public hasAdhesiveValue() {
        const property = this.design.properties.get(PropertyMetaData.AnchorChannel_CW_AdhesiveNumber.id);
        return property != undefined && !property.hidden;
    }

    public hasCapsuleValue() {
        const property = this.design.properties.get(PropertyMetaData.AnchorChannel_CW_CapsuleNumber.id);
        return property != undefined && !property.hidden;
    }

    public get embedmentDepthHidden() {
        return this.design?.properties?.get(PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthOptimizationType.id)?.hidden
            && this.design?.properties?.get(PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthVariable.id)?.hidden
            && this.design?.properties?.get(PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthFixedMultiple.id)?.hidden;
    }

    public get infoSectionContainer() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector('#resize-product-dropdown') as HTMLElement;
    }

    public get productName() {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        const productFamilyGroup = this.isPostInstallAnchorProduct() ? this.design.anchorFamily : this.design.anchorChannelFamilyGroup;
        return productFamilyGroup != undefined
            ? productFamilyGroup.getTranslatedNameText(codeListDeps)
            : this.translate('Agito.Hilti.CW.ProductDropDown.NotSelected');
    }

    public get approvalsProperty() {
        return this.design.properties.get(PropertyMetaData.AnchorChannel_CW_Approvals.id);
    }

    public get hideChannelItemNumber() {
        return this.design.designData.projectDesign.product.anchorChannel.itemNumber == null;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-arrow-down',
            'sprite-arrow-up',
            'sprite-favorite-false',
            'sprite-favorite-true',
            'sprite-x',
            'sprite-product-dropdown'
        );

        this.allowedValuesChangedFn = this.allowedValuesChanged.bind(this);
        this.design.onAllowedValuesChanged(this.allowedValuesChangedFn);

        this.onResize = this.onResize.bind(this);

        this.initControls();

        this.design.onStateChanged((_design, state, oldState) => {
            // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
            const onStateChanged = () => {
                if (!isEqual(oldState.model[PropertyMetaData.Product_CW_Filters.id], state.model[PropertyMetaData.Product_CW_Filters.id])) {
                    this.getSavedGroupsAndFilters();
                    this.reloadInputs();
                }

                if (!isEqual(oldState.model[PropertyMetaData.Option_UnitLength.id], state.model[PropertyMetaData.Option_UnitLength.id])) {
                    this.loadAnchorChannelLength();
                    this.loadAnchorChannelEmbedmentDepth();
                    this.loadCustomRebarLength();
                }

                if (!isEqual(oldState.model[PropertyMetaData.AnchorChannel_CW_UserDefinedLength.id], state.model[PropertyMetaData.AnchorChannel_CW_UserDefinedLength.id])) {
                    this.loadUserDefinedAnchorChannelLength();
                }

                if (!isEqual(oldState.model[PropertyMetaData.AnchorChannel_CW_CustomRebarLength.id], state.model[PropertyMetaData.AnchorChannel_CW_CustomRebarLength.id])) {
                    this.loadCustomRebarLength();
                }
            };
            return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
        });

        this.getSavedGroupsAndFilters();

        this.localizationChangeSubscription = this.localizationService.localizationChange.subscribe(this.productService.updateProductNameText.bind(this));
        this.allowedValuesChanged();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['collapsed'] != null) {
            this.onCollapsedChanged(this.collapsed);
        }
    }

    ngOnDestroy(): void {
        if (this.localizationChangeSubscription != null) {
            this.localizationChangeSubscription.unsubscribe();
        }

        if (this.allowedValuesChangedFn != null) {
            this.design?.off(DesignEvent.allowedValuesChanged, this.allowedValuesChangedFn);
            this.allowedValuesChangedFn = undefined;
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        if (!this.collapsed) {
            this.calculateProductsElementHeights();
        }
    }

    public onResize() {
        setTimeout(() => {
            this.infoSectionHeight = this.infoSectionContainer.offsetHeight;
            this.resizeInfoSection(false);
        });
    }

    public setPropertyValue(value: number, propetyId: IPropertyMetaData) {
        if (this.design.isReadOnlyDesignMode) {
            return;
        }

        this.calculationService.calculateAsync(this.design, d => d.model[propetyId.id] = value);
    }

    public translate(key?: string) {
        return this.localizationService.getString(key ?? '');
    }

    public onInfoSectionCollapse(collapsed: boolean) {
        this.collapsedChange.emit(collapsed);
    }

    public openSelectProduct() {
        this.modalService.openSelectAnchorChannelLength();
    }

    private getSavedGroupsAndFilters() {
        const productFilters = this.design?.designData?.designCodeLists[DesignCodeList.AnchorFilter] as AnchorFilterEntity[];
        const productFilterGroups = this.design?.designData?.designCodeLists[DesignCodeList.AnchorFilterGroup] as AnchorFilterGroupEntity[];

        // load saved filters
        const savedProductFilters = this.design?.model[PropertyMetaData.Product_CW_Filters.id] as ProductFilterEntity;

        const filteredProductFilters = filterProductFilters(productFilters, savedProductFilters);
        const filteredProductFilterGroups = filterProductFilterGroups(productFilterGroups, filteredProductFilters);

        this.productFilters = savedProductFilters != null ? savedProductFilters.checkboxFilters.map((checkboxFilter): ProductFilterDisplay => ({
            id: checkboxFilter.id,
            title: filteredProductFilters.find(filter => filter.id == checkboxFilter.id)?.nameResourceKey,
            anchorFilterGroupId: filteredProductFilters.find(filter => filter.id == checkboxFilter.id)?.anchorFilterGroupId
        })) : undefined;

        this.productFilterGroups = filteredProductFilterGroups?.map((productFilterGroup) => new SelectProductFilterGroup({
            groupOperator: productFilterGroup.groupOperator,
            items: filteredProductFilters.filter((productFilter) => productFilter.anchorFilterGroupId == productFilterGroup.id).map((productFilter) => new SelectProductFilterGroupItem({
                id: productFilter.id,
                textKey: productFilter.nameResourceKey,
                selected: this.productFilters != null && this.productFilters.find((filter) => filter.id == productFilter.id) != null,
                disabled: savedProductFilters.checkboxFiltersDisabled.some(disabled => disabled.id == productFilter.id)
            }))
        }));
    }

    public trackProductById: TrackByFunction<IProduct> = (_: number, product: IProduct) => product.id;

    public initControls() {
        this.reloadInputs();
    }

    public reloadInputs() {
        this.products = this.productService.loadProducts(this.productFilterGroups);
        setTimeout(() => this.resizeInfoSection(true));

        if (this.isPostInstallAnchorProduct()) {
            this.loadPostInstallAnchorType();
            this.loadPostInstallAnchorSize();
            this.loadPostInstallEmbedmentDepth();
            this.loadPostInstallEmbedmentDepthTypeToggleButton();
        }
        else {
            this.loadProductType();
            this.loadAnchorChannelLength();
            this.loadUserDefinedAnchorChannelLength();
            this.loadAnchorChannelEmbedmentDepth();
            this.loadCustomRebarLength();
        }
    }

    public IsCustomRebarLengthAllowed(design: Design) {
        return design.properties.get(PropertyMetaData.AnchorChannel_CW_EmbedmentDepth.id).allowedValues?.includes(Constants.UserDefinedId);
    }

    public isEmbedmentDepthDisabled(design: Design) {

        return this.IsCustomRebarLengthAllowed(design) ? false : isAnchorRebar(design);
    }

    public loadProductType() {
        this.productType = this.createDropdownComponent(
            'product-dropdown-anchor-type-dropdown',
            'Agito.Hilti.CW.AnchorChannel.Type',
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                this.getCodeListItems(DesignCodeList.AnchorChannelFamily, PropertyMetaData.AnchorChannel_CW_Type.id),
                DesignCodeList.AnchorChannelFamily)
        );
    }

    public loadAnchorChannelLength() {
        this.channelLength = this.createDropdownComponent(
            'product-dropdown-anchor-lenght-dropdown',
            'Agito.Hilti.CW.AnchorChannel.Length',
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                this.getCodeListItems(DesignCodeList.AnchorChannelLength, PropertyMetaData.AnchorChannel_CW_Length.id),
                DesignCodeList.AnchorChannelLength)
        );
    }

    public loadUserDefinedAnchorChannelLength() {
        this.userDefinedChannelLength = this.design.model[this.propertyMetaData.AnchorChannel_CW_Length.id] == -1 ? {
            id: 'product-dropdown-user-defined-anchor-length-dropdown',
            title: this.translate('Agito.Hilti.CW.AnchorChannel.Length.UserDefined'),
            unit: this.design.unitLength,
            value: this.design.designData.projectDesign.product.anchorChannel.length,
        } : undefined;
    }

    public loadCustomRebarLength() {
        this.customRebarLength = this.design.model[PropertyMetaData.AnchorChannel_CW_EmbedmentDepth.id] == Constants.UserDefinedId ? {
            id: 'product-dropdown-anchor-channel-custom-rebar-length-textbox',
            title: this.translate('Agito.Hilti.CW.AnchorChannel.CustomRebarLength'),
            unit: this.design.unitLength,
            value: this.design.designData.projectDesign.product.anchorChannel.customRebarLength,
        } : undefined;
    }

    public loadAnchorChannelEmbedmentDepth() {
        this.embedmentDepth = this.createDropdownComponent(
            'product-dropdown-anchor-embedment-depth-dropdown',
            isAnchorRebar(this.design) ? 'Agito.Hilti.CW.AnchorChannel.RebarLength' : 'Agito.Hilti.CW.AnchorChannel.EmbedmentDepth',
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                this.getCodeListItems(DesignCodeList.AnchorChannelEmbedmentDepth, PropertyMetaData.AnchorChannel_CW_EmbedmentDepth.id),
                DesignCodeList.AnchorChannelEmbedmentDepth)
        );
    }

    public loadPostInstallAnchorType() {
        this.postInstallAnchorType = this.createDropdownComponent(
            'product-dropdown-post-install-anchor-type-dropdown',
            'Agito.Hilti.Profis3.InfoSection.AnchorSection.Type',
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                this.getCodeListItems(DesignCodeList.AnchorTypes, PropertyMetaData.PostInstalledAnchor_CW_Type.id),
                DesignCodeList.AnchorTypes)
        );
    }

    public loadPostInstallAnchorSize() {
        this.postInstallAnchorSize = this.createDropdownComponent(
            'product-dropdown-post-install-anchor-size-dropdown',
            'Agito.Hilti.Profis3.InfoSection.AnchorSection.Size',
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                this.getCodeListItems(DesignCodeList.AnchorSizes, PropertyMetaData.PostInstalledAnchor_CW_Size.id),
                DesignCodeList.AnchorSizes)
        );
    }

    public loadPostInstallEmbedmentDepthTypeToggleButton() {
        const codeList = this.getCodeListItems(DesignCodeList.EmbedmentOptimizationTypes, PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthOptimizationType.id);
        const controlDisabled = this.design?.properties?.get(PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthOptimizationType.id).disabled;
        const toggleButtonGroupItems: ToggleButtonGroupItem[] = [];

        for (const codeListItem of codeList) {
            toggleButtonGroupItems.push({
                id: `product-dropdown-anchor-detailed-embedment-depth-type-${codeListItem.id}`,
                value: codeListItem.id,
                text: '',
                image: codeListItem.image != null && codeListItem.image != '' ? getSpriteAsIconStyle(codeListItem.image as Sprite) : {},
                disabled: controlDisabled
            });
        }

        this.embedmentDepthTypeToggleButtonGroup = {
            items: toggleButtonGroupItems
        };
    }

    public loadPostInstallEmbedmentDepth() {
        this.embedmentDepthValueDropdown = this.createDropdownComponent(
            'product-dropdown-post-install-embedment-depth-dropdown',
            'Agito.Hilti.Profis3.InfoSection.AnchorSection.EmbedmentDepth',
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                this.getCodeListItems(DesignCodeList.AnchorEmbedmentDepths, PropertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthFixedMultiple.id),
                DesignCodeList.AnchorEmbedmentDepths)
        );
    }

    public getViewApprovalButtonText() {
        return this.translate(this.isPostInstallAnchorProduct() ? getPostInstallApprovalKey(this.design) : 'Agito.Hilti.CW.ViewApproval');
    }

    public openApproval() {
        openApprovalLinkOrModalDialog(this.design, this.modalService, this.offlineService);
    }

    public isPostInstalledAnchor(productId: number) {
        return productId < 0;
    }

    public displayComponentSection() {
        if (this.isPostInstallAnchorProduct()) {
            return this.design.anchorFamily != undefined;
        }
        else {
            return this.design.anchorChannelFamilyGroup != undefined;
        }
    }

    private numberOfFiltersPerGroup(productFilters: AnchorFilterEntity[] | ProductFilterDisplay[]) {
        return (productFilters as any[])
            .reduce((obj: IFiltersGroupNumber, a: AnchorFilterEntity | ProductFilterDisplay) =>
                ({ ...obj, [a.anchorFilterGroupId as number]: obj[a.anchorFilterGroupId as number] == null ? 1 : obj[a.anchorFilterGroupId as number] + 1 }),
                {} as IFiltersGroupNumber
            );
    }

    private resizeInfoSection(recalculateHeight: boolean) {
        this.calculateProductsMinHeight();

        if (!this.collapsed) {
            this.setInfoSectionHeight(recalculateHeight);
        }
    }

    private calculateProductsMinHeight() {
        if (this.products && this.products.length > 0) {
            this.calculateProductsElementHeights();

            const productLists = (this.elementRef.nativeElement.shadowRoot?.querySelector('.product-dropdown-anchor-detailed-anchors') as HTMLElement);
            const productListChildren = productLists?.children ?? ([] as HTMLElement[]);
            if (this.products.length >= 1 && productLists?.style) {
                productLists.style.minHeight = Math.min(this.products.length, 2) * ((productListChildren[0] as HTMLElement).offsetHeight) + 'px';
            }
        }
    }

    private calculateProductsElementHeights() {
        const productList = this.elementRef.nativeElement.shadowRoot?.querySelectorAll('.product-dropdown-anchor-detailed-anchor') as NodeListOf<HTMLElement>;

        productList.forEach((productWrapper) => {
            const productElem = productWrapper.shadowRoot?.querySelector('.product-dropdown-select-button-container') as HTMLElement;
            const productNameElements = (productElem.getElementsByClassName('product-dropdown-detailed-name') as HTMLCollectionOf<HTMLElement>);
            if (productNameElements.length > 0) {
                productElem.style.height = (productNameElements[0].offsetHeight + 6) + 'px';
            }
        });
    }

    private setInfoSectionHeight(recalculateHeight: boolean) {
        if (recalculateHeight) {
            this.infoSectionHeight = this.calculateNeededHeight();
        }

        this.infoSectionContainer.style.height = this.infoSectionHeight + 'px';
    }

    private calculateNeededHeight(): number {
        const infoSection = this.infoSectionContainer;

        let neededHeight = 4 /* resizer handle */ + 5 /* top + bottom border + reserve */;

        // Header
        const header = (infoSection?.getElementsByClassName('box-section-header') as HTMLCollectionOf<HTMLElement>);
        if (header.length > 0) {
            neededHeight += header[0].offsetHeight;
        }

        // Filler and anchor channels html elements are skipped for height calculation
        const skippedElements = ['product-dropdown-space-filler', 'product-dropdown-anchor-detailed-anchors'];

        const infoContent = (this.elementRef.nativeElement.shadowRoot?.querySelector('#product-dropdown-content') as HTMLElement)?.children;
        if (infoContent != null) {
            // Content (without filler and products)
            for (const content of Array.from(infoContent)) {

                if (skippedElements.some(el => content.classList.contains(el))) {
                    // Skip element
                    continue;
                }

                neededHeight += (content as HTMLElement).offsetHeight;
            }

            // Products
            const productLists = (infoSection?.getElementsByClassName('product-dropdown-anchor-detailed-anchors') as HTMLCollectionOf<HTMLElement>);
            for (const productList of Array.from(productLists)) {
                if (productList.children) {
                    const products = Array.from(productList.children) as HTMLElement[];
                    products.forEach(product => neededHeight += product.offsetHeight);
                }
            }
        }

        return neededHeight;
    }

    private allowedValuesChanged() {
        if (!this.productHidden) {
            this.getSavedGroupsAndFilters();
            this.reloadInputs();
        }
    }

    private onCollapsedChanged(value: boolean) {
        const el = this.infoSectionContainer;
        if (value) {
            // clear custom height on element when closed
            el.style.height = '';
        }
        else {
            // set size to include just header ...
            el.style.height = '34px';
            setTimeout(() => {
                // and recalculate height.
                el.style.height = '';
                this.resizeInfoSection(true);
            });
        }
    }

    private createDropdownComponent<TValue>(id: string, translationKey: string, items?: DropdownItem<TValue>[], selectedValue?: TValue) {
        const dropdown: DropdownProps<TValue> = {
            id: id,
            title: translationKey ? this.translate(translationKey) : '',
            items,
            selectedValue
        };
        return dropdown;
    }

    private getCodeListItems(codeListProperty: DesignCodeList, targetProperty: number) {
        let items = this.design.designData.designCodeLists[codeListProperty];
        const allowedValues = this.design.properties.get(targetProperty).allowedValues;

        if (items != null && allowedValues != null) {
            items = items.filter(item => allowedValues.includes(item?.id ?? 0));
        }

        return items;
    }
}

