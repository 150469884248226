import { Injectable } from '@angular/core';
import { ApplicationState, SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { IFixing } from '../entities/fixing';
import { ConcreteSafetyFactorGammaC, DesignMethodGroups } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { AppSettingsHelper } from '../helpers/app-settings-helper';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { LocalizationService } from './localization.service';
import { NumberService } from './number.service';
import { UserSettingsService } from './user-settings.service';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { ProjectCodeList } from '../entities/enums/project-code-list';
import { PropertyMetaData } from '../entities/properties';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    private appSettingsHelper: AppSettingsHelper;

    constructor(
        private localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        private codeList: CodeListService,
        private commonCodeListService: CommonCodeListService,
        private numberService: NumberService
    ) {
        this.appSettingsHelper = new AppSettingsHelper(this.localizationService, this.userSettings, this.codeList, this.commonCodeListService, this.numberService);
    }

    public loadSettings() {
        const fixing: IFixing = {
            region: 0,
            length: 0,
            area: 0,
            stress: 0,
            force: 0,
            moment: 0,
            temperature: 0,
            forcePerLength: 0,
            momentPerLength: 0,
            density: 0,
            basePlateFactor: 0,
            safetyFactorPermLoad: 0,
            safetyFactorVarLoad: 0,
            minAnchorProfileDist: 0,
            minConcreteCover: 0,
            concreteSafetyFactorGammaC: 0,
            designStandardId: 0,
            designMethodGroupId: 0,
            applicationStateControl: ApplicationState.Unknown
        };

        fixing.region = this.userSettings.settings.quickStart.curtainWall.generalRegionId.value ?? SpecialRegion.Default;

        // Preset default values
        this.handleRegionChange(fixing, this.userSettings.settings.application.general.regionId.value ?? fixing.region, true);

        const concreteSafetyFactorGammaC = this.codeList.getPropertyValue(PropertyMetaData.Option_CW_ConcreteSafetyFactorGammaC.id, fixing.region);
        fixing.concreteSafetyFactorGammaC = concreteSafetyFactorGammaC?.defaultValue ?? ConcreteSafetyFactorGammaC.GeneralUse;

        // Try set actual values with default fallbacks
        fixing.length = this.appSettingsHelper.getCommonCodelistOrDefault(CommonCodeList.UnitLength, this.userSettings.settings.quickStart.curtainWall.unitsLengthId.value, fixing.length);
        fixing.area = this.appSettingsHelper.getCommonCodelistOrDefault(CommonCodeList.UnitArea, this.userSettings.settings.quickStart.curtainWall.unitsAreaId.value, fixing.area);
        fixing.stress = this.appSettingsHelper.getCommonCodelistOrDefault(CommonCodeList.UnitStress, this.userSettings.settings.quickStart.curtainWall.unitsStressId.value, fixing.stress);
        fixing.force = this.appSettingsHelper.getCommonCodelistOrDefault(CommonCodeList.UnitForce, this.userSettings.settings.quickStart.curtainWall.unitsForceId.value, fixing.force);
        fixing.moment = this.appSettingsHelper.getCommonCodelistOrDefault(CommonCodeList.UnitMoment, this.userSettings.settings.quickStart.curtainWall.unitsMomentId.value, fixing.moment);
        fixing.temperature = this.appSettingsHelper.getCommonCodelistOrDefault(CommonCodeList.UnitTemperature, this.userSettings.settings.quickStart.curtainWall.unitsTemperatureId.value, fixing.temperature);
        fixing.forcePerLength = this.appSettingsHelper.getCommonCodelistOrDefault(CommonCodeList.UnitForcePerLength, this.userSettings.settings.quickStart.curtainWall.unitsForcePerLengthId.value, fixing.forcePerLength);
        fixing.momentPerLength = this.appSettingsHelper.getCommonCodelistOrDefault(CommonCodeList.UnitMomentPerLength, this.userSettings.settings.quickStart.curtainWall.unitsMomentPerLengthId.value, fixing.momentPerLength);
        fixing.density = this.appSettingsHelper.getCommonCodelistOrDefault(CommonCodeList.UnitDensity, this.userSettings.settings.quickStart.curtainWall.unitsDensity.value, fixing.density);
        fixing.basePlateFactor = this.userSettings.settings.quickStart.curtainWall.basePlateFactor.value;
        fixing.designStandardId = this.appSettingsHelper.getProjectCodelistOrDefault(ProjectCodeList.DesignStandards, this.userSettings.settings.quickStart.curtainWall.calculationDesignStandardId.value, fixing.designStandardId);
        fixing.designMethodGroupId = this.appSettingsHelper.getDesignMethodIdOrDefault(fixing.region, fixing.designStandardId, this.userSettings.settings.quickStart.curtainWall.calculationDesignMethodGroupId.value ?? DesignMethodGroups.ACI31819);
        fixing.safetyFactorPermLoad = this.userSettings.settings.quickStart.curtainWall.safetyFactorPermLoad.value;
        fixing.safetyFactorVarLoad = this.userSettings.settings.quickStart.curtainWall.safetyFactorVarLoad.value;
        fixing.minAnchorProfileDist = this.userSettings.settings.quickStart.curtainWall.minAnchorProfileDist.value;
        fixing.minConcreteCover = this.userSettings.settings.quickStart.curtainWall.minConcreteCover.value;
        fixing.concreteSafetyFactorGammaC =  this.appSettingsHelper.getProjectCodelistOrDefault(ProjectCodeList.ConcreteSafetyFactorGammaC, this.userSettings.settings.quickStart.curtainWall.concreteSafetyFactorGammaC.value, fixing.concreteSafetyFactorGammaC);

        return fixing;
    }

    public handleRegionChange(fixing: IFixing, regionId: number, override = false) {
        if (fixing.region == SpecialRegion.Default || override) {
            const region = fixing.region < 0
                ? regionId
                : fixing.region;

            this.appSettingsHelper.setDefaultUnits(fixing, region);
            this.appSettingsHelper.setDefaultDesignStandard(fixing, region);
            this.appSettingsHelper.setDefaultDesignMethod(fixing, region);
        }
    }

    public updateSettings(fixing: IFixing) {
        const settings = this.userSettings.settings.quickStart.curtainWall;

        settings.generalRegionId.value = fixing.region ?? SpecialRegion.Default;
        settings.unitsLengthId.value = fixing.length;
        settings.unitsAreaId.value = fixing.area;
        settings.unitsStressId.value = fixing.stress;
        settings.unitsForceId.value = fixing.force;
        settings.unitsMomentId.value = fixing.moment;
        settings.unitsTemperatureId.value = fixing.temperature;
        settings.unitsForcePerLengthId.value = fixing.forcePerLength;
        settings.unitsMomentPerLengthId.value = fixing.momentPerLength;
        settings.unitsDensity.value = fixing.density;
        settings.basePlateFactor.value = fixing.basePlateFactor;
        settings.calculationDesignStandardId.value = fixing.designStandardId ?? 0;
        settings.calculationDesignMethodGroupId.value = fixing.designMethodGroupId ?? 0;
        settings.safetyFactorPermLoad.value = fixing.safetyFactorPermLoad;
        settings.safetyFactorVarLoad.value = fixing.safetyFactorVarLoad;
        settings.minAnchorProfileDist.value = fixing.minAnchorProfileDist;
        settings.minConcreteCover.value = fixing.minConcreteCover;
        settings.concreteSafetyFactorGammaC.value = fixing.concreteSafetyFactorGammaC ?? 0;
    }
}
