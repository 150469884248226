import { Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { LocalizationService } from '../../services/localization.service';
import { CodeListService } from '../../services/code-list.service';
import { getDesignTypeSpecificKey } from '../../helpers/localization-helper';
import { Validators } from '@angular/forms';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { AppSettingsHelper } from '../../helpers/app-settings-helper';
import { UserSettingsService } from '../../services/user-settings.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { NumberService } from '../../services/number.service';

export interface IMethodInputsData {
    // Context
    addEditType?: AddEditType;

    // Values
    designStandard?: number;
    designMethod?: number;
}

@Component({
    selector: 'cw-method-inputs',
    templateUrl: './method-inputs.component.html'
})
export class MethodInputsComponent implements OnInit, OnChanges, DoCheck {
    @Input()
    public collapsed!: boolean;

    @Input()
    public data!: IMethodInputsData;

    @Input()
    public selectedRegionId!: number;

    @Input()
    public disabled!: boolean;

    private dataLoaded = false;

    public projectDesignStandardDropdown!: DropdownProps<number>;
    public projectDesignMethodDropdown!: DropdownProps<number>;

    public requiredValidator = Validators.required;
    public AddEditType = AddEditType;

    private appSettingsHelper: AppSettingsHelper;

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService,
        private codeList: CodeListService,
        private commonCodeListService: CommonCodeListService,
        private numberService: NumberService
    ) {
        this.appSettingsHelper = new AppSettingsHelper(this.localization, this.userSettings, this.codeList, this.commonCodeListService, this.numberService);
    }

    ngOnInit(): void {
        this.initProjectDesignStandardDropdown();
        this.initProjectDesignMethodDropdown();
    }

    ngDoCheck() {
        if (!this.dataLoaded) {
            this.setStandardAndDesignMethod();
            this.dataLoaded = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedRegionId'] != undefined &&
            changes['selectedRegionId'].previousValue != changes['selectedRegionId'].currentValue) {
                this.onRegionChanged();
        }

        if (this.isEdit) {
            this.initProjectDesignStandardDropdown();
            this.initProjectDesignMethodDropdown();
            this.setStandardAndDesignMethod();
        }
    }

    public get isEdit() {
        return this.data.addEditType == AddEditType.edit;
    }

    private setStandardAndDesignMethod() {
        if (this.codeList.projectCodeLists != undefined)
        {
            if (this.data.designStandard) {
                this.projectDesignStandardDropdown.selectedValue = this.data.designStandard;
            }

            if (this.data.designMethod) {
                this.projectDesignMethodDropdown.selectedValue = this.data.designMethod;
            }
        }
    }

    public get designStandard() {
        if (!this.data.designStandard) {
            return undefined;
        }

        return this.codeList.getDesignStandards().find((designStandard) => designStandard.id == this.data.designStandard);
    }

    public get selectedDesignStandardDescription() {
        if (!this.designStandard) {
            return undefined;
        }

        return this.designStandard.descriptionResourceKey;
    }

    public get designMethodGroup() {
        if (!this.data.designMethod) {
            return undefined;
        }

        return this.codeList.getDesignMethodGroups().find((designMethodGroup) => designMethodGroup.id == this.data.designMethod);
    }

    public get selectedDesignMethodGroupDescription() {
        if (!this.designMethodGroup) {
            return undefined;
        }

        return this.designMethodGroup.nameResourceKey + '.Description';
    }

    private initProjectDesignStandardDropdown() {
        this.projectDesignStandardDropdown = {
            id: 'add-edit-design-project-design-standard-cw-dropdown',
            title: this.localization.getString('Agito.Profis3.AddEditDesign.Dropdown.DesignStandard.Title'),
            notSelectedText: this.localization.getString('Agito.Profis3.AddEditDesign.Dropdown.DesignStandard.Placeholder'),
            validators: [this.requiredValidator]
        };

        this.modifyProjectDesignStandardItems();
    }

    private initProjectDesignMethodDropdown() {
        this.projectDesignMethodDropdown = {
            id: 'add-edit-design-project-design-method-cw-dropdown',
            title: this.localization.getString('Agito.Profis3.AddEditDesign.Dropdown.DesignMethod.Title'),
            notSelectedText: this.localization.getString('Agito.Profis3.AddEditDesign.Dropdown.DesignMethod.Placeholder'),
            validators: [this.requiredValidator]
        };

        this.modifyProjectDesignMethodItems();
    }

    private modifyProjectDesignStandardItems() {
        this.projectDesignStandardDropdown.items = this.appSettingsHelper.getDesignStandardsForRegion(this.selectedRegionId).map(
            (designStandard) => {
                return {
                    id: `add-edit-design-project-design-standard-cw-dropdown-item-${designStandard.id}`,
                    value: designStandard.id,
                    text: this.translate(getDesignTypeSpecificKey(this.localization, designStandard.nameResourceKey ?? '')) ?? ''
                };
            }
        );

        if (this.isEdit) {
            this.projectDesignStandardDropdown.selectedValue = this.data.designStandard;
        }
    }

    private modifyProjectDesignMethodItems(): void {
        this.projectDesignMethodDropdown.items = this.appSettingsHelper.getDesignMethodGroupsForRegion(this.data.designStandard, this.selectedRegionId).map(
            (designMethodGroup) => {
                return {
                    id: `add-edit-design-project-design-method-dropdown-item-${designMethodGroup.id}`,
                    value: designMethodGroup.id,
                    text: this.translate(designMethodGroup.nameResourceKey ?? '')
                };
            }
        );

        this.onDesignMethodDropdownSelectedValueChange(this.data.designMethod);
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public onRegionChanged() {
        this.data.designStandard = this.appSettingsHelper.getDefaultDesignStandard(this.selectedRegionId).id;
        this.projectDesignStandardDropdown.selectedValue = this.data.designStandard;

        this.modifyProjectDesignStandardItems();
        this.modifyProjectDesignMethodItems();

        this.data.designMethod = this.appSettingsHelper.getDefaultDesignMethod(this.selectedRegionId, this.data.designStandard).id;
        this.projectDesignMethodDropdown.selectedValue = this.data.designMethod;
    }

    public onDesignStandardDropdownSelectedValueChange(designStandardId?: number) {
        const oldDesignStandardId = this.projectDesignStandardDropdown.selectedValue;

        if (!this.data.designStandard || designStandardId == oldDesignStandardId) {
            return;
        }

        const designStandards = this.appSettingsHelper.getDesignStandardsForRegion(this.selectedRegionId);
        const existingDesignStandard = designStandards.find((it) => {
            return it.id == designStandardId;
        });

        if (existingDesignStandard) {
            this.projectDesignStandardDropdown.selectedValue = designStandardId;
        }
        else {
            this.projectDesignStandardDropdown.selectedValue = designStandards[0].id;
        }

        this.onDesignStandardIdChange(this.projectDesignStandardDropdown.selectedValue);
        this.data.designMethod = this.projectDesignMethodDropdown.selectedValue;

        this.setDefaultDesignMethodGroup();
        this.modifyProjectDesignMethodItems();
    }

    private setDefaultDesignMethodGroup() {
        const defaultDesignMethod = this.appSettingsHelper.getDefaultDesignMethod(this.selectedRegionId, this.data.designStandard ?? 0);
        this.data.designMethod = defaultDesignMethod?.id;
        this.projectDesignMethodDropdown.selectedValue = this.data.designMethod;
    }

    private onDesignStandardIdChange(designStandardId: number | undefined) {
        const oldDesignStandardId = this.data.designStandard;
        this.data.designStandard = designStandardId;

        if (designStandardId !== oldDesignStandardId && designStandardId) {
            this.setDefaultDesignMethodGroup();
        }
    }

    public onDesignMethodDropdownSelectedValueChange(designMethodGroupId: number | undefined) {
        const oldDesignMethodGroupId = this.projectDesignMethodDropdown.selectedValue;

        if (!designMethodGroupId || designMethodGroupId == oldDesignMethodGroupId) {
            return;
        }

        this.setProjectDesignMethod(designMethodGroupId);
    }

    private setProjectDesignMethod(methodGroupId: number) {
        const designMethodGroups = this.appSettingsHelper.getDesignMethodGroupsForRegion(this.data.designStandard, this.selectedRegionId);

        const designMethod = designMethodGroups.find((it) => {
            return it.id == methodGroupId;
        });

        if (designMethod) {
            this.projectDesignMethodDropdown.selectedValue = designMethod.id;
        }
        else {
            this.projectDesignMethodDropdown.selectedValue = designMethodGroups[0]?.id;
        }

        this.data.designMethod = this.projectDesignMethodDropdown.selectedValue;
    }
}
