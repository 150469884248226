import { ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { RadioButtonProps } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { IAppSettingsComponent } from '@profis-engineering/pe-ui-common/entities/app-settings-component';
import { ApplicationState, SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { format, formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { getCodeListTextDeps } from '../../entities/code-list';
import { IFixing } from '../../entities/fixing';
import { PropertyMetaData } from '../../entities/properties';
import { AppSettingsHelper } from '../../helpers/app-settings-helper';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { ProjectCodeList } from '../../entities/enums/project-code-list';
import { ConcreteSafetyFactorGammaC } from '../../entities/code-lists/concrete-safety-factor-gamma-c';
import { DesignStandardHelper } from '../../helpers/design-standard-helper';

@Component({
    templateUrl: './app-settings-fixing.component.html',
    styleUrls: ['./app-settings-fixing.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AppSettingsFixingComponent implements IAppSettingsComponent<IFixing>, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public decimalSeparatorId!: number;

    @Input()
    public thousandsSeparatorId!: number;

    @Input()
    public submitted = false;

    @Input()
    public settings: IFixing = {
        region: 0,
        length: 0,
        area: 0,
        stress: 0,
        force: 0,
        moment: 0,
        temperature: 0,
        forcePerLength: 0,
        momentPerLength: 0,
        density: 0,
        basePlateFactor: 0,
        safetyFactorPermLoad: 0,
        safetyFactorVarLoad: 0,
        minAnchorProfileDist: 0,
        minConcreteCover: 0,
        concreteSafetyFactorGammaC: 0,
        designStandardId: 0,
        designMethodGroupId: 0,
        applicationStateControl: ApplicationState.Unknown
    };

    public get fixing() {
        return this.settings;
    }

    public applicationStateControl!: RadioButtonProps<ApplicationState>;
    public regionDropdown!: DropdownProps<number>;
    public lengthDropdown!: DropdownProps<number>;
    public areaDropdown!: DropdownProps<number>;
    public stressDropdown!: DropdownProps<number>;
    public forceDropdown!: DropdownProps<number>;
    public momentDropdown!: DropdownProps<number>;
    public temperatureDropdown!: DropdownProps<number>;
    public forcePerLengthDropdown!: DropdownProps<number>;
    public momentPerLengthDropdown!: DropdownProps<number>;
    public densityDropdown!: DropdownProps<number>;
    public concreteSafetyFactorGammaC!: DropdownProps<number>;
    public basePlateFactor!: NumericTextBoxProps;
    public safetyFactorPermLoad!: NumericTextBoxProps;
    public safetyFactorVarLoad!: NumericTextBoxProps;
    public minAnchorProfileDist!: NumericTextBoxProps;
    public minConcreteCover!: NumericTextBoxProps;
    public designStandardDropdown!: DropdownProps<number>;
    public designMethodDropdown!: DropdownProps<number>;
    public dataLoaded = false;

    private appSettingsHelper: AppSettingsHelper;

    constructor(
        private userSettings: UserSettingsService,
        private localizationService: LocalizationService,
        protected codeList: CodeListService,
        private commonCodeListService: CommonCodeListService,
        private numberService: NumberService,
        private unit: UnitService,
        private changeDetection: ChangeDetectorRef
    ) {
        this.appSettingsHelper = new AppSettingsHelper(this.localizationService, this.userSettings, this.codeList, this.commonCodeListService, this.numberService);
        this.createControlsRegion();
    }

    ngOnChanges(): void {
        if (!this.dataLoaded) {
            this.createControlsGeneral();
            this.modifyProjectDesignStandardItems();
            this.modifyProjectDesignMethodItems();

            this.dataLoaded = true;
        }
    }

    public get checkDefaultRegion() {
        return this.fixing.region == SpecialRegion.Default
            ? this.nonDefaultRegion ?? 0
            : this.fixing.region;
    }

    public get allowQuickStart(): boolean {
        return this.fixing.region != SpecialRegion.None && this.appSettingsHelper.isRegionAllowed(this.nonDefaultRegion);
    }

    public get nonDefaultRegion() {
        return this.fixing.region < 0 ? this.selectedRegionId : this.fixing.region;
    }

    public get allowStatus() {
        let translation = '';

        if (this.regionSettingsStatusOk) {
            translation = this.translate('Agito.Hilti.CW.ApplicationSettings.Facade.Status.Ok');
            translation = format(translation, this.translate('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.None'));
        }
        else {
            const region = this.appSettingsHelper.getRegionById(this.nonDefaultRegion);
            const regionName = region.getTranslatedNameText(getCodeListTextDeps(this.localizationService, this.numberService)) ?? '';

            translation = this.translate('Agito.Hilti.CW.ApplicationSettings.Facade.Status.Error');
            translation = formatKeyValue(translation, { region: regionName });
        }

        return translation;
    }

    public get regionSettingsStatusOk(): boolean {
        return this.fixing.region == SpecialRegion.None || this.appSettingsHelper.isRegionAllowed(this.nonDefaultRegion);
    }

    public get designSettingsTitle(): string {
        return 'Agito.Hilti.CW.ApplicationSettings.DesignStandardAndMethod';
    }

    public get designStandardDescription(): string {
        if (!this.fixing.designStandardId) {
            return '';
        }

        const selectedStandard = this.appSettingsHelper.getDesignStandardById(this.fixing.designStandardId);
        return 'Agito.Hilti.CW.ApplicationSettings.DesignStandard.Description.' + selectedStandard?.displayKey;
    }

    public get designMethodDescription(): string {
        if (!this.fixing.designMethodGroupId) {
            return '';
        }

        const selectedMethodGroup = this.appSettingsHelper.getDesignMethodById(this.fixing.designMethodGroupId);
        return 'Agito.Hilti.CW.ApplicationSettings.DesignMethod.Description.' + selectedMethodGroup?.displayKey;
    }

    public get isEuropeanDesignStandard() {
        return DesignStandardHelper.isEnBasedDesignStandard(this.fixing.designStandardId);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public displayRegionData(regionId: number) {
        return regionId != SpecialRegion.None && this.appSettingsHelper.isRegionAllowed(regionId);
    }

    public onDesignStandardDropdownSelectedValueChange(designStandardId?: number) {
        const oldDesignStandardId = this.designStandardDropdown.selectedValue;

        if (!this.fixing.designStandardId || designStandardId == oldDesignStandardId) {
            return;
        }

        const designStandards = this.appSettingsHelper.getDesignStandardsForRegion(this.fixing.region);

        const existingDesignStandard = designStandards.find((it) => {
            return it.id == designStandardId;
        });

        if (existingDesignStandard) {
            this.designStandardDropdown.selectedValue = designStandardId;
        }
        else {
            this.designStandardDropdown.selectedValue = designStandards[0].id;
        }

        this.onDesignStandardIdChange(this.designStandardDropdown.selectedValue ?? 0);
        this.fixing.designMethodGroupId = this.designMethodDropdown.selectedValue;

        this.appSettingsHelper.setDefaultDesignMethod(this.fixing, this.fixing.region);
        this.modifyProjectDesignMethodItems();
    }

    private modifyProjectDesignStandardItems(regionId?: number) {
        this.designStandardDropdown.items = this.appSettingsHelper.getDesignStandardsForRegion(regionId ?? this.fixing.region).map(
            (designStandard) => {
                return {
                    id: `add-edit-design-project-design-standard-cw-dropdown-item-${designStandard.id}`,
                    value: designStandard.id,
                    text: this.translate(designStandard.nameResourceKey ?? '')
                };
            }
        );
    }

    private modifyProjectDesignMethodItems(regionId?: number): void {
        this.designMethodDropdown.items = this.appSettingsHelper.getDesignMethodGroupsForRegion(this.fixing.designStandardId, regionId ?? this.fixing.region).map(
            (designMethodGroup) => {
                return {
                    id: `add-edit-design-project-design-method-dropdown-item-${designMethodGroup.id}`,
                    value: designMethodGroup.id,
                    text: this.translate(designMethodGroup.nameResourceKey ?? '')
                };
            }
        );

    }

    private onDesignStandardIdChange(designStandardId: number) {
        const oldDesignStandardId = this.fixing.designStandardId;
        this.fixing.designStandardId = designStandardId;

        if (designStandardId !== oldDesignStandardId && designStandardId) {
            this.appSettingsHelper.setDefaultDesignMethod(this.fixing, this.fixing.region);
        }
    }

    public onDesignMethodChange(designMethodId: number | undefined) {
        this.fixing.designMethodGroupId = designMethodId ?? 0;
    }

    public onRegionDropdownChange(regionId: number) {
        this.fixing.region = regionId;
        this.updateQuickStart(regionId, true);
    }

    public get getBasePlateFactorPrecision() {
        return this.unit.getPrecision(Unit.None, PropertyMetaData.Option_CW_BasePlateFactor.id);
    }

    private updateQuickStart(regionId: number, regionChanged: boolean) {
        if (regionId !== SpecialRegion.None && regionChanged) {
            regionId = regionId < 0 ? this.selectedRegionId ?? 0 : regionId;

            this.appSettingsHelper.setDefaultUnits(this.fixing, regionId);

            this.modifyProjectDesignStandardItems(regionId);

            this.appSettingsHelper.setDefaultDesignStandard(this.fixing, regionId);
            this.designStandardDropdown.selectedValue = this.fixing.designStandardId;

            this.modifyProjectDesignMethodItems(regionId);

            this.appSettingsHelper.setDefaultDesignMethod(this.fixing, regionId);
            this.designMethodDropdown.selectedValue = this.fixing.designMethodGroupId;
        }

        this.changeDetection.detectChanges();
    }

    private createControlsRegion() {
        this.regionDropdown = this.appSettingsHelper.createRegionDropdown('cw-region', 'Region');
    }

    private createControlsGeneral() {
        this.lengthDropdown = this.appSettingsHelper.createUnitDropdown('cw-length', 'Length', CommonCodeList.UnitLength);
        this.areaDropdown = this.appSettingsHelper.createUnitDropdown('cw-area', 'Area', CommonCodeList.UnitArea);
        this.stressDropdown = this.appSettingsHelper.createUnitDropdown('cw-stress', 'Stress', CommonCodeList.UnitStress);
        this.forceDropdown = this.appSettingsHelper.createUnitDropdown('cw-force', 'Force', CommonCodeList.UnitForce);
        this.momentDropdown = this.appSettingsHelper.createUnitDropdown('cw-moment', 'Moment', CommonCodeList.UnitMoment);
        this.temperatureDropdown = this.appSettingsHelper.createUnitDropdown('cw-temperature', 'Temperature', CommonCodeList.UnitTemperature);
        this.densityDropdown = this.appSettingsHelper.createUnitDropdown('cw-density-length', 'Density', CommonCodeList.UnitDensity);
        this.forcePerLengthDropdown = this.appSettingsHelper.createUnitDropdown('cw-force-per-length', 'ForcePerLength', CommonCodeList.UnitForcePerLength);
        this.momentPerLengthDropdown = this.appSettingsHelper.createUnitDropdown('cw-moment-per-length', 'MomentPerLength', CommonCodeList.UnitMomentPerLength);
        this.basePlateFactor = this.appSettingsHelper.createBaseFactorComponent('cw-base-plate-factor', 'BasePlateFactor', this.fixing);
        this.designStandardDropdown = this.appSettingsHelper.createDropdownComponent<number>('cw-design-standard-dropdown', 'DesignStandard', this.appSettingsHelper.getDesignStandardItems());
        this.designMethodDropdown = this.appSettingsHelper.createDropdownComponent<number>('cw-design-method-group-dropdown', 'DesignMethod', this.appSettingsHelper.getDesignMethodItems());
        this.safetyFactorPermLoad = this.createTextBoxComponent('cw-safety-factor-perm-load', 'SafetyFactorPermanentLoad', PropertyMetaData.Option_CW_SafetyFactorPermanentLoad.id, this.fixing.region, this.fixing.safetyFactorPermLoad);
        this.safetyFactorVarLoad = this.createTextBoxComponent('cw-safety-factor-var-load', 'SafetyFactorVariableLoad', PropertyMetaData.Option_CW_SafetyFactorVariableLoad.id, this.fixing.region, this.fixing.safetyFactorVarLoad);
        this.minAnchorProfileDist = this.createTextBoxComponent('cw-min-anchor-profile-dist', 'MinAnchorProfileDist', PropertyMetaData.Option_CW_MinAnchorProfileDist.id, this.fixing.region, this.fixing.minAnchorProfileDist, this.translate('Agito.Hilti.CW.ApplicationSettings.MinAnchorProfileDist.Placeholder'));
        this.minConcreteCover = this.createTextBoxComponent('cw-min-concrete-cover', 'MinConcreteCover', PropertyMetaData.Option_CW_MinConcreteCover.id, this.fixing.region, this.fixing.minConcreteCover);
        this.concreteSafetyFactorGammaC = this.appSettingsHelper.createDropdownComponent<number>('cw-concrete-safety-factor-gamma-c', 'ConcreteSafetyFactorGammaC', this.createSafetyFactorsDropdownItems());
    }

    private createSafetyFactorsDropdownItems() {
        const codeListItems = this.codeList.projectCodeLists[ProjectCodeList.ConcreteSafetyFactorGammaC] as ConcreteSafetyFactorGammaC[];
        return codeListItems?.map(p => ({
            id: `app-settings-cw-factor-gamma-c-dropdown-item-${p.id}`,
            value: p.id,
            text: p.nameResourceKey != null ? this.translate(p.nameResourceKey) : ''
        }) as DropdownItem<number>);
    }

    public createTextBoxComponent(id: string, translationKey: string, uiPropId: number, regionId: number, value: number | null, placeholder?: number | string) {
        const propertyValue = this.codeList.getPropertyValue(uiPropId, regionId);

        return this.appSettingsHelper.createNumericTextBox(
            `application-settings-${id}`,
            translationKey,
            value ?? undefined,
            propertyValue?.minValue ?? 0,
            propertyValue?.maxValue ?? 0,
            placeholder ?? propertyValue?.defaultValue ?? 0,
            false,
            this.unit.getPrecision(Unit.None, uiPropId)
        );
    }
}
