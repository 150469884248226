<pe-modal-header [title]="title" [modalInstance]="modalInstance">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <pe-modal-body>
        <div class="section">
            <div class="section-row">
                <div class="section-column">
                    <pe-numeric-text-box [id]="yieldStrength.id" [title]="yieldStrength.title"
                        [value]="yieldStrength.value" (valueChange)="yieldStrength.value = $any($event).detail"
                        [unit]="yieldStrength.unit" [minValue]="yieldStrength.minValue"
                        [maxValue]="yieldStrength.maxValue" [updateOnBlur]="true" [required]="true"
                        [disabled]="submitted" [validators]="[requiredValidator]"
                        (isValid)="yieldStrength.isValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>
                <div class="section-column" >
                    <pe-numeric-text-box *ngIf="isHiddenProperty(propertyMetaData.AnchorReinforcement_CW_ConcreteCover.id)"
                        [id]="concreteCover.id" [title]="concreteCover.title"
                        [value]="concreteCover.value" (valueChange)="concreteCover.value = $any($event).detail"
                        [unit]="concreteCover.unit" [minValue]="concreteCover.minValue"
                        [maxValue]="concreteCover.maxValue" [updateOnBlur]="true" [required]="true"
                        [disabled]="submitted" [validators]="[requiredValidator]"
                        (isValid)="concreteCover.isValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>
            </div>

            <div class="section-row section-padding">
                <div class="section-column separator row-container">
                    <pe-checkbox-button class="checkbox" [items]="tensionCheckbox.items"
                        [selectedValues]="tensionCheckbox.selectedValues"
                        (selectedValuesChange)="tensionCheckbox.selectedValues = $any($event).detail;"
                        [disabled]="submitted || tensionCheckbox.disabled">
                    </pe-checkbox-button>

                    <div class="anchor-reinforcement-tension-image align-center">
                        <img class="col-50" src="{{images.tension1Image}}" alt="Reinforcement for tension loads" [class.disabled]="tensionCheckbox.disabled">
                    </div>

                    <div class="anchor-reinforcement-tension-image align-center">
                        <img src="{{images.tension2Image}}" alt="Reinforcement for tension loads" [class.disabled]="tensionCheckbox.disabled">
                    </div>

                    <div class="buttons-container">
                        <pe-dropdown [id]="tensionDiameter.id" [title]="tensionDiameter.title"
                            [items]="tensionDiameter.items" [disabled]="submitted || tensionDiameter.disabled"
                            [selectedValue]="tensionDiameter.selectedValue"
                            (selectedValueChange)="tensionDiameter.selectedValue = $any($event).detail;">
                        </pe-dropdown>

                        <div *ngIf="isHiddenProperty(propertyMetaData.AnchorReinforcement_CW_Tension_BondCondition.id)">
                            <pe-dropdown [id]="tensionBondCondition.id" [title]="tensionBondCondition.title" [items]="tensionBondCondition.items"
                                [disabled]="submitted || tensionBondCondition.disabled"
                                [selectedValue]="tensionBondCondition.selectedValue"
                                (selectedValueChange)="tensionBondCondition.selectedValue = $any($event).detail;">
                            </pe-dropdown>
                        </div>

                        <div *ngIf="isHiddenProperty(propertyMetaData.AnchorReinforcement_CW_Tension_ConcreteCover.id)">
                            <pe-numeric-text-box [id]="tensionConcreteCover.id" [title]="tensionConcreteCover.title" [value]="tensionConcreteCover.value" (valueChange)="tensionConcreteCover.value = $any($event).detail"
                                [unit]="tensionConcreteCover.unit" [minValue]="tensionConcreteCover.minValue"
                                [maxValue]="tensionConcreteCover.maxValue" [updateOnBlur]="true" [required]="true"
                                [disabled]="submitted" [validators]="[requiredValidator]"
                                (isValid)="tensionConcreteCover.isValid = $any($event).detail"
                                [disabled]="submitted || isDisabledProperty(propertyMetaData.AnchorReinforcement_CW_Tension_ConcreteCover.id)">
                            </pe-numeric-text-box>
                        </div>
                    </div>
                </div>

                <div class="section-column separator row-container">
                    <pe-checkbox-button class="checkbox" [items]="shearCheckbox.items"
                        [selectedValues]="shearCheckbox.selectedValues"
                        (selectedValuesChange)="shearCheckbox.selectedValues = $any($event).detail;"
                        [disabled]="submitted || shearCheckbox.disabled">
                    </pe-checkbox-button>

                    <div class="anchor-reinforcement-shear-image align-center">
                        <img src="{{images.shearImage}}" alt="Reinforcement for perpendicular shear" [class.disabled]="shearCheckbox.disabled">
                    </div>

                    <div class="buttons-container">
                        <pe-dropdown [id]="shearDiameter.id" [title]="shearDiameter.title" [items]="shearDiameter.items"
                            [disabled]="submitted || shearDiameter.disabled" [selectedValue]="shearDiameter.selectedValue"
                            (selectedValueChange)="shearDiameter.selectedValue = $any($event).detail;">
                        </pe-dropdown>

                        <div *ngIf="isHiddenProperty(propertyMetaData.AnchorReinforcement_CW_Shear_BondCondition.id)">
                            <pe-dropdown [id]="shearBondCondition.id" [title]="shearBondCondition.title" [items]="shearBondCondition.items"
                                [disabled]="submitted || shearBondCondition.disabled" [selectedValue]="shearBondCondition.selectedValue"
                                (selectedValueChange)="shearBondCondition.selectedValue = $any($event).detail;">
                            </pe-dropdown>
                        </div>

                        <div *ngIf="isHiddenProperty(propertyMetaData.AnchorReinforcement_CW_Shear_ConcreteCover.id)">
                            <pe-numeric-text-box [id]="shearConcreteCover.id" [title]="shearConcreteCover.title"
                                [value]="shearConcreteCover.value" (valueChange)="shearConcreteCover.value = $any($event).detail"
                                [unit]="shearConcreteCover.unit" [minValue]="shearConcreteCover.minValue"
                                [maxValue]="shearConcreteCover.maxValue" [updateOnBlur]="true" [required]="true" [disabled]="submitted"
                                [validators]="[requiredValidator]" (isValid)="shearConcreteCover.isValid = $any($event).detail"
                                [disabled]="submitted || isDisabledProperty(propertyMetaData.AnchorReinforcement_CW_Shear_ConcreteCover.id)">
                            </pe-numeric-text-box>
                        </div>
                    </div>
                </div>

                <div class="section-column row-container">
                    <pe-checkbox-button class="checkbox" [items]="longitudinalCheckbox.items"
                        [selectedValues]="longitudinalCheckbox.selectedValues"
                        (selectedValuesChange)="longitudinalCheckbox.selectedValues = $any($event).detail;"
                        [disabled]="submitted || longitudinalCheckbox.disabled">
                    </pe-checkbox-button>

                    <div class="anchor-reinforcement-longitudinal-image align-center">
                        <img src="{{images.longitudinalImage}}" alt="Reinforcement for longitudinal shear" [class.disabled]="longitudinalCheckbox.disabled">
                    </div>

                    <div class="buttons-container">
                        <pe-dropdown [id]="longitudinalDiameter.id" [title]="longitudinalDiameter.title"
                            [items]="longitudinalDiameter.items" [disabled]="submitted || longitudinalDiameter.disabled"
                            [selectedValue]="longitudinalDiameter.selectedValue"
                            (selectedValueChange)="longitudinalDiameter.selectedValue = $any($event).detail;">
                        </pe-dropdown>

                        <div *ngIf="isHiddenProperty(propertyMetaData.AnchorReinforcement_CW_Longitudinal_BondCondition.id)">
                            <pe-dropdown [id]="longitudinalBondCondition.id" [title]="longitudinalBondCondition.title"
                                [items]="longitudinalBondCondition.items" [disabled]="submitted || longitudinalBondCondition.disabled"
                                [selectedValue]="longitudinalBondCondition.selectedValue"
                                (selectedValueChange)="longitudinalBondCondition.selectedValue = $any($event).detail;">
                            </pe-dropdown>
                        </div>

                        <div *ngIf="isHiddenProperty(propertyMetaData.AnchorReinforcement_CW_Longitudinal_ConcreteCover.id)">
                            <pe-numeric-text-box [id]="longitudinalConcreteCover.id" [title]="longitudinalConcreteCover.title"
                                [value]="longitudinalConcreteCover.value" (valueChange)="longitudinalConcreteCover.value = $any($event).detail"
                                [unit]="longitudinalConcreteCover.unit" [minValue]="longitudinalConcreteCover.minValue"
                                [maxValue]="longitudinalConcreteCover.maxValue" [updateOnBlur]="true" [required]="true" [disabled]="submitted"
                                [validators]="[requiredValidator]" (isValid)="longitudinalConcreteCover.isValid = $any($event).detail"
                                [disabled]="submitted || isDisabledProperty(propertyMetaData.AnchorReinforcement_CW_Longitudinal_ConcreteCover.id)">
                            </pe-numeric-text-box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button id="cw-anchor-reinforcement-cancel-button" look="Default" class="modal-button"
            (buttonClick)="close()" [text]="translate('Agito.Hilti.CW.BaseplateSize.Cancel')" [disabled]="submitted">
        </pe-button>

        <pe-button id="cw-anchor-reinforcement-save-button" look="Cta" class="modal-button" type="submit"
            [disabled]="!formValid || submitted">
            <pe-loading-button [loading]="submitted" [text]="translate('Agito.Hilti.CW.BaseplateSize.Save')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>