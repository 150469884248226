import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    TrackDesignChangeRequest, TrackDesignCloseRequest, TrackOnOpenRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Entities';
import { TrackingDesignType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Enums';
import { UserDetails } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Tracking';
import { getJSONDateWithTimezoneOffset } from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { environment } from '../../environments/environmentCW';
import { DesignType } from '../entities/enums/design-type';
import { DesignEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { UtilizationItemEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.DesignReportData';
import { DesignStandards } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { ProjectOpenType, TrackOnProjectCloseRequest, TrackOnProjectOpenRequest, UsageCounter } from '../entities/tracking-entities';
import { DesignHelper } from '../helpers/design-helper';
import { ApiService } from './api.service';
import { CommonCodeListService } from './common-code-list.service';
import { CommonTrackingService } from './common-tracking.service';
import { LocalizationService } from './localization.service';
import { NumberService } from './number.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class TrackingService {

    constructor(
        private apiService: ApiService,
        private localizationService: LocalizationService,
        private commonTrackingService: CommonTrackingService,
        private commonCodeListService: CommonCodeListService,
        private sharedEnvironmentService: SharedEnvironmentService,
        private numberService: NumberService,
        private userService: UserService
    ) { }

    private get peTrackingServiceUrl() {
        return this.sharedEnvironmentService.data.peTrackingServiceUrl;
    }

    public trackOnDesignOpen(
        designId: string,
        designStandard: DesignStandards
    ): Promise<void> {
        const url = `${this.peTrackingServiceUrl}Tracking/TrackOnProjectDesignOpen`;
        return this.trackOnOpenInternal(url, designId, undefined, designStandard);
    }

    public trackOnDesignChange(
        designId: string,
        projectDesign: DesignEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        createdFromTemplate: boolean,
        utilizations: UtilizationItemEntity[],
        displayNames: { [key: string]: string },
        templateId?: string
    ): Promise<void> {

        //TODO CW Team: seperate template tracking from design tracking.
        const endpoint = designId ? 'TrackOnProjectDesignChange' : 'TrackOnProjectTemplateChange';
        const url = `${this.peTrackingServiceUrl}Tracking/${endpoint}`;

        const trackingData = this.getDetailedTrackingData(designId, projectDesign, usageCounter, projectOpenType, createdFromTemplate, utilizations, displayNames, templateId);

        const data: TrackDesignChangeRequest = {
            DesignType: TrackingDesignType.CurtainWall,
            TrackingData: trackingData,
            DesignId: designId
        };

        return this.sendRequest(url, data);
    }

    public trackOnDesignClose(
        designId: string,
        projectDesign: DesignEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        createdFromTemplate: boolean,
        utilizations: UtilizationItemEntity[],
        displayNames: { [key: string]: string },
        templateId?: string,
        isBrowserUnloadEvent?: boolean
    ): Promise<void> {
        const url = `${this.peTrackingServiceUrl}Tracking/TrackOnProjectDesignClose`;
        return this.trackOnCloseInternal(url, designId, templateId, projectDesign, usageCounter, projectOpenType, createdFromTemplate, utilizations, displayNames, isBrowserUnloadEvent);
    }

    public trackOnTemplateOpen(
        templateId: string,
        designStandard: DesignStandards
    ): Promise<void> {
        const url = `${this.peTrackingServiceUrl}Tracking/TrackOnProjectTemplateOpen`;
        return this.trackOnOpenInternal(url, undefined, templateId, designStandard);
    }

    public trackOnTemplateClose(
        templateId: string,
        projectDesign: DesignEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        createdFromTemplate: boolean
    ): Promise<void> {
        const url = `${this.peTrackingServiceUrl}Tracking/TrackOnProjectTemplateClose`;
        return this.trackOnCloseInternal(url, undefined, templateId, projectDesign, usageCounter, projectOpenType, createdFromTemplate, [], {});
    }

    private trackOnOpenInternal(
        url: string,
        designId: string | undefined,
        templateId: string | undefined,
        designStandard: DesignStandards
    ): Promise<void> {
        const trackingData = this.getUsageTrackingData(designId, templateId, designStandard);

        const data: TrackOnOpenRequest = {
            DesignType: TrackingDesignType.CurtainWall,
            TrackingData: trackingData,
            DesignId: designId,
            TemplateId: templateId
        };

        return this.sendRequest(url, data);
    }

    private getUsageTrackingData(
        designId: string | undefined,
        templateId: string | undefined,
        designStandard: DesignStandards
    ): TrackOnProjectOpenRequest {

        const date = getJSONDateWithTimezoneOffset();
        const userDetails = this.getUserDetails(date.timezoneOffset);

        return {
            designId,
            templateId,
            designStandard,
            designType: DesignType.CurtainWall,
            timestamp: date.timestamp as any,
            userDetails,
            uiVersion: environment.moduleVersion,
        };
    }

    private trackOnCloseInternal(
        url: string,
        designId: string | undefined,
        templateId: string | undefined,
        projectDesign: DesignEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        createdFromTemplate: boolean,
        utilizations: UtilizationItemEntity[],
        displayNames: { [key: string]: string },
        isBrowserUnloadEvent?: boolean
    ) {
        const trackingData = this.getDetailedTrackingData(designId, projectDesign, usageCounter, projectOpenType, createdFromTemplate, utilizations, displayNames, templateId);

        const data: TrackDesignCloseRequest = {
            DesignType: TrackingDesignType.CurtainWall,
            TrackingData: trackingData,
            DesignId: designId,
            TemplateId: templateId
        };

        return this.sendRequest(url, data, isBrowserUnloadEvent);
    }

    private getDetailedTrackingData(
        designId: string | undefined,
        projectDesign: DesignEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        createdFromTemplate: boolean,
        utilizations: UtilizationItemEntity[],
        displayNames: { [key: string]: string },
        templateId?: string
    ): TrackOnProjectCloseRequest {

        const date = getJSONDateWithTimezoneOffset();
        const userDetails = this.getUserDetails(date.timezoneOffset);
        const regionName = this.commonCodeListService.getRegionById(projectDesign.options.regionId)?.displayKey ?? projectDesign.options.regionId.toString();

        return {
            designId,
            design: DesignHelper.simplifyDesign(projectDesign),
            language: this.localizationService.selectedLanguage,
            region: regionName,
            usageCounter,
            projectOpenType: projectOpenType,
            timestamp: date.timestamp as any,
            userDetails,
            createdFromTemplate,
            templateId,
            uiVersion: environment.moduleVersion,
            utilizations: utilizations,
            displayNames: displayNames
        };
    }

    private getUserDetails(timezoneOffset: number): UserDetails {
        const trackingUserData = this.commonTrackingService.getTrackingUserData(timezoneOffset);

        return {
            BrowserType: trackingUserData.BrowserType,
            OperatingSystem: trackingUserData.OperatingSystem,
            TimezoneOffset: trackingUserData.TimezoneOffset,
            IPAddress: trackingUserData.UserIpAddress,
            UserName: trackingUserData.UserName,
            UserId: trackingUserData.UserId,
            DiagnosticsAgreement: trackingUserData.DiagnosticsAgreement,
            CustomerId: trackingUserData.CustomerId,
            CustomerOriginId: trackingUserData.CustomerOriginId,
            SalesOrg: trackingUserData.SalesOrg,
            CustomerType: trackingUserData.CustomerType,
            License: trackingUserData.License,
            CountryOfResidence: trackingUserData.CountryOfResidence,
            CustomerCountry: trackingUserData.CustomerCountry,
            BuyingCustomerOriginId: trackingUserData.BuyingCustomerOriginId,
            BuyingSalesOrg: trackingUserData.BuyingSalesOrg,
            BuyingCustomerType: trackingUserData.BuyingCustomerType,
            IsTrial: trackingUserData.IsTrial
        };
    }

    private async sendRequest<T>(url: string, data: T, useFallback?: boolean): Promise<void> {
        if (!environment.trackingEnabled) {
            return;
        }

        if (useFallback) {
            await this.userService.collectHeadersAndCallProjectCloseBrowserUnload(url, JSON.stringify(data));
            return;
        }

        const request = new HttpRequest<T>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }
}
