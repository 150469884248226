import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Color3, Color4 } from '@babylonjs/core/Maths/math.color';
import { Design } from '../entities/design';
import { Context3dKey } from '@profis-engineering/pe-ui-common/entities/context-3d';
import { GLModel, IModel, ViewType, IScreenShotSettings, View2dModeType, IGLModelConstructor, ICameraValues3d, LoadsVisibilityInfo, Mode2d } from '@profis-engineering/gl-model/gl-model';
import { Change, Update } from '@profis-engineering/gl-model/base-update';
import { BaseMaterial } from '../components/gl-model/components/base-material';
import { BaseComponent, ScopeCheck } from '@profis-engineering/gl-model/components/base-component';
import { IBaseComponentConstructorCW, IModelCW, IVisibilityProperties } from './base-component';
import { SceneCoordinateSystem } from '../components/gl-model/components/scene-coordinate-system';
import { LoadsManager } from '../components/gl-model/components/loads-manager';
import { UIProperty } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { EventNotifier } from '@profis-engineering/gl-model/external/event-notifier';
import { MaterialCacheCW } from './cache/material-cache';
import { MeshCacheCW } from './cache/mesh-cache';
import { BaseUpdateCtor } from './base-update';
import { ToolTipKeyCW } from './tooltip';
import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh.js';
import { GLTemp } from '@profis-engineering/gl-model/cache/gl-temp.js';
import { AnchoringSystemManager } from '../components/gl-model/components/anchoring-system-manager';
import { LocalizationService } from '../services/localization.service';

const glTemp = new GLTemp({
    matrix: 1
});

export interface GLModelBaseProps {
    continuousRender?: boolean;
    context3dKey?: Context3dKey;
    model?: IModelCW;
}

export abstract class GlModelComponent {
    abstract update(): void;
}

export interface ICamera3dPosition {
    x: number;
    y: number;
    z: number;
}

export interface ICamera3dOffset {
    x: number;
    y: number;
    z: number;
}


export interface GlModelProps extends GLModelBaseProps {
    onFontsLoaded: () => void;
    onZoom: (zoom: number) => void;
    onSelectTab: (tab: string) => void;
    onPositionsChanged: (components: Record<string, boolean>) => void;
    onDraggingSelectionChanged: (visible: boolean) => void;
}


export interface IScreenShotSettingsCW extends IScreenShotSettings<LoadsVisibilityInfo> {
    backgroundColor?: Color4;
    visibilityProperties?: IVisibilityProperties;
    cameraRotation?: ICamera3dPosition;
    offset?: ICamera3dOffset;
}

export interface IGlModelComponent {
    zoomToFit: (extraInfo: IScreenShotSettingsCW) => void;
    update: (model: IModel, replace?: boolean) => Promise<void>;
    getModel: () => IModel;
    clearSelected: () => void;
    resizeNextFrame: (which?: number, renderCount?: number) => void;
    resetCamera: () => void;
    createDesignScreenshot: (extraInfo: IScreenShotSettingsCW) => Promise<string>;
    createDesignScreenshot2D: (extraInfo: IScreenShotSettingsCW, view2dMode: View2dModeType) => Promise<string>;
    propertyValueChanged: (changes: Change[], design: Design, update: Update, model?: IModel) => void;
    resetHighlightedComponents: () => void;
    cameraZoomIn: () => void;
    cameraZoomOut: () => void;
    cameraZoom: (percentage: number) => void;
}

export interface GLModelUpdateCW {
    sceneCoordinateSystemCtor?: BaseUpdateCtor;
    baseMaterialCtor?: BaseUpdateCtor;
    anchorChannelCtor?: BaseUpdateCtor;
    plateBracketCtor?: BaseUpdateCtor;
    boltUpdaterCtor?: BaseUpdateCtor;
    loadsUpdaterCtor?: BaseUpdateCtor;
    rebarPlateCtor?: BaseUpdateCtor;
    anchorChannelLipCtor?: BaseUpdateCtor;
}

export const enum PlatePosition {
    Top = 1,
    Front = 2,
    Bottom = 4
}

export type IGLModelCWConstructor = IGLModelConstructor<IModelCW, UIProperty, EventNotifier, MaterialCacheCW, MeshCacheCW, ToolTipKeyCW, Mode2d> & {
    modelUpdate?: GLModelUpdateCW;
    textEditorChange?: () => void;
    textEditorOpen?: (uiPropertyId: number) => void;
    localizationService: LocalizationService;
};

export class GLModelCW extends GLModel<IModelCW, UIProperty, EventNotifier, MaterialCacheCW, MeshCacheCW, ToolTipKeyCW, LoadsVisibilityInfo, IScreenShotSettingsCW, Mode2d> {
    protected static readonly textScaleReport = 1;

    protected modelUpdate?: GLModelUpdateCW;

    private components: BaseComponent<IModelCW, UIProperty, EventNotifier, MaterialCacheCW, MeshCacheCW, ToolTipKeyCW, Mode2d>[] = [];

    private baseMaterial!: BaseMaterial;
    protected sceneCoordinateSystem!: SceneCoordinateSystem;

    protected plateFrontLight!: DirectionalLight;
    protected plateBackLight!: DirectionalLight;

    protected localizationService!: LocalizationService;

    constructor(ctor: IGLModelCWConstructor) {
        super(ctor);

        this.modelUpdate = ctor.modelUpdate;
        this.textEditorChange = ctor.textEditorChange;
        this.textEditorOpen = ctor.textEditorOpen;
        this.localizationService = ctor.localizationService;

        this.initialize();
    }

    /**
     * Creates a list of bounding info to be used in scaling for screenshot
     */
    protected override getBoundingBoxes(): BoundingInfo[] {
        this.ensureNotDisposed();

        const boundingInfo: BoundingInfo[] = [];

        for (const component of this.components) {
            boundingInfo.push(...component.getBoundingBoxes());
        }

        return boundingInfo;
    }

    protected override ensureScreenShotSettingsInternal(extraInfo: IScreenShotSettingsCW): Vector3 {
        let screenshotModelPosition = new Vector3(4500, 7500, -12200);

        if (extraInfo.cameraRotation !== undefined) {
            screenshotModelPosition = new Vector3(extraInfo.cameraRotation.x, extraInfo.cameraRotation.y, extraInfo.cameraRotation.z);
        }

        return screenshotModelPosition;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected override prepareVisibilityForScreenshot(_zoomed: boolean, _info: LoadsVisibilityInfo, preview: boolean, _extraInfo: IScreenShotSettingsCW): void {
        if (this.model != null && this.model.sceneCoordinateSystem != null) {
            this.model.sceneCoordinateSystem.hidden = true;
            this.model.sceneCoordinateSystem.report = true;

            if (!preview) {
                this.model.sceneCoordinateSystem.hidden = false;
            }
        }

        if (_extraInfo.visibilityProperties !== undefined) {
            this.model.visibilityProperties = _extraInfo.visibilityProperties;
        }

        if (_extraInfo.offset !== undefined) {
            this.camera3d.targetScreenOffset.x = _extraInfo.offset.x;
            this.camera3d.targetScreenOffset.y = _extraInfo.offset.y;
        }

        this.scene3d.clearColor = _extraInfo.backgroundColor ?? new Color4(0.933, 0.933, 0.933, 1);

        this.refreshComponents();
    }

    protected override propertyValueChangedInternal(changes: Change[], scopeChecks: ScopeCheck[], update: Update, _model?: IModel, ...args: unknown[]): void {
        for (const component of this.components) {
            if (component) {
                // property value changes
                component.propertyValueChanged(changes, update, ...args);

                // scope check changes
                component.scopeCheckChanged(scopeChecks, ...args);
            }
        }
    }

    protected override updateComponentsInternal(): void {
        if (this.model.view == ViewType.View3d) {
            for (const component of this.components) {
                component?.update();
            }
        }
    }

    protected override initComponentsInternal(componentCtor: IBaseComponentConstructorCW): void {
        componentCtor.setPlateLights = this.setPlateLights.bind(this);
        componentCtor.plateFrontLight = this.plateFrontLight;
        componentCtor.plateBackLight = this.plateBackLight;
        componentCtor.localizationService = this.localizationService;

        // 3d components
        this.components = [];

        this.sceneCoordinateSystem = new SceneCoordinateSystem({ ...componentCtor, updateModelCtor: this.modelUpdate?.sceneCoordinateSystemCtor });
        this.components.push(this.sceneCoordinateSystem);

        this.components.push(new AnchoringSystemManager({ ...componentCtor, modelUpdates: this.modelUpdate, setComponents: this.setComponents, id: '1' }));

        this.baseMaterial = new BaseMaterial({ ...componentCtor, updateModelCtor: this.modelUpdate?.baseMaterialCtor });
        this.components.push(this.baseMaterial);

        this.components.push(new LoadsManager({ ...componentCtor, updateModelCtor: this.modelUpdate?.loadsUpdaterCtor }));

        // set 3d components
        for (const component of this.components) {
            this.setComponents(component);
        }

        this.resetCamera();
    }

    protected override disposeComponents(): void {
        for (const component of this.components) {
            if (component != null) {
                component.dispose();
            }
        }

        this.components = [];
    }

    // overrides resetCamera3dInternal
    protected override resetCamera3dInternal(): void {
        const viewCenter = this.calculateViewCenter();
        this.camera3d.target.y = viewCenter.y; // Reposition camera by Y
    }

    // overrides calculateViewCenter
    protected override calculateViewCenter(): Vector3 {
        const box = this.baseMaterial?.getBoundingBox();

        if (box == null) {
            return Vector3.Zero();
        }

        return new Vector3(
            (box.boundingBox.maximumWorld.x + box.boundingBox.minimumWorld.x) / 2,
            box.boundingBox.maximumWorld.y,
            (box.boundingBox.maximumWorld.z + box.boundingBox.minimumWorld.z) / 2
        );
    }

    public resetHighlightedComponents(): void {
        this.refreshModel();
    }

    protected override calculateCameraValues3d(): ICameraValues3d {
        return this.getConcreteCameraValues();
    }

    protected getConcreteCameraValues(): ICameraValues3d {
        return {
            alpha: Math.PI / 8,
            beta: -Math.PI / 6,
            radius: 2500,
            betaMin: -Math.PI / 2 + 0.01,
            betaMax: -0.01,
            radiusMin: 150,
            radiusMax: 10000
        };
    }

    protected override setLights(): void {
        this.setConcreteLights();
    }

    protected setConcreteLights(): void {
        this.frontLight.direction = new Vector3(0.35, 4, 1);
        this.frontLight.specular = Color3.Black();
        this.frontLight.intensity = 0.575;

        this.backLight.direction = new Vector3(-0.35, -4, -1);
        this.backLight.specular = Color3.Black();
        this.backLight.intensity = 0.575;

        this.groundLight.direction = Vector3.Zero();
        this.groundLight.specular = Color3.Black();
        this.groundLight.diffuse = Color3.White();
        this.groundLight.groundColor = Color3.White();
        this.groundLight.intensity = 0.4;

        // Look into PE
        this.setPlateLights(PlatePosition.Top);
    }

    protected setPlateLights(platePosition: PlatePosition): void {
        this.ensureNotDisposed();

        this.plateFrontLight.direction = new Vector3(0.35, 4, 1);
        this.plateFrontLight.specular = Color3.Black();
        this.plateFrontLight.intensity = 0.575;

        this.plateBackLight.direction = new Vector3(-0.35, -4, -1);
        this.plateBackLight.specular = Color3.Black();
        this.plateBackLight.intensity = 0.575;

        switch (platePosition) {
            case PlatePosition.Front: {
                const rotationMatrix = glTemp.matrix[0];
                Matrix.RotationXToRef(-Math.PI / 2, rotationMatrix);

                Vector3.TransformCoordinatesToRef(this.plateFrontLight.direction, rotationMatrix, this.plateFrontLight.direction);
                Vector3.TransformCoordinatesToRef(this.plateBackLight.direction, rotationMatrix, this.plateBackLight.direction);

                this.plateFrontLight.intensity = 0.54;
                this.plateBackLight.intensity = 0.54;

                break;
            }

            case PlatePosition.Bottom: {
                const rotationMatrix = glTemp.matrix[0];
                Matrix.RotationXToRef(Math.PI, rotationMatrix);

                Vector3.TransformCoordinatesToRef(this.plateFrontLight.direction, rotationMatrix, this.plateFrontLight.direction);
                Vector3.TransformCoordinatesToRef(this.plateBackLight.direction, rotationMatrix, this.plateBackLight.direction);

                break;
            }
        }
    }

    protected override clearLightsArrayInternal(): void {
        this.plateFrontLight.includedOnlyMeshes = [null as unknown as AbstractMesh];
        this.plateBackLight.includedOnlyMeshes = [null as unknown as AbstractMesh];
    }

    protected override initLightsInternal(): void {
        this.plateFrontLight = this.cache.lightCache.create('PlateFrontLight', () => {
            const light = new DirectionalLight('PlateFrontLight', Vector3.Zero(), this.scene3d);
            light.includedOnlyMeshes = [undefined as unknown as AbstractMesh];

            return light;
        });

        this.plateBackLight = this.cache.lightCache.create('PlateBackLight', () => {
            const light = new DirectionalLight('PlateBackLight', Vector3.Zero(), this.scene3d);
            light.includedOnlyMeshes = [undefined as unknown as AbstractMesh];

            return light;
        });
    }

    protected override disposeLightsInternal(clearCache: boolean): void {
        if (clearCache) {
            try {
                if (this.plateFrontLight != null) {
                    this.plateFrontLight.dispose();
                }

                if (this.plateBackLight != null) {
                    this.plateBackLight.dispose();
                }
            }
            catch (error) {
                console.error(error);
            }

            this.cache.lightCache.clear('PlateFrontLight', 'PlateBackLight');
        }

        this.plateFrontLight = undefined as unknown as DirectionalLight;
        this.plateBackLight = undefined as unknown as DirectionalLight;
    }

    public clearSelected(): void {
        this.ensureNotDisposed();
        this.refreshModel().catch(error => console.error(error));
    }
}
