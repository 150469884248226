import { Injectable } from '@angular/core';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitServiceInjected } from '@profis-engineering/pe-ui-common/services/unit.common';

@Injectable({
    providedIn: 'root'
})
export class UnitService extends UnitServiceInjected {
    public isMetric(unit: UnitType): boolean {
        return !(unit == UnitType.inch || unit == UnitType.ft || unit == UnitType.mi);
    }
}