import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import {
    SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';

import { IBaseplateSizeComponentInput } from '../../entities/baseplate-size';
import { PropertyMetaData } from '../../entities/properties';
import { CalculationService } from '../../services/calculation.service';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './baseplate-size.component.html',
    styleUrls: ['./baseplate-size.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class BaseplateSizeComponent implements OnInit {
    public submitted = false;
    public pendingSave = false;

    public widthTextBox!: NumericTextBoxProps;
    public lengthTextBox!: NumericTextBoxProps;
    public thicknessTextBox!: NumericTextBoxProps;

    public requiredValidator = Validators.required;

    @Input()
    public modalInstance!: ModalInstance<IBaseplateSizeComponentInput>;

    constructor(
        private localization: LocalizationService,
        private user: UserService,
        private codeList: CodeListService,
        private calculationService: CalculationService
    ) { }

    public get formValid() {
        return this.widthTextBox.isValid
            && this.lengthTextBox.isValid
            && this.thicknessTextBox.isValid;
    }

    public get title() {
        return this.translate('Agito.Hilti.CW.BaseplateSize.Title');
    }

    private get design() {
        return this.user.design;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance?.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        let valueRange = this.getPropertyValueRange(PropertyMetaData.Plate_CW_Width.id);
        this.widthTextBox = {
            id: 'baseplate-size-cw-input-width',
            title: this.localization.getString('Agito.Hilti.CW.BasePlateSize.Width'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Plate_CW_Length.id);
        this.lengthTextBox = {
            id: 'baseplate-size-cw-input-length',
            title: this.localization.getString('Agito.Hilti.CW.BasePlateSize.Length'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Plate_CW_Thickness.id);
        this.thicknessTextBox = {
            id: 'baseplate-size-cw-input-thickness',
            title: this.localization.getString('Agito.Hilti.CW.BasePlateSize.Thickness'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        if (this.design?.basePlateSize != null) {
            this.widthTextBox.value = this.design.basePlateSize.width;
            this.lengthTextBox.value = this.design.basePlateSize.length;
            this.thicknessTextBox.value = this.design.basePlateSize.thickness;
        }
    }

    public save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid)) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.calculationService.calculateAsync(this.design, d => {
            d.model[PropertyMetaData.Plate_CW_Width.id] = this.widthTextBox.value as number;
            d.model[PropertyMetaData.Plate_CW_Length.id] = this.lengthTextBox.value as number;
            d.model[PropertyMetaData.Plate_CW_Thickness.id] = this.thicknessTextBox.value as number;
        })
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                if (this.modalInstance.input?.onSave != null) {
                    this.modalInstance.input.onSave(this.design.basePlateSize);
                }

                this.close();
            })
            .catch((err: any) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    private getPropertyValueRange(propertyId: number) {
        const baseplateSize = this.codeList.getPropertyValue(propertyId, this.design.region?.id ?? SpecialRegion.Default);

        const retVal: IValueRange = {
            min: baseplateSize.minValue,
            max: baseplateSize.maxValue
        };
        return retVal;
    }
}
