import { Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { Design } from '../../entities/design';
import { UtilizationItemEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.DesignReportData';
import { IUtilization, IUtilizationDetail, utilizations, concreteCompressionForceUtilizations } from '../../entities/utilization';
import { getProperty } from '../../helpers/object-helper';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { includeSprites } from '../../sprites';
import { CollapsingControls } from '../main/main.component';
import { UtilizationsHelper } from '../../helpers/utilizations-helper';
import { UtilizationValueTypes } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { UnitGroup } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';

export const enum UtilizationType {
    Default                  = 'default',
    ConcreteCompressionForce = 'concrete-compression-force',
}

@Component({
  templateUrl: './utilizations.component.html',
  styleUrls: ['./utilizations-base.component.scss', './utilizations.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class UtilizationsComponent implements OnInit {

    @Input()
    public id = '';

    @Input()
    public design?: Design;

    @Input()
    public type = UtilizationType.Default;

    public collapsed = false;

    public tensionOpened = false;
    public shearOpened = false;
    public combinationOpened = false;


    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-arrow-up',
            'sprite-arrow-down',
            'sprite-notification-alert-small',
            'sprite-anchor-design',
            'sprite-combination-channel-anchor',
            'sprite-combination-channel-lip',
            'sprite-combination-channel-lip-flexure',
            'sprite-combination-concrete',
            'sprite-combination-screw',
            'sprite-shear-bending-steel-screw',
            'sprite-shear-concrete-edge-para',
            'sprite-shear-concrete-edge-perp',
            'sprite-shear-concrete-pryout-para',
            'sprite-shear-concrete-pryout-perp',
            'sprite-shear-steel-anchor-para',
            'sprite-shear-steel-anchor-perp',
            'sprite-shear-steel-channel-anchor-para',
            'sprite-shear-steel-channel-anchor-perp',
            'sprite-shear-steel-channel-lip-para',
            'sprite-shear-steel-channel-lip-perp',
            'sprite-shear-steel-screw',
            'sprite-tension-concrete-blowout',
            'sprite-tension-steel-anchor',
            'sprite-tension-steel-channel-anchor',
            'sprite-tension-steel-channel-lip',
            'sprite-tension-steel-flexure',
            'sprite-tension-steel-concrete-cone',
            'sprite-tension-steel-pullout',
            'sprite-tension-steel-screw',
            'sprite-shear-steel-rebar',
            'sprite-shear-crack-width-side-sls',
            'sprite-tension-concrete-kink-splitting',
            'sprite-concrete-compression-force',
            'sprite-shear-anchorage-inside-para',
            'sprite-shear-anchorage-inside-perp',
            'sprite-shear-steel-para',
            'sprite-shear-steel-perp',
            'sprite-tension-steel',
            'sprite-tension-anchorage-inside',
            'sprite-tension-concrete-splitting',
            'sprite-shear-bending-steel-channel-lip',
            'sprite-pi-tension-steel',
            'sprite-pi-tension-breakout',
            'sprite-pi-tension-pullout',
            'sprite-pi-masonry-tension-brick-pullout',
            'sprite-pi-tension-combined',
            'sprite-pi-tension-pullout',
            'sprite-pi-tension-splitting',
            'sprite-pi-tension-blowout',
            'sprite-pi-masonry-tension-bond',
            'sprite-pi-masonry-tension-overall',
            'sprite-pi-tension-supplementary-reinforcement-steel',
            'sprite-pi-tension-supplementary-reinforcement-anchorage',
            'sprite-pi-tension-surface-reinforcement-steel',
            'sprite-pi-shear-steel',
            'sprite-pi-shear-breakout',
            'sprite-pi-shear-pryout',
            'sprite-pi-shear-concrete-bearing',
            'sprite-pi-shear-additive-concrete-bearing',
            'sprite-pi-shear-breakout',
            'sprite-pi-masonry-shear-local-brick',
            'sprite-pi-masonry-shear-brick-pullout',
            'sprite-pi-masonry-shear-bond',
            'sprite-pi-masonry-shear-overall',
            'sprite-pi-shear-supplementary-reinforcement-steel',
            'sprite-pi-shear-supplementary-reinforcement-anchorage',
            'sprite-pi-shear-surface-reinforcement-steel',
            'sprite-pi-masonry-shear-bond',
            'sprite-pi-masonry-crushing',
            'sprite-pi-combination-steel',
            'sprite-pi-combination-concrete',
        );

        this.collapsed =
            this.type == UtilizationType.Default ?
                this.userSettings.isSectionCollapsed(CollapsingControls.Utilizations) :
                this.userSettings.isSectionCollapsed(CollapsingControls.ConcreteCompressionForce);
    }

    public utilizations(): ReadonlyArray<IUtilization> {
        return this.type == UtilizationType.Default ?
            utilizations(this.design?.isAnchorChannelWithXtProductFamily) :
            concreteCompressionForceUtilizations();
    }

    public get selectedLoadCombinationId() {
        return this.design?.selectedLoadCombinationId ?? '';
    }

    public get showUtilizations() {
        return this.type == UtilizationType.Default ?
            UtilizationsHelper.showUtilizationsChecker(this.design, this.selectedLoadCombinationId) :
            UtilizationsHelper.showConcreteCompressionForceChecker(this.design, this.selectedLoadCombinationId);
    }

    public get title() {
        return this.type == UtilizationType.Default ?
            this.translate('Agito.Hilti.CW.Utilizations.AnchorDesign') :
            this.translate('Agito.Hilti.CW.Utilizations.ConcreteCompressionForces');
    }

    public get getTitleUtilizationUnit() {
        return this.type == UtilizationType.Default ? UnitGroup.Percentage : UnitGroup.None;
    }

    public get isUtilizationTypeDefault() {
        return this.type == UtilizationType.Default;
    }

    public isOfCategory(utilizationCategory: string, utilization: UtilizationItemEntity): boolean {
        const searchStrings = [utilizationCategory.toLowerCase()];
        const utilizationProperty = utilization.name.toLowerCase();

        if (utilizationCategory == 'tension') {
            searchStrings.push('tensile');
        }
        else if (utilizationCategory == 'combination') {
            searchStrings.push('interaction');
        }

        return searchStrings.some(x => utilizationProperty.includes(x));
    }

    public haveMultiItems(utilization: string) {
        if (this.type == UtilizationType.ConcreteCompressionForce)
            return false;

        const utils = UtilizationsHelper.getAllUtilizations(this.design, this.selectedLoadCombinationId);
        return utils.filter(x => this.isOfCategory(utilization, x)).length > 1;
    }

    public trackUtilizationByProperty: TrackByFunction<IUtilization> = (_: number, utilization: IUtilization) => utilization.property;
    public trackUtilizationDetailByProperty: TrackByFunction<IUtilizationDetail> = (_: number, utilizationDetail: IUtilizationDetail) => utilizationDetail.property;

    public getUtilizationDetailsFromProperty(property: string): boolean {
        return getProperty(this, property + 'Details') as boolean;
    }

    public getUtilizationMaxValueDetail(utilization: string): UtilizationItemEntity | undefined {
        return UtilizationsHelper.getUtilizationMaxValueDetail(this.design, utilization, this.selectedLoadCombinationId);
    }

    public getUtilizationMaxValue(utilization: string): number {
        const value = Number(this.getUtilizationMaxValueDetail(utilization)?.value.value);
        return Number.isNaN(value) ? 0 : value;
    }

    public getUtilizationValue(utilization: string): string | undefined {
        const result = UtilizationsHelper.getUtilization(this.design, utilization, this.selectedLoadCombinationId);
        if (result?.type == UtilizationValueTypes.NumericValue)
            return result?.value.value;
        else
            return result?.value.value == 'NaN' ? '0 %' : result?.value.percentage;
    }

    public isUtilizationValueInvalid(utilization: string): boolean {
        const result = UtilizationsHelper.getUtilization(this.design, utilization, this.selectedLoadCombinationId);
        return UtilizationsHelper.isUtilizationInvalid(result);
    }

    public get maxGeneralUtilizationInvalid() {
        return UtilizationsHelper.isGeneralUtilizationInvalid(this.design, this.selectedLoadCombinationId);
    }

    public get maxGeneralUtilizationValue() {
        return UtilizationsHelper.getGeneralMaxUtilizationValue(this.design, this.selectedLoadCombinationId);
    }

    public image(image: string) {
        if (image == null || image == '') {
            return null;
        }

        return `pe-ui-cw-sprite-${image}`;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.AnchorLoads, collapsed);
    }

    public isUtilizationOpened(utilization: string): boolean {
        switch(utilization) {
            case 'tension':
                return this.tensionOpened;
            case 'shear':
                return this.shearOpened;
            case 'combination':
                return this.combinationOpened;
            case UtilizationType.ConcreteCompressionForce:
                return true;
        }

        return false;
    }

    public changeUtilizationOpened(utilization: string) {
        switch(utilization) {
            case 'tension':
                this.tensionOpened = !this.tensionOpened;
                break;
            case 'shear':
                this.shearOpened = !this.shearOpened;
                break;
            case 'combination':
                this.combinationOpened = !this.combinationOpened;
                break;
        }
    }
}
