import { APP_BASE_HREF } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDropdownModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AddEditDesignComponent } from './components/add-edit-design/add-edit-design.component';
import {
    AppSettingsFixingComponent
} from './components/app-settings-fixing/app-settings-fixing.component';
import { BaseplateSizeComponent } from './components/baseplate-size/baseplate-size.component';
import { DesignSectionExportComponent } from './components/design-section-export/design-section-export.component';
import { DesignSectionComponent } from './components/design-section/design-section.component';
import { DisplayOptionsComponent } from './components/display-options/display-options.component';
import { GlModelComponent } from './components/gl-model/gl-model.component';
import { MainComponent } from './components/main/main.component';
import { MethodInputsComponent } from './components/method-inputs/method-inputs.component';
import { ModalGridComponent } from './components/modal-grid/modal-grid.component';
import {
    ProductDropDownItemComponent
} from './components/product-drop-down-item/product-drop-down-item.component';
import {
    ProductDropDownComponent
} from './components/product-drop-down/product-drop-down.component';
import { SelectProductComponent } from './components/select-product/select-product.component';
import { SelectBoltComponent } from './components/select-bolt/select-bolt.component';
import { UiCwInitComponent } from './components/ui-cw-init/ui-cw-init.component';
import { UnitInputsComponent } from './components/unit-inputs/unit-inputs.component';
import { L10nDirective } from './directives/l10n.directive';
import { CloseOutsideNgbDropdownDirective } from './directives/close-outside-ngb-dropdown.directive';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { LoadsComponent } from './components/loads/loads.component';
import { LoadsRowComponent } from './components/loads-row/loads-row.component';
import { ReportExportComponent } from './components/report-export/report-export.component';
import { MultipleApprovalsComponent } from './components/multiple-approvals/multiple-approvals.component';
import { UtilizationsComponent } from './components/utilizations/utilizations.component';
import { LoadForcesComponent } from './components/load-forces/load-forces.component';
import { UtilizationBarComponent } from './components/utilization-bar/utilization-bar.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { SelectVParaSolutionComponent } from './components/select-v-para-solution/select-v-para-solution.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { InfoDialogTableComponent } from './components/info-dialog-table/info-dialog-table.component';
import { InfoDialogUnorderedListComponent } from './components/info-dialog-unordered-list/info-dialog-unordered-list.component';
import { SelectAnchorReinforcementComponent } from './components/select-anchor-reinforcement/select-anchor-reinforcement.component';
import { FirestopDialogComponent } from './components/firestop-dialog/firestop-dialog.component';
import { SelectAnchorChannelLengthComponent } from './components/select-anchor-channel-length/select-anchor-channel-length.component';
import { SelectFasteningTechnologyComponent } from './components/select-fastening-technology/select-fastening-technology.component';
import { SelectPlateStandoffPopupComponent } from './components/select-plate-standoff-popup/select-plate-standoff-popup.component';
import { SelectInspectionTypePopupComponent } from './components/select-inspection-type-popup/select-inspection-type-popup.component';
import { LoadsWizardComponent } from './components/loads-wizard/loads-wizard.component';
import { LoadsImportComponent } from './components/loads-import/loads-import.component';
import { AutomaticTorquePopupComponent } from './components/automatic-torque-popup/automatic-torque-popup.component';
import { ToleranceTableComponent } from './components/tolerance-table/tolerance-table.component';

@NgModule({
    declarations: [
        L10nDirective,
        AppSettingsFixingComponent,
        AddEditDesignComponent,
        MainComponent,
        ModalGridComponent,
        UnitInputsComponent,
        MethodInputsComponent,
        ProductDropDownComponent,
        ProductDropDownItemComponent,
        MethodInputsComponent,
        GlModelComponent,
        BaseplateSizeComponent,
        DisplayOptionsComponent,
        DesignSectionComponent,
        DesignSectionExportComponent,
        SelectProductComponent,
        SelectBoltComponent,
        LoadsComponent,
        LoadsWizardComponent,
        LoadsRowComponent,
        LoadsImportComponent,
        MultipleApprovalsComponent,
        ReportExportComponent,
        UtilizationsComponent,
        LoadForcesComponent,
        UtilizationBarComponent,
        SelectVParaSolutionComponent,
        InfoDialogComponent,
        InfoDialogTableComponent,
        InfoDialogUnorderedListComponent,
        CloseOutsideNgbDropdownDirective,
        SelectAnchorReinforcementComponent,
        FirestopDialogComponent,
        SelectAnchorChannelLengthComponent,
        SelectFasteningTechnologyComponent,
        SelectPlateStandoffPopupComponent,
        SelectInspectionTypePopupComponent,
        AutomaticTorquePopupComponent,
        ToleranceTableComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        NgbTooltipModule,
        VirtualScrollerModule,
        NgbDropdownModule,
        SortablejsModule.forRoot({}),
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: '/cdn/pe-ui-cw/' }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private injector: Injector,
        ngbTooltipConfig: NgbTooltipConfig
    ) {
        this.configureTooltip(ngbTooltipConfig);

        this.registerCustomElement('ui-cw-init', UiCwInitComponent);
        this.registerCustomElement('app-settings-fixing', AppSettingsFixingComponent);
        this.registerCustomElement('add-edit-design', AddEditDesignComponent);
        this.registerCustomElement('gl-model', GlModelComponent);
        this.registerCustomElement('modal-grid', ModalGridComponent);
        this.registerCustomElement('main', MainComponent);
        this.registerCustomElement('baseplate-size', BaseplateSizeComponent);
        this.registerCustomElement('design-section-export', DesignSectionExportComponent);
        this.registerCustomElement('app-design-section', DesignSectionComponent);
        this.registerCustomElement('select-product', SelectProductComponent);
        this.registerCustomElement('select-bolt', SelectBoltComponent);
        this.registerCustomElement('loads', LoadsComponent);
        this.registerCustomElement('loads-row', LoadsRowComponent);
        this.registerCustomElement('loads-wizard', LoadsWizardComponent);
        this.registerCustomElement('loads-import', LoadsImportComponent);
        this.registerCustomElement('multiple-approvals', MultipleApprovalsComponent);
        this.registerCustomElement('report-export', ReportExportComponent);
        this.registerCustomElement('utilizations', UtilizationsComponent);
        this.registerCustomElement('load-forces', LoadForcesComponent);
        this.registerCustomElement('utilization-bar', UtilizationBarComponent);
        this.registerCustomElement('select-v-para-solution', SelectVParaSolutionComponent);
        this.registerCustomElement('info-dialog', InfoDialogComponent);
        this.registerCustomElement('info-dialog-table', InfoDialogTableComponent);
        this.registerCustomElement('info-dialog-unordered-list', InfoDialogUnorderedListComponent);
        this.registerCustomElement('select-anchor-reinforcement', SelectAnchorReinforcementComponent);
        this.registerCustomElement('firestop-dialog', FirestopDialogComponent);
        this.registerCustomElement('select-anchor-channel-length', SelectAnchorChannelLengthComponent);
        this.registerCustomElement('select-fastening-technology', SelectFasteningTechnologyComponent);
        this.registerCustomElement('select-plate-standoff-popup', SelectPlateStandoffPopupComponent);
        this.registerCustomElement('select-inspection-type-popup', SelectInspectionTypePopupComponent);
        this.registerCustomElement('automatic-torque-popup', AutomaticTorquePopupComponent);
        this.registerCustomElement('tolerance-table', ToleranceTableComponent);
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @angular-eslint/use-lifecycle-interface, @typescript-eslint/no-empty-function
    public ngDoBootstrap() {
        // Nothing to do.
    }

    private configureTooltip(config: NgbTooltipConfig) {
        config.container = 'body';
        config.triggers = 'hover';
        config.openDelay = 500;
    }

    private registerCustomElement(componentName: string, component: Type<any>) {
        const customElement = createCustomElement(component, { injector: this.injector });
        customElements.define(`cw-${componentName}`, customElement);
    }
}
