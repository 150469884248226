import { getCodeListTextDeps } from '../entities/code-list';
import { Constants } from '../entities/constants';
import { Design } from '../entities/design';
import { LocalizationService } from '../services/localization.service';
import { NumberService } from '../services/number.service';
import { PropertiesHelper } from './properties-helper';

export function getDesignTitle(design: Design, localizationService: LocalizationService, numberService: NumberService) {
    const designStandard = design.designStandard;
    const designType = Constants.CurtainWallDesignTypeTranslationKey;
    const longitudinalShearCalculationType = design.longitudinalShearCalculationType;
    const designMethodName = design.designMethodName;
    const approvalNumber = design.approvalNumber;
    const projectName = design.projectName;

    let title = design.designName + ` (${projectName})`;

    if (design.isTemplate && design.templateName != null) {
        title = design.templateName;
        title += ` (${localizationService.getString('Agito.Hilti.CW.Main.TemplateProjectName')})`;
    }

    const codeListDeps = getCodeListTextDeps(localizationService, numberService);
    title += ` - ${design.region.getTranslatedNameText(codeListDeps)}, `;

    const designTypeSpecificKey = `${designStandard?.nameResourceKey}.${designType}`;

    title += localizationService.getKeyExists(designTypeSpecificKey)
        ? localizationService.getString(designTypeSpecificKey)
        : `${designStandard?.getTranslatedNameText(codeListDeps)}`;

    if (designMethodName != null && designMethodName != '') {
        title += `, ${designMethodName}`;
    }

    if (PropertiesHelper.IsLongitudinalShearCalculationShown(design) &&
        longitudinalShearCalculationType != null && longitudinalShearCalculationType != '') {

        title += ` + ${longitudinalShearCalculationType}`;
    }

    if (approvalNumber != null && approvalNumber != '') {
        title += `, ${approvalNumber}`;
    }

    return title;
}
