/* eslint-disable @typescript-eslint/no-unused-vars */

import { CodeList, ICodeListTextDeps, getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { ICalculateOptions, IDesignStateBase } from '@profis-engineering/pe-ui-common/entities/design';
import { ILabelProps } from '@profis-engineering/pe-ui-common/entities/main-menu/Label-props';
import { IButtonGroupItem, IButtonGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-group-props';
import { IButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { ICheckboxGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-group-props';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IDropdownProps } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { IGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/group-props';
import { IImageNameCheckboxGroupItem, IImageNameCheckboxGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-checkbox-group-props';
import { IImageNameRadioGroupItem, IImageNameRadioGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-radio-group-props';
import { IImageNameSelectionGroupItem, IImageNameSelectionGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-selection-group-props';

import { IMenu, IRegion } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { BaseControl, Button, ButtonGroup, CheckBox, CheckboxGroup, DropDown, Group, ImageNameSelectionGroup, Label, Menu, RadioButtonGroup, Region, Rotate, TabGroup, TextBox, ToggleButton, ToggleButtonGroup, ToggleImageButton, UIPropertyBaseControl } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { IPopupGridPartialProps, IPopupGridProps } from '@profis-engineering/pe-ui-common/entities/main-menu/popup-grid-props';
import { IRadioButtonGroupProps, IRadioButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/radio-button-group-props';
import { IRangeSliderProps } from '@profis-engineering/pe-ui-common/entities/main-menu/range-slider-props';
import { IRotateProps } from '@profis-engineering/pe-ui-common/entities/main-menu/rotate-pros';
import { ISwitchWithDescriptionProps } from '@profis-engineering/pe-ui-common/entities/main-menu/switch-with-description';
import { ITabGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/tab-group-props';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { IToggleButtonGroupItem, IToggleButtonGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { IToggleButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';
import { IToggleImageButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-image-button-props';
import { IModalGridComponentInput, IModalGridItem } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { IModalOpened, ModalOptions } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ICalculationResult } from '@profis-engineering/pe-ui-common/services/calculation.common';
import { ILocalizationExtension } from '@profis-engineering/pe-ui-common/services/extensions.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    IMenuServiceCodeListIdWithItems, IMenuServiceDropdownItemProps, IMenuServiceExtensions, MenuControl,
    TabItem
} from '@profis-engineering/pe-ui-common/services/menu.common';
import uniqBy from 'lodash-es/uniqBy';
import { MainMenuModal } from '../components/main-menu/MainMenuHelper';
import { Constants } from '../entities/constants';
import { Design } from '../entities/design';
import { DesignCodeList } from '../entities/enums/design-code-list';
import { DesignType } from '../entities/enums/design-type';
import { ProjectCodeList } from '../entities/enums/project-code-list';
import { TranslationFormat } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities';
import { CodelistHelper } from '../helpers/codelist-helper';
import { DesignStandardHelper } from '../helpers/design-standard-helper';
import { KnowledgeBaseHelper } from '../helpers/knowledge-base-helper';
import { ModalGridHelper } from '../helpers/modal-grid-helper';
import { getSpriteAsIconStyle } from '../sprites';
import { CalculationService } from './calculation.service';
import { CodeListService } from './code-list.service';
import { FavoritesService } from './favorites.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { NumberService } from './number.service';
import { StaticMenuService } from './static-menu.service';
import { TranslationFormatService } from './translation-format.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

export class MenuServiceExtensions implements IMenuServiceExtensions {

    public localizationExtension?: ILocalizationExtension;

    constructor(
        private codeListService: CodeListService,
        private staticMenuService: StaticMenuService,
        private localizationService: LocalizationService,
        private userSettingsService: UserSettingsService,
        private calculationService: CalculationService,
        private userService: UserService,
        private loggerService: LoggerService,
        private unitService: UnitService,
        private numberService: NumberService,
        private modalService: ModalService,
        private translationFormatService: TranslationFormatService,
        private favoritesService: FavoritesService
    ) {
        this.localizationExtension = {
            getTranslation: this.localizationService.getString.bind(this.localizationService),
            checkForTranslation: this.localizationService.hasTranslation.bind(this.localizationService)
        } as ILocalizationExtension;
    }

    public validate(design: Design): void {
        if (design?.designData.designCodeLists == null) {
            throw new Error('Design code lists not set. Probably design is not created yet.');
        }
    }

    public getMenuStructure(design: Design): Menu<BaseControl<string>, string> | undefined {
        return this.staticMenuService.initializeMenu(design);
    }

    public getMenuCommands(design: Design): Record<string, (navigationControl?: BaseControl<string> | undefined) => void> {
        return {};
    }

    public getMenuRegionCommands(design: Design): Record<string, () => void> {
        return {};
    }

    public getMenuModals(design: Design): Record<number, (input?: object | undefined) => IModalOpened> {
        return {
            [MainMenuModal.basePlateSize]: (input?: object) => this.modalService.openBasePlateSize(input),
        };
    }

    public calculateAsync(design: Design, changeFn?: ((design: Design) => void) | undefined, options?: ICalculateOptions | undefined): Promise<ICalculationResult> {
        return this.calculationService.calculateAsync(design, changeFn, options);
    }

    public trackUsage(navigationControl: UIPropertyBaseControl<string>, controlProps: IControlProps, design: Design): void {
        /* Nothing to do. */
    }

    public getDesignCodeList(codelistName: string): number {
        return DesignCodeList[codelistName as keyof typeof DesignCodeList];
    }

    public getProjectCodeList(codelistName: string): number {
        return ProjectCodeList[codelistName as keyof typeof ProjectCodeList];
    }

    public getCodeListItems(navigationControl: UIPropertyBaseControl<string>, design: Design, codeList: number): CodeList[] | undefined {
        let codeListItems: CodeList[];

        if (codeList != null && (codeListItems = design.designData.designCodeLists[codeList]) != null) {
            return codeListItems;
        }

        this.logMissingCodeList(navigationControl, design);
        return undefined;
    }

    private getProjectCodeListItems(navigationControl: MenuControl, design: Design, codeList: ProjectCodeList) {
        let codeListItems: CodeList[];
        if (codeList != null && (codeListItems = this.codeListService.projectCodeLists[codeList]) != null) {
            const uniqCodeListItems = uniqBy(codeListItems, x => x.id);
            if (uniqCodeListItems.length != codeListItems.length) {
                console.error(`Duplicate id found in codelist ${ProjectCodeList[codeList]} for control ${navigationControl.Name}`);
            }

            return uniqCodeListItems;
        }

        this.logMissingCodeList(navigationControl, design);
        return undefined;
    }

    private logMissingCodeList(navigationControl: UIPropertyBaseControl, design: Design) {
        const isVisible = navigationControl.UIPropertyId == null || !design.properties.get(navigationControl.UIPropertyId).hidden;

        if (isVisible) {
            this.loggerService.log(`Missing code list: ${navigationControl.CodelistName}`, LogType.warn);
        }
    }

    public getCodeListIdWithItems(design: Design, navigationControl: UIPropertyBaseControl<string>, codelistName?: string | undefined): IMenuServiceCodeListIdWithItems | undefined {
        const designCodeList = this.getDesignCodeList(codelistName ?? navigationControl.CodelistName ?? '');
        const projectCodeList = this.getProjectCodeList(codelistName ?? navigationControl.CodelistName ?? '');

        let codeListItems: CodeList[] | undefined = undefined;
        if (designCodeList != null) {
            codeListItems = this.getCodeListItems(navigationControl, design, designCodeList);
        }
        else if (projectCodeList != null) {
            codeListItems = this.getProjectCodeListItems(navigationControl, design, projectCodeList);
        }

        return {
            codeList: designCodeList,
            codeListItems: codeListItems ?? []
        };
    }

    public getCodeListItemText(design: Design, codeList: number, codeListItem: CodeList, codeListDeps: ICodeListTextDeps): string | undefined {
        codeListItem = design?.designData?.designCodeLists?.[codeList]?.find(p => p.id == codeListItem.id) ?? codeListItem; // Read from design data if available to get updated code list items
        const deps = getCodeListTextDeps(this.localizationService, this.numberService);
        return CodelistHelper.getCodeListItemText(this.userService, this.unitService, this.localizationService, design, codeList, codeListItem, deps)
            ?? codeListItem.getTranslatedNameText(deps);
    }

    public getDesignStandard(design: Design): CodeList | undefined {
        return this.codeListService.projectCodeLists[ProjectCodeList.DesignStandards].find(d => d.id == design.designStandard?.id);
    }

    public getTabControlId(name: string): string | undefined {
        return name;
    }

    public updateTabDisplayKey(): void {
        /* Nothing to do. */
    }

    public updateTabs() {
        /* Nothing to do. */
    }

    public isFavoritesTabHidden(design: Design): boolean {
        return false;
    }

    public isTabDisabled(design: Design, tab: string): boolean {
        return false;
    }

    public getRegionDisplayId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    public getRegionId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    getRegionFavoritesId(id: string): string | undefined {
        return this.favoritesService.getMenuRegionIdFavorites(id, DesignType.CurtainWall);
    }

    public setRegionKbFields(design: Design, _: number, navigationRegion: Region<BaseControl<string>, string>, region: IRegion): void {
        if (KnowledgeBaseHelper.checkDesignStandard(design.designStandard?.id)) {
            const selectedRegionId = design.regionId;
            const kbNumberRegions = design.designData.designCodeLists[DesignCodeList.KBNumberRegion]?.find(x => x.id == navigationRegion.Id)?.kbNumberRegion;
            if (kbNumberRegions) {
                navigationRegion.KBNumberAciRegion = kbNumberRegions;
            }

            if (navigationRegion.KBNumberAciRegion != null) {
                region.kbNumber = navigationRegion.KBNumberAciRegion[selectedRegionId];
            }

            region.kbLinkTemplate = KnowledgeBaseHelper.getKnowledgeBaseUrl(this.userSettingsService, selectedRegionId);
            region.kbTooltip = navigationRegion.KBTooltipDisplayKey;
        }
    }

    public updateControlKBNumberAci(design: Design, navigationControl: UIPropertyBaseControl<string>): void {
        const kbNumberRegions = design.designData.designCodeLists[DesignCodeList.KBNumberControl]?.find(x => x.id == navigationControl.UIPropertyId)?.kbNumberRegion;
        if (kbNumberRegions) {
            navigationControl.KBNumberAciRegion = kbNumberRegions;
        }
    }

    public onTabSelected(design: Design, tab: string): void {
        if (tab == Constants.FirestopTabName) {
            this.modalService.openFirestopDialog();
        }
    }

    public isCustomTab(tab: string): boolean {
        if (tab == Constants.FirestopTabName) {
            return true;
        }

        return false;
    }

    public clientHidden(uiPropertyId: number, design: Design): boolean {
        return false;
    }

    public formatDisplayStringModule(textKey: string, design: Design, codeList?: number | undefined, uiPropertyId?: number | undefined): string | undefined {
        if (textKey == null || textKey.length == 0) return undefined;

        if (codeList) {
            return this.formatStringByCodeList(textKey, design, codeList);
        }

        if (uiPropertyId) {
            return this.formatStringByUiPropertyId(textKey, design, uiPropertyId);
        }

        return this.localizationService.getString(textKey);
    }

    private formatStringByCodeList(textKey: string, design: Design, codeList: number) {
        // eslint-disable-next-line no-empty
        switch (codeList) {
            // Format custom string by code list if needed
        }

        return this.localizationService.getString(textKey);
    }

    private formatStringByUiPropertyId(textKey: string, design: Design, uiPropertyId: number) {
        // eslint-disable-next-line no-empty
        switch (uiPropertyId) {
            // Custom format string by UI prop id
        }

        return this.localizationService.getString(textKey);
    }

    public getFormattedKBLinkByRegionSpecificTemplate(design: Design, kbNumberRegion: { [key: string]: string }): string | undefined {
        if (KnowledgeBaseHelper.checkDesignStandard(design.designStandard?.id)) {
            const selectedRegionId = design.regionId;
            const kbUrl = KnowledgeBaseHelper.getKnowledgeBaseUrl(this.userSettingsService, selectedRegionId);
            if (kbNumberRegion?.[selectedRegionId] != null && kbUrl != null) {
                return KnowledgeBaseHelper.createKnowledgeBaseLink(kbUrl, kbNumberRegion[selectedRegionId]);
            }
        }

        return undefined;
    }

    public getLocalizedStringWithTranslationFormat(translationFormat: TranslationFormat): string {
        return this.translationFormatService.getLocalizedStringWithTranslationFormat(translationFormat) ?? '';
    }

    public createMainMenuControlModule() {
        return undefined as any;
    }

    public overrideTextBoxProps(design: Design, controlProps: ITextBoxProps, navigationControl: TextBox<string>): void {
        /* Nothing to do. */
    }

    public overrideTextBoxUnitGroup(design: Design, controlProps: ITextBoxProps, navigationControl: TextBox<string>, setState?: (() => void) | undefined): void {
        /* Nothing to do. */
    }

    public setTextBoxOnStateChanged(state: IDesignStateBase, controlProps: ITextBoxProps, navigationControl: TextBox<string>): void {
        /* Nothing to do. */
    }

    public overrideDropdownProps(design: Design, controlProps: IDropdownProps, navigationControl: DropDown<string>): void {
        /* Nothing to do. */
    }

    public getDropdownItemProps(design: Design, controlProps: IDropdownProps, navigationControl: DropDown<string>, designCodeList: number): IMenuServiceDropdownItemProps | undefined {
        return {};
    }

    public setDropdownOnAllowedValuesChanged(design: Design, controlProps: IDropdownProps, navigationControl: DropDown<string>): void {
        /* Nothing to do. */
    }

    public setDropdownOnStateChanged(design: Design, designCodeList: number, state: IDesignStateBase, menu: IMenu, navigationControl: DropDown<string>, controlProps: IDropdownProps, onStateChangeFn: (formatTextFn: (codeListItem: CodeList, unit?: UnitType | undefined) => string, unit?: UnitType | undefined) => IDropdownProps): IDropdownProps | undefined {
        if (CodelistHelper.hasCodeListCustomText(designCodeList)) {
            return onStateChangeFn((codeListItem) => {

                const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
                return this.getCodeListItemText(design, designCodeList, codeListItem, codeListDeps) ?? '';
            });
        }

        return ({ selectedValue: state.model[navigationControl.UIPropertyId as number] } as any) as IDropdownProps;
    }

    public overrideCheckboxProps(design: Design, controlProps: ICheckboxProps, navigationControl: CheckBox<string>): void {
        if (controlProps.iconImage != null) {
            controlProps.iconImageStyle = this.getSpriteAsIconStyleInternal(controlProps.iconImage);
            controlProps.iconImageSelectedStyle = this.getSpriteAsIconStyleInternal(`${controlProps.iconImage}-selected`);
        }
    }

    public overrideCheckboxGroupProps(design: Design, controlProps: ICheckboxGroupProps, navigationControl: CheckboxGroup<string>): void {
        /* Nothing to do. */
    }

    public overrideRadioButtonProps(design: Design, controlProps: IRadioButtonProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public overrideRadioButtonGroupProps(design: Design, controlProps: IRadioButtonGroupProps, navigationControl: RadioButtonGroup<string>): void {
        /* Nothing to do. */
    }

    public applyProperSingleImage(items: IImageNameRadioGroupItem[], design: Design): void {
        /* Nothing to do. */
    }

    public getApplySortOrderItemIds(design: Design, codeList: number): number[] | undefined {
        return undefined;
    }

    public getRadioGroupParentId(design: Design, codeList: number): number | undefined {
        return undefined;
    }

    public overrideButtonProps(design: Design, controlProps: IButtonProps, navigationControl: Button<string>): void {
        if (controlProps.image != null) {
            controlProps.imageStyle = this.getSpriteAsIconStyleInternal(controlProps.image);
        }
    }

    public getButtonDisplayText(design: Design, navigationControl: Button<string>): string | undefined {
        const isViewApproval = false; // TODO: Check if navigationControl.UIPropertyId is ViewApproval property (for example PropertyMetaData.Product_ViewApproval.id)

        const displayUIProperty = navigationControl.DisplayUIPropertyId != null ? design.model[navigationControl.DisplayUIPropertyId] as any : null;
        let translationKeySuffix = '';
        if (isViewApproval) {
            translationKeySuffix = DesignStandardHelper.isHnaBasedDesignStandard(design.designStandard?.id) ? '.HNA' : '.ETA';
        }

        const displayText = navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? this.localizationService.getString(navigationControl.DisplayKey + translationKeySuffix) : undefined;
        return displayUIProperty ?? displayText;
    }

    public overrideButtonGroupProps(design: Design, controlProps: IButtonGroupProps, navigationControl: ButtonGroup<string>): void {
        /* Nothing to do. */
    }

    public updateButtonGroupItemProps(design: Design, navigationControl: Button<string>, item: IButtonGroupItem): void {
        if (item.image != null && item.image != '') {
            item.imageStyle = this.getSpriteAsIconStyleInternal(item.image);
        }
    }

    public overrideToggleButtonProps(design: Design, controlProps: IToggleButtonProps, navigationControl: ToggleButton<string>): void {
        controlProps.imageStyle = this.getIconStyleForImage(controlProps.image);
    }

    private getIconStyleForImage(image: string | undefined): IIconStyle | undefined {
        if (image == null || image == '') {
            return undefined;
        }

        return this.getSpriteAsIconStyleInternal(image);
    }

    private getSpriteAsIconStyleInternal(image: string, scale?: number): IIconStyle {
        if (!image.startsWith('sprite-'))
            image = `sprite-${image}`;

        return getSpriteAsIconStyle(image, scale);
    }

    public overrideToggleImageButtonProps(design: Design, controlProps: IToggleImageButtonProps, navigationControl: ToggleImageButton<string>): void {
        if (controlProps.image != null) {
            controlProps.imageStyle = this.getSpriteAsIconStyleInternal(controlProps.image);
        }

        if (controlProps.alternateImage != null) {
            controlProps.alternateImageStyle = this.getSpriteAsIconStyleInternal(controlProps.alternateImage);
        }
    }

    public overrideToggleButtonGroupProps(design: Design, controlProps: IToggleButtonGroupProps, navigationControl: ToggleButtonGroup<string>): void {
        /* Nothing to do. */
    }

    public updateToggleButtonGroupItemCodeListProps(design: Design, navigationControl: ToggleButtonGroup<string>, codeListItem: CodeList): void {
        /* Nothing to do. */
    }

    public updateToggleButtonGroupItemProps(design: Design, navigationControl: ToggleButtonGroup<string>, item: IToggleButtonGroupItem): void {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public getToggleButtonGroupAllowedValues(codeList: number, allowedValues: number[]): number[] {
        return allowedValues;
    }

    public overrideGroupProps(design: Design, controlProps: IGroupProps, navigationControl: Group<string>): void {
        /* Nothing to do. */
    }

    public setGroupChildProps(design: Design, childNavigationControl: UIPropertyBaseControl<string>): void {
        const kbNumberRegions = design.designData.designCodeLists[DesignCodeList.KBNumberControl]?.find(x => x.id == childNavigationControl.UIPropertyId)?.kbNumberRegion;
        if (kbNumberRegions) {
            childNavigationControl.KBNumberAciRegion = kbNumberRegions;
        }
    }

    public overrideLabelProps(design: Design, controlProps: ILabelProps, navigationControl: Label<string>): void {
        /* Nothing to do. */
    }

    public overrideRotateProps(design: Design, controlProps: IRotateProps, navigationControl: Rotate<string>): void {
        /* Nothing to do. */
    }

    public overridePopupGridProps(design: Design, controlProps: IPopupGridProps, navigationControl: UIPropertyBaseControl<string>): void {
        controlProps.localization = this.localizationService;
        controlProps.notSelectedName = 'Agito.Hilti.CW.ProductDropDown.NotSelected';
        controlProps.notSelectedImage = 'sprite-anchor-family-no-photo-available';
    }

    public customizePopupGridModal(modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions, codeList: number, design: Design): void {

        modalProps.componentName = 'cw-modal-grid'; // CW module use it's own modal grid component

        switch (codeList) {
            case DesignCodeList.AnchorChannelFamilyGroup:
                modalProps.componentName = 'cw-select-product';
                modalOpts.size = 'xl';

                break;
            case DesignCodeList.BoltFamilies:
                modalProps.componentName = 'cw-select-bolt';
                modalOpts.size = 'xl';

                break;
            case DesignCodeList.SeismicTensionTypes:
            case DesignCodeList.SeismicShearTypes:
                modalProps.numberOfColumns = 1;
                modalOpts.size = 'lg';

                break;
            case DesignCodeList.StandoffTypes:
                modalProps.numberOfColumns = modalProps.items?.length || 3;
                modalOpts.size = 'lg';
                break;
            default:
                modalOpts.size = 'lg';
                break;
        }
    }

    public customizePopupGridItems(_: IModalGridItem<number>[], design: Design, codeList: number): IModalGridItem<number>[] {

        // CW module does not call shared modal grid helper, therefore we need to create new items inside customizePopupGridItems method
        const itemMapping = ModalGridHelper.getPopupGridItemMapping(this.localizationService, this.unitService, codeList);
        let items = ModalGridHelper.createPopupGridItems(codeList, design.designData.designCodeLists, itemMapping);

        if (design.isPostInstallAnchorProduct())
        {
            if (codeList == DesignCodeList.StandoffTypes) {
                items = items?.filter(x => x.value != 2);
                items?.forEach(x => x.image = x.image + '-pi');
            }

            if (codeList == DesignCodeList.AnchorChannelFamilyGroup) {
                const piItems = ModalGridHelper.createPopupGridItems(DesignCodeList.AnchorFamilies, design.designData.designCodeLists, itemMapping);
                items = items?.concat(piItems ?? []);
            }
        }

        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = {
                    elementStyle: this.getSpriteAsIconStyleInternal(item.image, codeList == DesignCodeList.AnchorChannelFamilyGroup && this.isPostInstalledProduct(item.value ?? 0) ? 0.55 : undefined)
                };
            }
        });

        return items ?? [] as IModalGridItem<number>[];
    }

    public getPopupGridHideShowDescriptionOnButton(codeList: number): boolean {
        switch (codeList) {
            // Custom behaviour implementation for PopupGrid control
            case DesignCodeList.SeismicTensionTypes:
            case DesignCodeList.SeismicShearTypes:
                return true;
            default:
                return false;
        }
    }

    public overridePopupGridPartialProps(design: Design, controlProps: IPopupGridPartialProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public customizePopupGridPartialControl(controlProps: IPopupGridPartialProps, codeList: number): void {
        /* Nothing to do. */
    }

    public customizePopupGridPartialItems(items: IModalGridItem<number>[], design: Design, designCodeList: number): IModalGridItem<number>[] {
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = {
                    elementStyle: this.getSpriteAsIconStyleInternal(item.image)
                };
            }
        });

        return items;
    }

    public customizePopupGridPartialModal(modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions, codeList: number): void {
        /* Nothing to do. */
    }

    public overrideDlubalImportExportProps(design: Design, controlProps: IControlProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public overrideSAP2000ImportExportProps(design: Design, controlProps: IControlProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public overrideRobotImportExportProps(design: Design, controlProps: IControlProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public overrideETABSImportExportProps(design: Design, controlProps: IControlProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public overrideStaadProImportExportProps(design: Design, controlProps: IControlProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public overrideImageNameRadioGroupProps(design: Design, controlProps: IImageNameRadioGroupProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public updateImageNameRadioGroupItemProps(design: Design, navigationControl: UIPropertyBaseControl<string>, item: IImageNameRadioGroupItem): void {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public updateImageNameRadioGroupSortOrder(design: Design, designCodeList: number, newSortOrder: number[], parentId?: number | undefined): Promise<void> {
        return Promise.resolve();
    }

    public overrideImageNameSelectionGroupProps(design: Design, controlProps: IImageNameSelectionGroupProps, navigationControl: ImageNameSelectionGroup<string>): void {
        /* Nothing to do. */
    }

    public updateImageNameSelectionGroupItemProps(design: Design, navigationControl: ImageNameSelectionGroup<string>, item: IImageNameSelectionGroupItem): void {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public overrideImageNameCheckboxGroupProps(design: Design, controlProps: IImageNameCheckboxGroupProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public updateImageNameCheckboxGroupItemProps(design: Design, navigationControl: UIPropertyBaseControl<string>, item: IImageNameCheckboxGroupItem): void {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public overrideSwitchWithDescriptionProps(design: Design, controlProps: ISwitchWithDescriptionProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public overrideRangeSliderProps(design: Design, controlProps: IRangeSliderProps, navigationControl: UIPropertyBaseControl<string>): void {
        /* Nothing to do. */
    }

    public overrideTabGroupProps(design: Design, controlProps: ITabGroupProps, navigationControl: TabGroup<string>): void {
        /* Nothing to do. */
    }

    public overrideTabGroupItems(controlProps: ITabGroupProps, navigationControl: TabGroup<string>): void {
        /* Nothing to do. */
    }

    public getTabGroupItemsByTag(childNavigationControls: UIPropertyBaseControl<string>[], tab: TabItem, navigationControl: TabGroup<string>): UIPropertyBaseControl<string>[] {
        return childNavigationControls.filter(x => x.ParentControlTag == tab.Tag);
    }

    public setFooterControlVisibility(design: Design, menu: IMenu): IMenu | undefined {
        return menu;
    }

    private isPostInstalledProduct(productId: number) {
        return productId < 0;
    }
}
