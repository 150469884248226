import { BaseComponentCW, IModelCW } from '../../../gl-model/base-component';
import { Bolt } from './bolt';
import { InstancedMesh } from '@babylonjs/core/Meshes/instancedMesh.js';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { AnchorChannelHelper } from './anchor-channel';
import maxBy from 'lodash-es/maxBy';
import sortBy from 'lodash-es/sortBy';
import { createArrowXYLine, lineConstants } from '@profis-engineering/gl-model/line-helper';
import { CreateLineSystem } from '@babylonjs/core/Meshes/Builders/linesBuilder.js';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh.js';
import { CreateIUnitText2DCreateOptions } from '@profis-engineering/gl-model/components/base-component';
import { UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { glModelConstants } from '@profis-engineering/gl-model/gl-model-helper';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { GlModelConstants } from '../../../gl-model/gl-model-constants';
import { BaseComponentHelper } from '../../../gl-model/base-component-helper';
import { Text2D } from '../../../gl-model/text/text-2d';
import { UnitText2D } from '../../../gl-model/text/unit-text-2d';
import { INumberedPosition } from '../../../gl-model/entities/numbered-position';
import { IBasePlateSystemBaseConstructor } from './base-plate-system';
import { BasePlateSystemHelper } from '../helpers/base-plate-system-helper';
import { MaterialCacheCW } from '../../../gl-model/cache/material-cache';
import { PlateBracketHelper } from './plate-bracket';
import { StandoffTypes } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';

const minimumTextSpacing = 10;
const overlappingXZDimensionOffset = 30;

export interface IBoltValues {
    positions: INumberedPosition[];
    holeDiameter: number;
    length: number;
}

interface IDimensionLine {
    start: Vector3;
    end: Vector3;
}

interface IBoltSpacingLinesInfo {
    lines: Vector3[][];
    dimensionLines: IDimensionLine[];
}

interface IBoltLinesMeshInfo {
    linesMesh: LinesMesh;
    linesInfo: IBoltSpacingLinesInfo;
    capacity: number;
}

export class BoltManager extends BaseComponentCW {
    private bolts: InstancedMesh[] = [];
    private washers: InstancedMesh[] = [];
    private bolt: Bolt;
    private boltText: Text2D[];

    private spacingLinesMeshInfo!: IBoltLinesMeshInfo;
    private spacingDimensionsTexts: UnitText2D[] = [];

    private transformNode?: TransformNode;

    constructor(ctor: IBasePlateSystemBaseConstructor) {
        super(ctor);

        this.id = ctor.id;
        this.plateSystemId = ctor.plateSystemId;

        this.bolt = new Bolt(ctor);
        this.boltText = [];
    }

    override update(): void {
        this.dispose();

        this.bolt.id = this.id;
        this.bolt.plateSystemId = this.plateSystemId;

        const anchoringSystem = this.model.anchoringSystem(this.model, this.id);
        this.transformNode = this.getGlobalTransformNode(anchoringSystem.geometry.position, anchoringSystem.geometry.rotation);

        const boltPositions = BoltsHelper.getBoltValues(this.model, this.id, this.plateSystemId);

        this.ensureBoltInstances(boltPositions);

        const plateValues = PlateBracketHelper.getPlateBracketValues(this.model, this.id, this.plateSystemId);
        if (plateValues.slottedHole?.enabled) {
            const washerYPosition = (plateValues.platePosition?.y ?? 0) + (plateValues.plateSize?.y ?? 0) / 2 + (plateValues.slottedHole?.washerHeight ?? 0) / 2;
            this.ensureWasherInstances(boltPositions, washerYPosition);
        }

        if (!this.model.visibilityProperties.boltSpacingVisible)
            return;

        this.ensureSpacingMesh(boltPositions);
    }

    override dispose() {
        this.bolts.forEach(x => {
            x.setEnabled(false);
            x.dispose();
        });

        this.washers.forEach(x => {
            x.setEnabled(false);
            x.dispose();
        });

        this.bolts = [];
        this.washers = [];

        this.spacingLinesMeshInfo?.linesMesh.dispose();

        this.spacingDimensionsTexts.forEach(x => {
            x.setEnabled(false);
            x.dispose();
        });

        this.spacingDimensionsTexts = [];
        this.disposeText();
    }

    private disposeText() {
        this.boltText.forEach(x => {
            x.setEnabled(false);
            x.dispose();
        });

        this.boltText = [];
    }

    public override hide() {
        this.dispose();
    }

    private ensureBoltInstances(boltValues: IBoltValues) {
        if (boltValues.positions.length == 0) {
            return;
        }

        // Trigger bolt mesh update for updated meshes
        this.bolt.update();
        const mesh = this.bolt.getMesh();

        const textOffsetY = this.model.isPostInstallAnchorProduct ? this.getAnchorsTextOffsetY() : this.getBoldTextOffsetY(boltValues.length);
        const textOffset = new Vector3(0, textOffsetY, 0);


        let i = 0;
        for (const position of boltValues.positions) {
            const instance = mesh.createInstance(`${mesh.name}_${i++}`);
            instance.position = position.position;

            if (this.model.visibilityProperties.boltNumberVisible)
                BaseComponentHelper.addNumberTextToMesh(() => this.createText2D(), position.position.add(textOffset), position.id ?? i, this.boltText, this.transformNode, MaterialCacheCW.boltColor);

            if (this.transformNode != null)
                instance.parent = this.transformNode;

            this.scene.addMesh(instance);
            this.bolts.push(instance);
        }
    }

    private getAnchorsTextOffsetY() {
        const originalOffset = GlModelConstants.baseMaterialConstants.anchorProtrudingHeight + GlModelConstants.baseMaterialConstants.cylinderNumberingOffset;
        const bps = BasePlateSystemHelper.getFirstBasePlateSystem(this.model);
        if (bps == null || bps.plateBracket == null)
            return originalOffset;

        const plateThickness = bps.plateBracket.thickness;
        const standoffHeight = bps.plateBracket.standoff.type != StandoffTypes.None ? bps.plateBracket.standoff.distance : 0;
        const sunkenOffset = bps.plateBracket.isSunken ? -plateThickness : 0;

        return plateThickness + standoffHeight + originalOffset + sunkenOffset;
    }

    private getBoldTextOffsetY(boltLength: number) {
        return boltLength + GlModelConstants.baseMaterialConstants.cylinderNumberingOffset;
    }

    private ensureWasherInstances(boltValues: IBoltValues, washerYPosition: number) {
        if (boltValues.positions.length == 0) {
            return;
        }
        const mesh = this.bolt.getWasherMesh();

        let i = 0;
        for (const position of boltValues.positions) {
            const instance = mesh.createInstance(`${mesh.name}_${i++}`);
            instance.position = new Vector3(position.position.x, washerYPosition, position.position.z);

            if (this.transformNode != null)
                instance.parent = this.transformNode;

            this.scene.addMesh(instance);
            this.washers.push(instance);
        }
    }

    private ensureSpacingMesh(boltValues: IBoltValues) {
        if (!BasePlateSystemHelper.isBasePlateSystemWithAnchoringSystemSelected(this.model, this.plateSystemId, this.id))
            return;

        let positions = boltValues.positions.map(p => p.position);
        positions = sortBy(positions, (p) => p.x);
        BoltsHelper.addBoltEdgePositions(this.model, this.id, this.plateSystemId, positions);

        this.spacingLinesMeshInfo = this.createSpacingMesh(positions);
        this.spacingLinesMeshInfo.linesMesh.setEnabled(true);

        if (this.transformNode != null)
            this.spacingLinesMeshInfo.linesMesh.parent = this.transformNode;

        this.ensureSpacingDimensionText();
    }

    private ensureSpacingDimensionText() {
        const textCtor: CreateIUnitText2DCreateOptions = {
            unitGroup: UnitGroup.Length
        };

        for (let i = this.spacingDimensionsTexts.length; i < this.spacingLinesMeshInfo.linesInfo.dimensionLines.length; i++) {
            this.spacingDimensionsTexts.push(this.createUnitText2DForProperty(textCtor, UIProperty.Bolt_CW_SpacingX, this.updateBoltPositions(UIProperty.Bolt_CW_SpacingX, i)));
        }

        const isFirst = (index: number) => index == 0;
        const isLast = (index: number) => index == this.spacingLinesMeshInfo.linesInfo.dimensionLines.length - 1;

        this.settingAllTextValuesOnLine(isFirst, isLast);
    }

    private settingAllTextValuesOnLine(isFirst: (index: number) => boolean, isLast: (index: number) => boolean) {
        let yOffset = 0;
        for (let i = 0; i < this.spacingLinesMeshInfo.linesInfo.dimensionLines.length; i++) {

            // First and last measurments are displayed on bottom side of the line
            const upVector = isFirst(i) || isLast(i) ? new Vector3(0, 0, 1) : new Vector3(0, 0, -1);

            const text = this.spacingDimensionsTexts[i];
            const spacing = this.spacingLinesMeshInfo.linesInfo.dimensionLines[i];

            // Mitigate collisions
            if (i >= 2 && !isLast(i)) {
                const curStart = this.calculateTextStartX(spacing, text);
                const curEnd = this.calculateTextEndX(spacing, text);
                const prevStart = this.calculateTextStartX(this.spacingLinesMeshInfo.linesInfo.dimensionLines[i - 1], this.spacingDimensionsTexts[i - 1]);
                const prevEnd = this.calculateTextEndX(this.spacingLinesMeshInfo.linesInfo.dimensionLines[i - 1], this.spacingDimensionsTexts[i - 1]);

                if (this.isTextOverlapping(prevStart, prevEnd, curStart, curEnd)) {
                    yOffset -= overlappingXZDimensionOffset;
                    // Reset after 3 mitigations
                    yOffset = yOffset % (3 * overlappingXZDimensionOffset);

                    spacing.start.z += yOffset;
                    spacing.end.z += yOffset;
                }
            }

            this.setTextValue(spacing, text, upVector, isLast, i);
        }
    }

    private setTextValue(spacing: IDimensionLine, text: UnitText2D, upVector: Vector3, isLast: (index: number) => boolean, i: number) {
        const value = spacing.end.x - spacing.start.x;

        text.setValueOnLine(value,
            spacing.start,
            spacing.end,
            upVector,
            undefined,
            () => this.unitConverter.format(value, UnitGroup.Length, UIProperty.Bolt_CW_SpacingX)
        );

        if (this.transformNode != null && text.mesh != null)
            text.mesh.parent = this.transformNode;

        if (isLast(i)) {
            text.setEditable(false);
            text.setAlpha(glModelConstants.dimmedDimensionsAlpha);
        }
        else {
            text.setEditable(true);
            text.setAlpha(glModelConstants.highlightedDimensionsAlpha);
        }
    }

    private updateBoltPositions(property: UIProperty, index: number) {
        return (source: UnitText2D, internalValue: number | undefined) => {
            const propertyValue: { [id: number]: number | undefined } = {};
            propertyValue[index] = internalValue;
            this.propertyInfo.setPropertyValue(property, propertyValue);
        };
    }

    private createSpacingMesh(positions: Vector3[]) {
        const name = 'BoltManager_SpacingMesh';

        const linesInfo = this.calculateSpacingLines(positions);
        const linesMesh = this.createLineSystem(linesInfo, name);

        return {
            linesMesh,
            linesInfo,
            capacity: linesInfo.lines.length
        };
    }


    private calculateSpacingLines(positions: Vector3[]): IBoltSpacingLinesInfo {
        const lines: Vector3[][] = [];
        const dimensionLines: IDimensionLine[] = [];

        const lineDimensionOffset = BoltsHelper.getSpacingLineOffset(this.model, this.id, this.plateSystemId);
        const lineElevation = BoltsHelper.getSpacingLineElevation(this.model, this.id, this.plateSystemId);

        for (let i = 0; i < positions.length - 1; i++) {
            const firstPoint = positions[i].clone();
            const secondPoint = positions[i + 1].clone();

            firstPoint.y = lineElevation;
            secondPoint.y = lineElevation;

            const line = [
                firstPoint,
                firstPoint.clone().add(new Vector3(0, 0, lineDimensionOffset)),
                secondPoint.clone().add(new Vector3(0, 0, lineDimensionOffset)),
                secondPoint
            ];

            const arrows = createArrowXYLine(line[1], line[2]);

            lines.push(line);
            lines.push(...arrows);

            dimensionLines.push({ start: line[1], end: line[2] });
        }

        return {
            lines,
            dimensionLines
        };
    }

    private createLineSystem(linesInfo: IBoltSpacingLinesInfo, name: string): LinesMesh {
        const linesMesh = CreateLineSystem(name, {
            lines: linesInfo.lines,
            updatable: true
        }, this.scene);

        linesMesh.isPickable = false;
        linesMesh.color = lineConstants.defaultLineColor;

        return linesMesh;
    }

    private calculateTextStartX(line: IDimensionLine, text: UnitText2D) {
        const center = line.start.x + (line.end.x - line.start.x) / 2;
        return center - (text.width + minimumTextSpacing) / 2;
    }

    private calculateTextEndX(line: IDimensionLine, text: UnitText2D) {
        const center = line.start.x + (line.end.x - line.start.x) / 2;
        return center + (text.width + minimumTextSpacing) / 2;
    }

    private isTextOverlapping(text1Start: number, text1End: number, text2Start: number, text2End: number) {
        return text2Start < text1End && text2End > text1Start;
    }
}

export class BoltsHelper {
    public static getSpacingLineElevation(model: IModelCW, anchoringSystemId: string, basePlateSystemId: string) {
        if (!BaseComponentHelper.isTopView(model.applicationType))
            return 0;

        const basePlateSystem = model.basePlateSystem(model, basePlateSystemId, anchoringSystemId);
        const plateOffset = basePlateSystem.plateBracket.thickness + 1;

        const sunkenOffset = basePlateSystem.plateBracket.isSunken ? -basePlateSystem.plateBracket.thickness + 2 : 0;

        return model.baseMaterial.thickness / 2 + plateOffset + sunkenOffset;
    }

    public static getSpacingLineOffset(model: IModelCW, anchoringSystemId: string, basePlateSystemId: string) {
        const plateBracket = model.basePlateSystem(model, basePlateSystemId, anchoringSystemId).plateBracket;
        return 3 * overlappingXZDimensionOffset + plateBracket.offsetY;
    }

    public static getBoltValues(model: IModelCW, anchoringSystemId: string, basePlateSystemId: string): IBoltValues {
        const isTopView = BaseComponentHelper.isTopView(model.applicationType);
        const anchorChannelValues = AnchorChannelHelper.getAnchorChannelValues(model, anchoringSystemId);
        const bolt = model.basePlateSystem(model, basePlateSystemId, anchoringSystemId).bolt;
        return {
            positions: bolt.positions?.map(p => { return { id: p.id, position: new Vector3(p.position.x, anchorChannelValues.position.y, isTopView ? p.position.y : anchorChannelValues.position.z) }; }) ?? [],
            holeDiameter: bolt.holeDiameter ?? 0,
            length: bolt.length ?? 0
        };
    }

    public static addBoltEdgePositions(model: IModelCW, anchoringSystemId: string, basePlateSystemId: string, positions: Vector3[]) {
        if (positions.length < 1)
            return;

        const anchoringSystem = model.anchoringSystem(model, anchoringSystemId);
        const channelLength = anchoringSystem.anchorChannel.channelLength;

        const basePlateSystem = model.basePlateSystem(model, basePlateSystemId, anchoringSystemId);

        const plateWidth = basePlateSystem.plateBracket.width;
        const plateOffset = basePlateSystem.plateBracket.offsetX;

        const plateStart = (-channelLength / 2) - (plateWidth / 2) + (plateOffset);
        const plateEnd = plateStart + plateWidth;

        const startPosition = positions[0].clone();
        const endPosition = positions[0].clone();

        startPosition.x = plateStart;
        endPosition.x = plateEnd;

        positions.unshift(startPosition);
        positions.push(endPosition);
    }

    /**
     * An extracts the contour that describes bolt head shape
     * An algorithm looks for the first point where the inner upper curve starts and follows its path till x > 0. It then mirrors its shape in x to complete the shape
     * @param points
     * @returns
     */
    public static getBoltHeadShape(points: Vector3[]): Vector3[] {
        // Find most top right point
        const positiveX = points.filter(p => p.x > 0);
        let currentPoint = maxBy(positiveX, p => p.y) ?? Vector3.Zero();
        const startIndex = points.indexOf(currentPoint);

        // Check neighbouring points. We need to move towards the x center where the channel opening is
        let moveIndex = (index: number) => index - 1;

        if (Math.abs(points[startIndex + 1].x) < Math.abs(points[startIndex - 1].x))
            moveIndex = (index: number) => index + 1;

        // Iterate the points moving downwards until we start moving upwards (where physically a water dropplet would tear off)
        let index = startIndex;
        while (index < points.length && index >= 0 && points[index].y <= currentPoint.y) {
            currentPoint = points[index];
            index = moveIndex(index);
        }

        // Collect the shape points in the same direction untill we reach a vertical center
        const shape = [currentPoint];
        while (index < points.length && index >= 0 && points[index].y >= 0) {
            shape.push(points[index]);
            index = moveIndex(index);
        }

        // Mirror the shape on the opposite side
        for (let i = shape.length - 1; i >= 0; i--) {
            shape.push(new Vector3(-shape[i].x, shape[i].y, shape[i].z));
        }

        // Complete the polygon
        if (shape.length > 0) {
            shape.push(shape[0]);
        }

        return shape;
    }
}
